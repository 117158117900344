import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import { ZERO } from '@helpers/constants';
import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useApproveTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const {
    swapTicketExchangeAddress,
    usdcAddress,
    usdcAbi,
  } = useSmartContracts();

  /*
   * State
   */ 

  const [amountToApprove, setAmountToApprove] = useState<bigint>(ZERO);

  const [shouldConfigureApprovalWrite, setShouldConfigureApprovalWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  //
  // approve(address spender, uint256 value)
  //
  const { config: writeApproveConfig } = usePrepareContractWrite({
    address: usdcAddress,
    abi: usdcAbi,
    functionName: "approve",
    args: [
      swapTicketExchangeAddress,
      amountToApprove
    ],
    onError(error: any) {
      console.log('writeApproveConfig failed: ', error.message);

      setShouldConfigureApprovalWrite(false);

      onError?.(error);
    },
    enabled: shouldConfigureApprovalWrite
  });

  const {
    data: submitApproveResult,
    status: signApproveTransactionStatus,
    writeAsync: writeApproveAsync,
  } = useContractWrite(writeApproveConfig);

  const {
    status: mineApproveTransactionStatus,
  } = useWaitForTransaction({
    hash: submitApproveResult ? submitApproveResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeApproveAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeApproveAsync,
    amountToApprove,
    setAmountToApprove,
    setShouldConfigureApprovalWrite,
    signApproveTransactionStatus,
    mineApproveTransactionStatus,
    transactionHash: submitApproveResult?.hash,
  };
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/BigInt
export const ZERO = BigInt(0);
export const SECONDS_IN_DAY = BigInt(86400);
export const CHROME_EXTENSION_URL = 'https://chromewebstore.google.com/detail/zkp2p-extension/ijpgccednehjpeclfcllnjjcmiohdjih';

export const STATE_REFETCH_INTERVAL = 20000; // 20 seconds
// export const DEPOSIT_REFETCH_INTERVAL = 2000000; // 30 minutes
// export const STATE_REFETCH_INTERVAL = 6000000; // 100 minute

export const PRECISION = BigInt(1_000_000_000_000_000_000); // 18
export const USDC_UNITS = BigInt(1_000_000); // 6
export const PENNY_IN_USDC_UNITS = BigInt(10_000); // 6

export const EMPTY_STRING = '';

export const SOCKET_QUOTE_DEFAULT_ADDRESS = '0x18Cc6F90512C6D95ACA0d57F98C727D61873c06a';
export const SOCKET_DEFAULT_SOL_ADDRESS = '8pHKRNF3u8tndkUJ4euAddNWM9EAMWbUiK5GVmtaGY5U';
export const QUOTE_FETCHING_DEBOUNCE_MS = 750;

export const CLIENT_VERSION = '0.0.1';

// circom constants from main.circom / https://zkrepl.dev/?gist=30d21c7a7285b1b14f608325f172417b
// template RSAGroupSigVerify(n, k, levels) {
// component main { public [ modulus ] } = RSAVerify(121, 17);
// component main { public [ root, payload1 ] } = RSAGroupSigVerify(121, 17, 30);
export const CIRCOM_BIGINT_N = 121;
export const CIRCOM_BIGINT_K = 17;
export const CIRCOM_LEVELS = 30;


// This is the string that comes right before the target string in the email. Ideally as close to the end of the email as possible.
export const STRING_PRESELECTOR = "<!-- recipient name -->";


// Misc smart contract values
export const UINT256_MAX = "0x7fffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const CALLER_ACCOUNT = "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266";
export const DEFAULT_NETWORK = "base";


const ENABLE_STATE_LOGGING = false;
export const esl = ENABLE_STATE_LOGGING;

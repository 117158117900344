import { createContext } from 'react';

import { Event } from '@helpers/types';


interface EventsValues {
  eventsByEventId: { [eventId: string]: Event } | null;

  refetchEventIds: (() => void) | null;
  shouldFetchEventIds: boolean;

  refetchEvents: (() => void) | null;
  shouldFetchEvents: boolean;
};

const defaultValues: EventsValues = {
  eventsByEventId: null,

  refetchEvents: null,
  shouldFetchEventIds: false,
  
  shouldFetchEvents: false,
  refetchEventIds: null,
};

const EventsContext = createContext<EventsValues>(defaultValues);

export default EventsContext;

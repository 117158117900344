import React, { useEffect, useState, ReactNode } from 'react'
import { erc20ABI } from 'wagmi';

import { contractAddresses, blockExplorerUrls } from "@helpers/deployed_addresses";
import { esl, DEFAULT_NETWORK } from '@helpers/constants';
import { Abi } from '@helpers/types';
import { abi as verifiedTicketRegistryAbi } from '@helpers/abi/verfiedTicketRegistry.abi';
import { abi as swapTicketExchangeAbi } from '@helpers/abi/swapTicketExchange.abi';
import { abi as eventRegistryAbi } from '@helpers/abi/eventRegistry.abi';
import useAccount from '@hooks/contexts/useAccount'

import SmartContractsContext from './SmartContractsContext';


interface ProvidersProps {
  children: ReactNode;
}

const SmartContractsProvider = ({ children }: ProvidersProps) => {
  /*
   * Context
   */

  const { network, isLoggedIn } = useAccount();

  /*
   * State
   */

  const [usdcAddress, setUsdcAddress] = useState<string | null>(null);
  const [blockscanUrl, setBlockscanUrl] = useState<string>(blockExplorerUrls[DEFAULT_NETWORK]);

  const [verifiedTicketRegistryAddress, setVerifiedTicketRegistryAddress] = useState<string | null>(null);
  const [swapTicketExchangeAddress, setSwapTicketExchangeAddress] = useState<string | null>(null);
  const [eventRegistryAddress, setEventRegistryAddress] = useState<string | null>(null);

  // Socket
  const [socketBridgeAddress, setSocketBridgeAddress] = useState<string | null>(null);
  
  // Socket
  const [lifiBridgeAddress, setLifiBridgeAddress] = useState<string | null>(null);

  /*
   * Hooks
   */

  useEffect(() => {
    esl && console.log('smartContracts_1');
    esl && console.log('checking network: ', network);
    esl && console.log('isLoggedIn: ', isLoggedIn);

    const deploymentEnvironment = process.env.DEPLOYMENT_ENVIRONMENT || 'LOCAL';

    let networkToUse = null;
    if (isLoggedIn) {
      networkToUse = network;
    } else {
      switch (deploymentEnvironment) {
        case 'PRODUCTION':
        case 'STAGING':
          networkToUse = 'base';
          break;

        case 'STAGING_TESTNET':
          networkToUse = 'sepolia';
          break;

        default:
          networkToUse = 'hardhat';
      }
    }

    if (networkToUse) {
      switch (deploymentEnvironment) {
        case 'PRODUCTION':
          setAddressWithNetworkEnvKey(networkToUse, 'base_production');
          break;
  
        default:
          switch (networkToUse) {
            case 'base':
              setAddressWithNetworkEnvKey(networkToUse, 'base_staging');
              break;
  
            case 'sepolia':
              setAddressWithNetworkEnvKey(networkToUse, 'sepolia_staging');
              break;
  
            case 'hardhat':
            default:
              setAddressWithNetworkEnvKey(networkToUse, 'localhardhat');
              break;
          }
        }
    } else {
      setEmptyAddresses();
    }
  }, [network, isLoggedIn]);

  /*
   * Helpers
   */

  const setEmptyAddresses = () => {
    setBlockscanUrl(blockExplorerUrls['base']);
    setUsdcAddress(null);
    
    // Socket
    setSocketBridgeAddress(null);

    // Lifi
    setLifiBridgeAddress(null);
  };

  const setAddressWithNetworkEnvKey = (network: string, networkEnvKey: string) => {
    const contractsForNetwork = contractAddresses[networkEnvKey];

    setBlockscanUrl(blockExplorerUrls[network]);
    setUsdcAddress(contractsForNetwork.usdc);

    setVerifiedTicketRegistryAddress(contractsForNetwork.verifiedTicketRegistry)
    setSwapTicketExchangeAddress(contractsForNetwork.swapTicketExchange)
    setEventRegistryAddress(contractsForNetwork.eventRegistry)

    // Socket
    setSocketBridgeAddress(contractsForNetwork.socketBridge);
    
    // Lifi
    setLifiBridgeAddress(contractsForNetwork.lifiBridge);
  };

  return (
    <SmartContractsContext.Provider
      value={{
        usdcAddress,
        usdcAbi: erc20ABI as Abi,
        blockscanUrl: blockscanUrl,

        // Protocol Abis
        verifiedTicketRegistryAbi: verifiedTicketRegistryAbi as Abi,
        swapTicketExchangeAbi: swapTicketExchangeAbi as Abi,
        eventRegistryAbi: eventRegistryAbi as Abi,

        // Protocl Addresses
        verifiedTicketRegistryAddress,
        swapTicketExchangeAddress,
        eventRegistryAddress,

        // Socket
        socketBridgeAddress,

        // Lifi
        lifiBridgeAddress
      }}
    >
      {children}
    </SmartContractsContext.Provider>
  );
};

export default SmartContractsProvider;

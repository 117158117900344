import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useDeleteListingTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { swapTicketExchangeAddress, swapTicketExchangeAbi } = useSmartContracts();

  /*
   * State
   */ 

  const [listingIdInput, setListingIdInput] = useState<bigint | null>(null);

  const [shouldConfigureDeleteListing, setShouldConfigureDeleteListing] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  // * @param _listingId        Id of the listing being updated

  const { config: writeDeleteListingConfig } = usePrepareContractWrite({
    address: swapTicketExchangeAddress,
    abi: swapTicketExchangeAbi,
    functionName: 'deleteListing',
    args: [
      listingIdInput,
    ],
    onError(error: any) {
      console.log('writeDeleteListingConfig failed: ', error.message);

      setShouldConfigureDeleteListing(false);

      onError?.(error);
    },
    enabled: shouldConfigureDeleteListing,
  });

  const {
    data: submitDeleteListingResult,
    status: signDeleteListingTransactionStatus,
    writeAsync: writeDeleteListingAsync,
  } = useContractWrite(writeDeleteListingConfig);

  const {
    status: mineDeleteListingTransactionStatus,
  } = useWaitForTransaction({
    hash: submitDeleteListingResult ? submitDeleteListingResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeDeleteListingAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeDeleteListingAsync,
    listingIdInput,
    shouldConfigureDeleteListing,
    setListingIdInput,
    setShouldConfigureDeleteListing,
    signDeleteListingTransactionStatus,
    mineDeleteListingTransactionStatus,
    transactionHash: submitDeleteListingResult?.hash,
  };
};

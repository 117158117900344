type Contracts = {
  [network: string]: {
    [contract: string]: string;
  };
};

export const contractAddresses: Contracts = {
  'base_production': {
    'swapTicketExchange': '0xa2AbFae278d949A08Afeb8761F926654e8Ed7E59',
    'verifiedTicketRegistry': '0xfB26bcFaFC09B7b6ea6FF4Ff93C2e406DBF68c67',
    'eventRegistry': '0x91df3E4499079bb5389fABB7da10682218f21694',
    'nullifierRegistry': '0x2BC00e50337e1dE5a516B85b6918B8c0c3067a69',
    'usdc': '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
    'ticketNft': '',
    'socketBridge': '0x3a23f943181408eac424116af7b7790c94cb97a5',
    'lifiBridge': '0x1231DEB6f5749EF6cE6943a275A1D3E7486F4EaE'
  },
  'base_staging': {
    'swapTicketExchange': '0x23765495Be2f1Ff7960F0820CD092c7DD7980d50',
    'verifiedTicketRegistry': '0xAd540E5be8F923c128f77CF6d152313d052c1B53',
    'eventRegistry': '0x7c6766C342Ca52a0e57056BEEf2F463e9CEBe34d',
    'nullifierRegistry': '0x9B7685d83154873B4bc0699F5757eAE1fAD5df7A',
    'usdc': '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
    'ticketNft': '',
    'socketBridge': '0x3a23f943181408eac424116af7b7790c94cb97a5',
    'lifiBridge': '0x1231DEB6f5749EF6cE6943a275A1D3E7486F4EaE'
  },
  'sepolia_staging': {
    'swapTicketExchange': '0xadceB9793236861960666BB60e076B98cd2F6E31',
    'verifiedTicketRegistry': '0x1617929e1660dA9dAF03775d1242FC738d7AbF1F',
    'eventRegistry': '0x93b58E65B9434c47963FbEE69272d7AAA9441d9C',
    'nullifierRegistry': '0x07F43F608410a08be16595eB8D8d07e0C7Eae4E1',
    'usdc': '0x6555D8a4A40Cd1abC5a2BB7D5D91173ECE4Ad1E1',
    'ticketNft': '',
    'socketBridge': '',
    'lifiBridge': '',
  },
  'localhardhat': {
    'swapTicketExchange': '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9',
    'verifiedTicketRegistry': '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',
    'eventRegistry': '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
    'nullifierRegistry': '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
    'usdc': '0x5FbDB2315678afecb367f032d93F642f64180aa3',
    'ticketNft': '',
    'socketBridge': '',
    'lifiBridge': '',
  },
};

export const blockExplorerUrls: { [network: string]: string } = {
  'hardhat': 'https://etherscan.io',
  'sepolia': 'https://sepolia.etherscan.io/',
  'base': 'https://basescan.org'
};

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Inbox } from 'react-feather';
import styled, { css } from 'styled-components/macro';

import { RowBetween } from '@components/layouts/Row';
import { CustomConnectButton } from '@components/common/ConnectButton';
import { OrderRow, SellerOrderStatus } from '@components/Listings/Seller/OrderRow'; 
import { Order, Ticket, Event, DetailedListing } from '@helpers/types';
import QuestionHelper from '@components/common/QuestionHelper';
import { formatTicketSummaries } from '@helpers/ticketFormat';
import { decryptMessageWithAccount } from '@helpers/messagEncryption';
import { toUsdString } from '@helpers/units';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import useAccount from '@hooks/contexts/useAccount';
import useSellers from '@hooks/contexts/useSellers';
import { commonStrings } from '@helpers/strings';
import useQuery from '@hooks/useQuery';


export interface OrderRowData {
  status: SellerOrderStatus;
  orderId: bigint;
  sectionText: string;
  emailText: string;
  rowText: string;
  seatText: string;
  seats: string[];
  ticketPriceText: string;
}

export interface OrderSettlementDetails {
  orderId: bigint;
  eventName: string;
  eventDate: bigint;
  recipientEmail: string;
  orderPriceText: string;
  section: string;
  row: string;
  seats: string[];
};


interface OrderTableProps {
  selectedEventToFilterOrders: Event | null;
  handleCompleteOrderPressed: (selectedOrderDetails: OrderSettlementDetails) => void;
  handleTransferOrderPressed: (selectedOrderDetails: OrderSettlementDetails) => void;
}

export const OrderTable: React.FC<OrderTableProps> = ({
  selectedEventToFilterOrders,
  handleCompleteOrderPressed,
  handleTransferOrderPressed
}) => {

  const { queryParams } = useQuery();
  const navigate = useNavigate();
  const location = useLocation();

  /*
   * Contexts
   */

  const { accountListingHash, createAccountListingHash, isLoggedIn } = useAccount();
  const { sellerListingsStore } = useSellers();

  /*
   * State
   */

  const [orderRowsData, setOrderRowsData] = useState<OrderRowData[]>([]);
  const [orderIdFromQuery, setOrderIdFromQuery] = useState<BigInt | null>(null);

  /*
   * Hooks
   */

  useEffect(() => {
    const orderId = queryParams.ORDER_ID ? BigInt(queryParams.ORDER_ID) : null;
    setOrderIdFromQuery(orderId);

    // Clear the query parameters
    if (orderId) {
      const newUrl = location.pathname;
      navigate(newUrl, { replace: true });
    }
  }, []);


  useEffect(() => {
    if (!accountListingHash && isLoggedIn && createAccountListingHash) {
      createAccountListingHash();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountListingHash, isLoggedIn]);

  useEffect(() => {
    const determineOrderStatus = (order: Order) => {
      if (order.refundInitiatedAt !== BigInt(0)) {
        return SellerOrderStatus.PENDING_REFUND;
      } else if (order.transferId) {
        return SellerOrderStatus.WAITING_TO_ACCEPT;
      } else {
        return SellerOrderStatus.WAITING_TRANSFER;
      }
    }

    const processSellerListingsForOrders = async () => {
      if (sellerListingsStore && accountListingHash && selectedEventToFilterOrders) {
        const sellerListings = sellerListingsStore.listings;

        // Filter only for the orders for the selected Event
        const listingsForEvent = sellerListings.filter((listing: DetailedListing) => listing.eventId === selectedEventToFilterOrders.id);

        if (listingsForEvent) {
          const orderRowsData: OrderRowData[] = [];
          for (const detailedListing of listingsForEvent) {
            const listingOrders: Order[] = detailedListing.orders;
            if (!listingOrders || listingOrders.length === 0) {
              continue;
            }

            const ticketsOnListing = [...detailedListing.unfilledTickets, ...detailedListing.pendingTickets];
  
            // Extract Order Info
            for (const order of listingOrders) {
              const ticketIdsOnOrder = order.ticketIds;
              const ticketOrders = ticketsOnListing.filter((ticket) => ticketIdsOnOrder.includes(ticket.ticketId));
              const ticketSummaryComponents = formatTicketSummaries(ticketOrders);
  
              const ticketPriceText = `$${toUsdString(order.purchaseValue)}`;

              const decodedRecipientEmail = await decryptMessageWithAccount(order.encryptedEmail, accountListingHash);

              const status = determineOrderStatus(order);
  
              orderRowsData.push({
                orderId: order.orderId,
                status,
                sectionText: ticketSummaryComponents.sectionSummary,
                emailText: decodedRecipientEmail,
                rowText: ticketSummaryComponents.rowSummary,
                seatText: ticketSummaryComponents.seatSummary,
                seats: ticketOrders.map((ticket: Ticket) => ticket.seat),
                ticketPriceText
              });
            }
          }
  
          setOrderRowsData(orderRowsData);
        } else {
          setOrderRowsData([]);
        }
      } else {
        setOrderRowsData([]);
      }
    };
  
    processSellerListingsForOrders();
  }, [sellerListingsStore, accountListingHash, selectedEventToFilterOrders]);

  useEffect(() => {
    console.log("orderIdFromQuery", orderIdFromQuery);
    console.log("orderRowsData", orderRowsData);
    if (orderIdFromQuery && orderRowsData && selectedEventToFilterOrders) {
      const orderRowIndex = orderRowsData.findIndex((order) => order.orderId === orderIdFromQuery);
      console.log("orderRowIndex", orderRowIndex);
      if (orderRowIndex !== -1) {
        handleTransferOrderRowPressed(orderRowIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderIdFromQuery, orderRowsData, selectedEventToFilterOrders]);

  /*
   * Handlers
   */

  const handleCompleteOrderRowPressed = (rowIndex: number) => {
    const selectedOrderDetails: OrderSettlementDetails = {
      eventName: selectedEventToFilterOrders?.name ?? '',
      eventDate: selectedEventToFilterOrders?.startTime ?? BigInt(0),
      section: orderRowsData[rowIndex].sectionText,
      row: orderRowsData[rowIndex].rowText,
      seats: orderRowsData[rowIndex].seats,
      orderId: orderRowsData[rowIndex].orderId,
      recipientEmail: orderRowsData[rowIndex].emailText,
      orderPriceText: orderRowsData[rowIndex].ticketPriceText,
    };

    handleCompleteOrderPressed(selectedOrderDetails);
  };

  const handleTransferOrderRowPressed = (rowIndex: number) => {
    const selectedOrderDetails: OrderSettlementDetails = {
      eventName: selectedEventToFilterOrders?.name ?? '',
      eventDate: selectedEventToFilterOrders?.startTime ?? BigInt(0),
      section: orderRowsData[rowIndex].sectionText,
      row: orderRowsData[rowIndex].rowText,
      seats: orderRowsData[rowIndex].seats,
      orderId: orderRowsData[rowIndex].orderId,
      recipientEmail: orderRowsData[rowIndex].emailText,
      orderPriceText: orderRowsData[rowIndex].ticketPriceText,
    };

    handleTransferOrderPressed(selectedOrderDetails);
  };
  
  /*
   * Component
   */

  return (
    <Container>
      <TitleRow>
        <ThemedText.HeadlineMedium>
          Pending Sales
        </ThemedText.HeadlineMedium>
      </TitleRow>

      <Content>
        {!isLoggedIn ? (
          <ErrorContainer>
            <ThemedText.DeprecatedBody textAlign="center">
              <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
              <div>
                Your pending sales will appear here.
              </div>
            </ThemedText.DeprecatedBody>
            <CustomConnectButton width={152} />
          </ErrorContainer>
        ) : orderRowsData.length === 0 ? (
            <ErrorContainer>
              <ThemedText.DeprecatedBody textAlign="center">
                <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
                <div style={{ lineHeight: '1.3' }}>
                  You have no pending sales on your <strong>{selectedEventToFilterOrders?.name}</strong> listings.
                </div>
              </ThemedText.DeprecatedBody>
            </ErrorContainer>
        ) : (
          <>
            <TableHeaderRow>
              <ColumnHeader>#</ColumnHeader>

              <ColumnHeader>
                <ColumnHeaderAndQuestionMarkContainer>
                  Status
                  <QuestionHelper
                    text={commonStrings.get('ORDER_STATUS_LABEL_EXPLAINER')}
                  />
                </ColumnHeaderAndQuestionMarkContainer>
              </ColumnHeader>

              <ColumnHeader>Seats</ColumnHeader>

              <ColumnHeader>Recipient Email</ColumnHeader>

              <ColumnHeader>Price</ColumnHeader>

              <ColumnHeader>Actions</ColumnHeader>
            </TableHeaderRow>

            <Table>
              {orderRowsData.map((orderRowData, rowIndex) => (
                <StyledOrderRow key={rowIndex}>
                  <OrderRow
                    orderId={orderRowData.orderId}
                    status={orderRowData.status}
                    sectionText={orderRowData.sectionText}
                    rowText={orderRowData.rowText}
                    seatText={orderRowData.seatText}
                    orderPriceText={orderRowData.ticketPriceText}
                    recipientEmail={orderRowData.emailText}
                    rowIndex={rowIndex}
                    handleCompleteOrderPressed={() => handleCompleteOrderRowPressed(rowIndex)}
                    handleTransferOrderPressed={() => handleTransferOrderRowPressed(rowIndex)}
                  />
                </StyledOrderRow>
              ))}
            </Table>
          </>
        )}
      </Content>
    </Container>
  )
};

const Container = styled.div`
  gap: 1rem;
`;

const TitleRow = styled(RowBetween)`
  align-items: flex-end;
  color: ${colors.darkText};
  padding: .5rem 1rem 1.75rem 1rem;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    padding: 0 1.75rem;
  };
`;

const Content = styled.div`
  background-color: ${colors.container};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  overflow: hidden;
  position: relative;

  @media (min-width: 600px) {
    border-radius: 16px;
  }
`;

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 36px;
  max-width: 340px;
  min-height: 25vh;
  gap: 36px;
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`;

const TableHeaderRow = styled.div`
  display: grid;
  grid-template-columns: 0.75fr 2.5fr 3fr 3fr 2fr 2fr;
  grid-gap: 4px;
  padding: 1rem 1.5rem 0.75rem 1.5rem;
  text-align: left;
  color: ${colors.darkText};
  border-bottom: 1px solid ${colors.defaultBorderColor};
`;

const ColumnHeader = styled.div`
  text-align: center;
  font-size: 14px;
  
  @media (max-width: 600px) {
    font-size: 13px;
  };
`;

const ColumnHeaderAndQuestionMarkContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Table = styled.div`
  font-size: 16px;
  color: #616161;

  & > * {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${colors.defaultBorderColor};
    }
  }

  & > *:last-child::after {
    display: none;
  }
`;

const StyledOrderRow = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

import React, { useEffect, useState, ReactNode } from 'react';

// import { esl } from '@helpers/constants';
import {
  ExtensionEventMessage,
  ExtensionNotaryProofRequest,
  ExtensionEventVersionMessage,
  ExtensionPostMessage,
  ExtensionReceiveMessage,
} from '@hooks/useBrowserExtension';

import ExtensionProxyProofsContext from './ExtensionProxyProofsContext';


interface ProvidersProps {
  children: ReactNode;
};

const ExtensionNotarizationsProvider = ({ children }: ProvidersProps) => {
  /*
   * Contexts
   */

  // no-op

  /*
   * State
   */

  const [isSidebarInstalled, setIsSidebarInstalled] = useState<boolean>(false);
  const [sideBarVersion, setSideBarVersion] = useState<string | null>(null);

  const [verifiedTicketProofs, setVerifiedTicketProofs] = useState<ExtensionNotaryProofRequest[] | null>(null);
  const [initializeTransferProofs, setInitializeTransferProofs] = useState<ExtensionNotaryProofRequest[] | null>(null);
  const [transferProofs, setTransferProofs] = useState<ExtensionNotaryProofRequest[] | null>(null);

  /*
   * Extension Storage Reads
   */

  const refetchExtensionVersion = () => {
    window.postMessage({ type: ExtensionPostMessage.FETCH_EXTENSION_VERSION }, '*');

    // console.log('Posted Message: ', ExtensionPostMessage.FETCH_EXTENSION_VERSION);
  };

  const refetchVerifiedTicketProofs = () => {
    window.postMessage({ type: ExtensionPostMessage.FETCH_VERIFIED_TICKET_PROOFS }, '*');

    // console.log('Posted Message: ', ExtensionPostMessage.FETCH_VERIFIED_TICKET_PROOFS);
  };

  const refetchInitializeTransferProofs = () => {
    window.postMessage({ type: ExtensionPostMessage.FETCH_INITIALIZE_TRANSFER_PROOFS }, '*');

    // console.log('Posted Message: ', ExtensionPostMessage.FETCH_INITIALIZE_TRANSFER_PROOFS);
  };

  const refetchTransferProofs = () => {
    window.postMessage({ type: ExtensionPostMessage.FETCH_TRANSFER_PROOFS }, '*');

    // console.log('Posted Message: ', ExtensionPostMessage.FETCH_TRANSFER_PROOFS);
  };

  const openSidebarVerifyTickets = () => {
    window.postMessage({ type: ExtensionPostMessage.OPEN_SIDEBAR_VERIFY_TICKETS }, '*');

    // console.log('Posted Message: ', ExtensionPostMessage.OPEN_SIDEBAR_VERIFY_TICKETS);
  };

  const openSidebarInitializeTransfer = () => {
    window.postMessage({ type: ExtensionPostMessage.OPEN_SIDEBAR_INITIALIZE_TRANSFERS }, '*');

    // console.log('Posted Message: ', ExtensionPostMessage.OPEN_SIDEBAR_INITIALIZE_TRANSFERS);
  };

  const openSidebarTransfer = () => {
    window.postMessage({ type: ExtensionPostMessage.OPEN_SIDEBAR_TRANSFERS }, '*');

    // console.log('Posted Message: ', ExtensionPostMessage.OPEN_SIDEBAR_TRANSFERS);
  };

  /*
   * Handlers
   */

  const handleExtensionMessage = function(event: any) {
    if (event.origin !== window.location.origin) {
      return;
    };

    if (event.data.type && event.data.type === ExtensionReceiveMessage.EXTENSION_VERSION_RESPONSE) {
      handleExtensionVersionMessageReceived(event);
    };

    //
    // Ticketmaster
    //

    if (event.data.type && event.data.type === ExtensionReceiveMessage.VERIFIED_TICKET_PROOFS_RESPONSE) {
      handleExtensionVerifiedTicketsResponseMessageReceived(event);
    };

    if (event.data.type && event.data.type === ExtensionReceiveMessage.INITIALIZE_TRANSFER_PROOFS_RESPONSE) {
      handleExtensionTransfersInitializedResponseMessageReceived(event);
    };

    if (event.data.type && event.data.type === ExtensionReceiveMessage.TRANSFER_PROOFS_RESPONSE) {
      handleExtensionTransfersResponseMessageReceived(event);
    };
  };

  const handleExtensionVersionMessageReceived = function(event: ExtensionEventVersionMessage) {
    // console.log('Client received EXTENSION_VERSION_RESPONSE message');

    const version = event.data.version;

    setSideBarVersion(version);
    setIsSidebarInstalled(true);
  };

  const handleExtensionVerifiedTicketsResponseMessageReceived = function(event: ExtensionEventMessage) {
    if (event.data.requestHistory && event.data.requestHistory.notaryRequests) {
      const requestHistory = event.data.requestHistory.notaryRequests;

      setVerifiedTicketProofs(requestHistory);
    } else {
      setVerifiedTicketProofs(null);
    }
  };

  const handleExtensionTransfersInitializedResponseMessageReceived = function(event: ExtensionEventMessage) {
    if (event.data.requestHistory && event.data.requestHistory.notaryRequests) {
      const requestHistory = event.data.requestHistory.notaryRequests;

      setInitializeTransferProofs(requestHistory);
    } else {
      setInitializeTransferProofs(null);
    }
  };


  const handleExtensionTransfersResponseMessageReceived = function(event: ExtensionEventMessage) {
    if (event.data.requestHistory && event.data.requestHistory.notaryRequests) {
      const requestHistory = event.data.requestHistory.notaryRequests;

      setTransferProofs(requestHistory);
    } else {
      setTransferProofs(null);
    }
  };

  /*
   * Hooks
   */

  useEffect(() => {
    refetchExtensionVersion();
  }, []);

  useEffect(() => {
    window.addEventListener("message", handleExtensionMessage);
  
    return () => {
      window.removeEventListener("message", handleExtensionMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExtensionProxyProofsContext.Provider
      value={{
        isSidebarInstalled,
        sideBarVersion,
        refetchExtensionVersion,

        openSidebarInitializeTransfer,
        refetchInitializeTransferProofs,
        initializeTransferProofs,

        openSidebarVerifyTickets,
        refetchVerifiedTicketProofs,
        verifiedTicketProofs,

        openSidebarTransfer,
        refetchTransferProofs,
        transferProofs,
      }}
    >
      {children}
    </ExtensionProxyProofsContext.Provider>
  );
};

export default ExtensionNotarizationsProvider;

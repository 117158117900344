import React, { useEffect, useState, ReactNode } from 'react'
import { erc20ABI, useBalance, useContractRead } from 'wagmi'

import { esl, ZERO, ZERO_ADDRESS } from '@helpers/constants'
import useAccount from '@hooks/contexts/useAccount'
import useSmartContracts from '@hooks/contexts/useSmartContracts'

import BalancesContext from './BalancesContext'


interface ProvidersProps {
  children: ReactNode;
}

const BalancesProvider = ({ children }: ProvidersProps) => {
  /*
   * Contexts
   */

  const { isLoggedIn, loggedInEthereumAddress } = useAccount();
  const { 
    usdcAddress,
    socketBridgeAddress,
    lifiBridgeAddress,
    swapTicketExchangeAddress
  } = useSmartContracts();

  /*
   * State
   */

  const [ethBalance, setEthBalance] = useState<bigint | null>(null);
  const [usdcBalance, setUsdcBalance] = useState<bigint | null>(null);
  const [usdcApprovalToExchange, setUsdcApprovalToExchange] = useState<bigint | null>(null);
  const [usdcApprovalToSocketBridge, setUsdcApprovalToSocketBridge] = useState<bigint | null>(null);
  const [usdcApprovalToLifiBridge, setUsdcApprovalToLifiBridge] = useState<bigint | null>(null);

  const [shouldFetchEthBalance, setShouldFetchEthBalance] = useState<boolean>(false);
  const [shouldFetchUsdcBalance, setShouldFetchUsdcBalance] = useState<boolean>(false);
  const [shouldFetchUsdcApprovalToExchange, setShouldFetchUsdcApprovalToExchange] = useState<boolean>(false);
  const [shouldFetchUsdcApprovalToSocketBridge, setShouldFetchUsdcApprovalToSocketBridge] = useState<boolean>(false);
  const [shouldFetchUsdcApprovalToLifiBridge, setShouldFetchUsdcApprovalToLifiBridge] = useState<boolean>(false);

  /*
   * Contract Reads
   */

  const {
    data: ethBalanceRaw,
    refetch: refetchEthBalance,
  } = useBalance({
    address: loggedInEthereumAddress ?? ZERO_ADDRESS,
    enabled: shouldFetchEthBalance,
  });

  const {
    data: usdcBalanceRaw,
    refetch: refetchUsdcBalance,
  } = useBalance({
    address: loggedInEthereumAddress ?? ZERO_ADDRESS,
    token: usdcAddress,
    enabled: shouldFetchUsdcBalance,
  })

  const {
    data: usdcApprovalToExchangeRaw,
    refetch: refetchUsdcApprovalToExchange,
  } = useContractRead({
    address: usdcAddress,
    abi: erc20ABI,
    functionName: "allowance",
    args: [
      loggedInEthereumAddress ?? ZERO_ADDRESS,
      swapTicketExchangeAddress
    ],
    enabled: setShouldFetchUsdcApprovalToExchange,
  });

  const {
    data: usdcApprovalToSocketBridgeRaw,
    refetch: refetchUsdcApprovalToSocketBridge,
  } = useContractRead({
    address: usdcAddress,
    abi: erc20ABI,
    functionName: "allowance",
    args: [
      loggedInEthereumAddress ?? ZERO_ADDRESS,
        socketBridgeAddress
    ],
    enabled: shouldFetchUsdcApprovalToSocketBridge,
  });

  const {
    data: usdcApprovalToLifiBridgeRaw,
    refetch: refetchUsdcApprovalToLifiBridge,
  } = useContractRead({
    address: usdcAddress,
    abi: erc20ABI,
    functionName: "allowance",
    args: [
      loggedInEthereumAddress ?? ZERO_ADDRESS,
      lifiBridgeAddress
    ],
    enabled: shouldFetchUsdcApprovalToLifiBridge,
  });

  /*
   * Hooks
   */

  useEffect(() => {
    esl && console.log('shouldFetchEthBalance_1');
    esl && console.log('checking isLoggedIn: ', isLoggedIn);
    esl && console.log('checking loggedInEthereumAddress: ', loggedInEthereumAddress);

    if (isLoggedIn && loggedInEthereumAddress) {
      esl && console.log('shouldFetchEthBalance_2');

      setShouldFetchEthBalance(true);
    } else {
      esl && console.log('shouldFetchEthBalance_3');

      setShouldFetchEthBalance(false);

      setEthBalance(null);
      setUsdcBalance(null);
      setUsdcApprovalToExchange(null);
    }
  }, [isLoggedIn, loggedInEthereumAddress]);

  useEffect(() => {
    esl && console.log('shouldFetchUsdcBalanceAndApproval_1');
    esl && console.log('checking isLoggedIn: ', isLoggedIn);
    esl && console.log('checking loggedInEthereumAddress: ', loggedInEthereumAddress);
    esl && console.log('checking swapTicketExchangeAddress: ', swapTicketExchangeAddress);
    esl && console.log('checking usdcAddress: ', usdcAddress);

    if (isLoggedIn && loggedInEthereumAddress && swapTicketExchangeAddress && usdcAddress) {
      esl && console.log('shouldFetchUsdcBalanceAndApproval_2');

      setShouldFetchUsdcBalance(true);
      setShouldFetchUsdcApprovalToExchange(true);
      setShouldFetchUsdcApprovalToSocketBridge(true);
      setShouldFetchUsdcApprovalToLifiBridge(true);
    } else {
      esl && console.log('shouldFetchUsdcBalanceAndApproval_3');

      setShouldFetchUsdcBalance(false);
      setShouldFetchUsdcApprovalToExchange(false);
      setShouldFetchUsdcApprovalToSocketBridge(false);
      setShouldFetchUsdcApprovalToLifiBridge(false);

      setEthBalance(null);
      setUsdcBalance(null);
      setUsdcApprovalToExchange(null);
    }
  }, [isLoggedIn, loggedInEthereumAddress, swapTicketExchangeAddress, usdcAddress]);
  
  useEffect(() => {
    esl && console.log('ethBalanceRaw_1');
    esl && console.log('checking ethBalanceRaw: ', ethBalanceRaw);
  
    if (ethBalanceRaw) {
      esl && console.log('ethBalanceRaw_2');

      const ethBalanceProcessed = ethBalanceRaw.value;

      setEthBalance(ethBalanceProcessed);
    } else {
      esl && console.log('ethBalanceRaw_3');

      setEthBalance(null);
    }
  }, [ethBalanceRaw]);

  useEffect(() => {
    esl && console.log('usdcBalanceRaw_1');
    esl && console.log('checking usdcBalanceRaw: ', usdcBalanceRaw);
  
    if (usdcBalanceRaw) {
      esl && console.log('usdcBalanceRaw_2');

      const usdcBalanceRawProcessed = usdcBalanceRaw.value;

      setUsdcBalance(usdcBalanceRawProcessed);
    } else {
      esl && console.log('usdcBalanceRaw_3');

      setUsdcBalance(null);
    }
  }, [usdcBalanceRaw]);

  useEffect(() => {
    esl && console.log('usdcApprovalToRampRaw_1');
    esl && console.log('checking usdcApprovalToExchangeRaw: ', usdcApprovalToExchangeRaw);
  
    if (usdcApprovalToExchangeRaw || usdcApprovalToExchangeRaw === ZERO) { // BigInt(0) is falsy
      esl && console.log('usdcApprovalToExchangeRaw_2');

      setUsdcApprovalToExchange(usdcApprovalToExchangeRaw);
    } else {
      esl && console.log('usdcApprovalToExchangeRaw_3');
      
      setUsdcApprovalToExchange(null);
    }
  }, [usdcApprovalToExchangeRaw]);

  useEffect(() => {
    esl && console.log('usdcApprovalToSocketBridgeRaw_1');
    esl && console.log('checking usdcApprovalToSocketBridgeRaw: ', usdcApprovalToSocketBridgeRaw);
  
    if (usdcApprovalToSocketBridgeRaw || usdcApprovalToSocketBridgeRaw === ZERO) { // BigInt(0) is falsy
      esl && console.log('usdcApprovalToSocketBridgeRaw_2');

      setUsdcApprovalToSocketBridge(usdcApprovalToSocketBridgeRaw);
    } else {
      esl && console.log('usdcApprovalToSocketBridgeRaw_3');
      
      setUsdcApprovalToSocketBridge(null);
    }
  }, [usdcApprovalToSocketBridgeRaw]);

  useEffect(() => {
    esl && console.log('usdcApprovalToLifiBridgeRaw_1');
    esl && console.log('checking usdcApprovalToLifiBridgeRaw: ', usdcApprovalToLifiBridgeRaw);
  
    if (usdcApprovalToLifiBridgeRaw || usdcApprovalToLifiBridgeRaw === ZERO) { // BigInt(0) is falsy
      esl && console.log('usdcApprovalToLifiBridgeRaw_2');

      setUsdcApprovalToLifiBridge(usdcApprovalToLifiBridgeRaw);
    } else {
      esl && console.log('usdcApprovalToLifiBridgeRaw_3');
      
      setUsdcApprovalToLifiBridge(null);
    }
  }, [usdcApprovalToLifiBridgeRaw]);

  return (
    <BalancesContext.Provider
      value={{
        ethBalance,
        refetchEthBalance,
        shouldFetchEthBalance,

        usdcBalance,
        refetchUsdcBalance,
        shouldFetchUsdcBalance,

        usdcApprovalToExchange,
        refetchUsdcApprovalToExchange,
        shouldFetchUsdcApprovalToExchange,

        usdcApprovalToSocketBridge,
        refetchUsdcApprovalToSocketBridge,
        usdcApprovalToLifiBridge,
        refetchUsdcApprovalToLifiBridge
      }}
    >
      {children}
    </BalancesContext.Provider>
  );
};

export default BalancesProvider

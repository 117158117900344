import React, { useCallback, useState, useRef, useEffect, useLayoutEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ChevronDown } from 'react-feather';

import { formatEventDateTime } from '@helpers/dateFormat';
import { colors } from '@theme/colors';
import { OrderSettlementDetails } from '@components/Listings/Seller/OrderTable';
import { TransferInstructionsRow } from '@components/Listings/FinalizeOrder/TransferInstructionsRow';


interface TransferInstructionsProps {
  orderDetails: OrderSettlementDetails
}

export const TransferInstructions: React.FC<TransferInstructionsProps> = ({
  orderDetails
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  /*
   * State
   */

  const [isOpen, setIsOpen] = useState(true);

  const [centerPosition, setCenterPosition] = useState(0);

  const [canAnimate, setCanAnimate] = useState(false);

  /*
   * Hooks
   */

    const updateCenterPosition = useCallback(() => {
      if (containerRef.current && titleRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const titleWidth = titleRef.current.offsetWidth;
        const newCenterPosition = (containerWidth - titleWidth) / 2;
        setCenterPosition(newCenterPosition);
      }
    }, []);
  
    useLayoutEffect(() => {
      updateCenterPosition();
    }, [updateCenterPosition]);
  
    useEffect(() => {
      const handleResize = () => {
        requestAnimationFrame(updateCenterPosition);
      };
  
      window.addEventListener('resize', handleResize);
      
      const timer = setTimeout(() => setCanAnimate(true), 0);
  
      return () => {
        window.removeEventListener('resize', handleResize);
        clearTimeout(timer);
      };
    }, [updateCenterPosition]);
  
    const toggleOpen = useCallback(() => setIsOpen(prev => !prev), []);
  
    const titleStyle = useMemo(() => ({
      transform: `translateX(${isOpen ? centerPosition : 0}px)`
    }), [isOpen, centerPosition]);

  /*
   * Component
   */

  return (
    <Wrapper ref={wrapperRef}>
        <TitleLabelAndDropdownIconContainer ref={containerRef} $isOpen={isOpen}>
          <TitleLabelWrapper>
            <TitleLabel  ref={titleRef}  $canAnimate={canAnimate} style={titleStyle}>
              Review Instructions
            </TitleLabel>
          </TitleLabelWrapper>
          
          <StyledChevronDown
            onClick={toggleOpen}
            $isOpen={isOpen}
          />
        </TitleLabelAndDropdownIconContainer>

        <InstructionsDropdown $isOpen={isOpen}>
          <TransferInstructionsRow step={1}>
            Open browser sidebar and select <strong>Go to Ticketmaster</strong> to open your events
          </TransferInstructionsRow>

          <TransferInstructionsRow step={2}>
            Select the event with the completed ticket transfer
          </TransferInstructionsRow>

          <TicketInfoContainer>
            <Label>
              {orderDetails.eventName}
            </Label>

            <Label>
              {formatEventDateTime(orderDetails.eventDate)}
            </Label>
          </TicketInfoContainer>

          <TransferInstructionsRow step={3}>
            Follow the instructions in the sidebar to generate proof of completed ticket transfer
          </TransferInstructionsRow>

          <TransferInstructionsRow step={4}>
            Navigate back to this page and submit proof of transfer
          </TransferInstructionsRow>
        </InstructionsDropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 14px 20px 12px 20px;
  border-radius: 8px;
  border: 1px solid ${colors.defaultBorderColor};
  background-color: ${colors.inputDefaultColor};
`;

const TitleLabelAndDropdownIconContainer = styled.div<{ $isOpen: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${({ $isOpen }) => $isOpen ? '1.25rem' : '0rem'};
  transition: padding-bottom 0.4s ease-in-out;
  align-items: center;
`;

const TitleLabelWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
`;

const TitleLabel = styled.div<{ $canAnimate: boolean }>`
  display: flex;
  align-items: center;
  font-size: 15px;
  transition: ${({ $canAnimate }) => $canAnimate ? 'transform 0.4s ease-out' : 'none'};
`;

const StyledChevronDown = styled(ChevronDown)<{ $isOpen: boolean }>`
  width: 20px;
  height: 20px;
  color: #CED4DA;

  transition: transform 0.4s;
  transform: ${({ $isOpen }) => $isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const InstructionsDropdown = styled.div<{ $isOpen: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.inputDefaultColor};
  color: #FFF;
  gap: 16px;
  overflow: hidden;

  max-height: ${({ $isOpen }) => $isOpen ? '500px' : '0rem'};
  transition: max-height 0.4s ease-out;
`;

const TicketInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid;
  border-color: #C0C0C0;
  background-color: #FFF;
  border-radius: 8px;
  padding: 16px;
`;

const Label = styled.span`
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  color: #333333;
`;

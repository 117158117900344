export const UpdateListingTransactionStatus = {
  DEFAULT: 'default',
  PRICE_NOT_UPDATED: 'price_not_updated',
  TICKETS_PENDING_ACTION: 'tickets_pending_action',
  TRANSACTION_SIGNING: 'transaction_signing',
  TRANSACTION_MINING: 'transaction_mining',
  VALID: 'valid',
  TRANSACTION_SUCCEEDED: 'transaction_succeeded'
};

export type UpdateListingTransactionStatusType = typeof UpdateListingTransactionStatus[keyof typeof UpdateListingTransactionStatus];

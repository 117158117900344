import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ArrowLeft, Check, Facebook, Link, Twitter } from 'react-feather';

import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { Z_INDEX } from '@theme/zIndex';
import { RowBetween } from '@components/layouts/Row';
import { Overlay } from '@components/modals/Overlay';
import { Button } from '@components/common/Button';


interface EmailWarningModalProps {
  onBackClick: () => void;
  onConfirm: () => void;
};

export const EmailWarningModal: React.FC<EmailWarningModalProps> = ({
  onBackClick,
  onConfirm,
}) => {

  /*
   * Handlers
   */

  const handleOverlayClick = () => {
    onBackClick();
  };

  /*
   * Component
   */

  return (
    <ModalAndOverlayContainer>
      <Overlay onClick={handleOverlayClick}/>

      <ModalContainer>
        <RowBetween>
          <div style={{ flex: 0.25 }}>
            <button
              onClick={handleOverlayClick}
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            >
              <StyledArrowLeft/>
            </button>
          </div>

          <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
            Email Confirmation
          </ThemedText.HeadlineSmall>

          <div style={{ flex: 0.25 }}/>
        </RowBetween>

        <BodyContainer>
          <WarningText>
            You have not specified an email to receive listing alerts. Not receiving alerts may cause you to miss buys on your listing. Do you wish to proceed?
          </WarningText>
        </BodyContainer>

        <ButtonContainer>
          <Button
            fullWidth={true}
            onClick={onConfirm}
          >
            Proceed Without Setting Email
          </Button>
        </ButtonContainer>
      </ModalContainer>
    </ModalAndOverlayContainer>
  );
};

const ModalAndOverlayContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: flex-start;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.overlay};
`;

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${colors.black};
`;

const ModalContainer = styled.div`
  max-height: 80vh;
  width: 80vw;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1.5rem 1.5rem;
  background-color: ${colors.background};
  color: ${colors.black};
  z-index: ${Z_INDEX.buy_modal};
  gap: 1rem;
  overflow-y: auto;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const BodyContainer = styled.div`
  display: block;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  color: #333;
  line-height: 1.4;
  font-size: 15px;
`;

const WarningText = styled(ThemedText.BodyPrimary)`
  text-align: center;
  color: ${colors.darkText};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

import { useEffect, useState, ReactNode } from 'react';
import { useContractRead } from 'wagmi';

import {
  SellerListingsStore,
  DetailedListing,
  DetailedTicket,
  Event,
  Listing,
  Ticket,
  Order
} from '@helpers/types';
import { esl, ZERO_ADDRESS } from '@helpers/constants';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import useAccount from '@hooks/contexts/useAccount';
import useEvents from '@hooks/contexts/useEvents';

import SellersContext from './SellersContext';


interface ProvidersProps {
  children: ReactNode;
}

const Sellers = ({ children }: ProvidersProps) => {
  /*
   * Contexts
   */

  const { isLoggedIn, loggedInEthereumAddress } = useAccount();
  const { swapTicketExchangeAddress, swapTicketExchangeAbi, verifiedTicketRegistryAbi, verifiedTicketRegistryAddress } = useSmartContracts();
  const { eventsByEventId } = useEvents();

  /*
   * State
   */
  const [sellerListingIds, setSellerListingIds] = useState<bigint[] | null>(null);

  const [sellerListings, setSellerListings] = useState<Listing[] | null>(null);
  const [sellerTickets, setSellerTickets] = useState<DetailedTicket[] | null>(null);
  const [sellerOrders, setSellerOrders] = useState<Order[] | null>(null);

  const [sellerListingsStore, setSellerListingsStore] = useState<SellerListingsStore | null>(null);

  const [shouldFetchSellerTickets, setShouldFetchSellerTickets] = useState<boolean>(false);
  const [shouldFetchSellerListings, setShouldFetchSellerListings] = useState<boolean>(false);
  const [shouldFetchSellerListingOrders, setShouldFetchSellerListingOrders] = useState<boolean>(false);

  /*
   * Contract Reads
   */

  // getUserListings(address _user) (ListingWithId[] memory listingsOutput)
  const {
    data: sellerListingsRaw,
    refetch: refetchSellerListings,
  } = useContractRead({
    address: swapTicketExchangeAddress,
    abi: swapTicketExchangeAbi,
    functionName: 'getUserListings',
    args: [
      loggedInEthereumAddress
    ],
    enabled: shouldFetchSellerListings
  });

  // getListingOrders(uint256[] memory _listingIds) (OrderWithId[][] memory ordersOutput) 
  const {
    data: sellerOrdersRaw,
    refetch: refetchSellerOrders,
  } = useContractRead({
    address: swapTicketExchangeAddress,
    abi: swapTicketExchangeAbi,
    functionName: 'getListingOrders',
    args: [
      sellerListingIds
    ],
    enabled: shouldFetchSellerListingOrders
  });

   // getUserTickets(address _user) external view returns (TicketWithId[] memory)
  const {
    data: sellerTicketsRaw,
    refetch: refetchSellerTickets,
  } = useContractRead({
    address: verifiedTicketRegistryAddress,
    abi: verifiedTicketRegistryAbi,
    functionName: 'getUserTickets',
    args: [
      loggedInEthereumAddress
    ],
    enabled: shouldFetchSellerTickets
  });

  /*
   * Hooks
   */

  useEffect(() => {
    esl && console.log('shouldFetchListings_1');
    esl && console.log('checking isLoggedIn: ', isLoggedIn);
    esl && console.log('checking loggedInEthereumAddress: ', loggedInEthereumAddress);
    esl && console.log('checking swapTicketExchangeAddress: ', swapTicketExchangeAddress);

    if (isLoggedIn && loggedInEthereumAddress && swapTicketExchangeAddress) {
      esl && console.log('shouldFetchListings_2');

      setShouldFetchSellerListings(true);
    } else {
      esl && console.log('shouldFetchListings_3');

      setShouldFetchSellerListings(false);

      setSellerListings(null);
    }
  }, [isLoggedIn, loggedInEthereumAddress, swapTicketExchangeAddress]);

  useEffect(() => {
    esl && console.log('sellerListings_1');
    esl && console.log('checking sellerListings: ', sellerListingsRaw);
  
    if (sellerListingsRaw && sellerListingsRaw.length > 0) {
      esl && console.log('sellerListings_2');

      const sanitizedListings = sanitizeRawListings(sellerListingsRaw);
      setSellerListings(sanitizedListings);

      const fetchedListingIds = sanitizedListings.map(listing => BigInt(listing.listingId));
      if (fetchedListingIds.length > 0) {
        setSellerListingIds(fetchedListingIds); // Fetches for listings, even if there are no orders

        setShouldFetchSellerListingOrders(true);
      } else {
        setShouldFetchSellerListingOrders(false);

        setSellerListingIds(null);
      }
    } else {
      esl && console.log('sellerListings_3');
  
      setSellerListings(null);
    }
  }, [sellerListingsRaw]);

  useEffect(() => {
    esl && console.log('shouldFetchSellerTicketIds_1');
    esl && console.log('checking isLoggedIn: ', isLoggedIn);
  
    if (isLoggedIn) {
      esl && console.log('shouldFetchSellerTicketIds_2');
  
      setShouldFetchSellerTickets(true);
    } else {
      esl && console.log('shouldFetchSellerTicketIds_3');

      setShouldFetchSellerTickets(false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    esl && console.log('sellerTickets_1');
    esl && console.log('sellerTicketsRaw: ', sellerTicketsRaw);
    esl && console.log('eventsByEventId: ', eventsByEventId);
  
    if (eventsByEventId && sellerTicketsRaw && sellerTicketsRaw.length > 0) {
      esl && console.log('sellerTickets_2');

      const sanitizedTickets = sanitizeTickets(sellerTicketsRaw);

      const detailedTickets = sanitizedTickets.map((ticket: Ticket) => {
        const event = eventsByEventId[ticket.eventId];
        if (!event) {
          setSellerTickets(null);

          throw new Error(`Event not found for ticket ${ticket.ticketId}`);
        };

        return {
          ...ticket,
          event
        };
      });

      setSellerTickets(detailedTickets);
    } else {
      esl && console.log('sellerTickets_3');
  
      setSellerTickets(null);
    }
  }, [sellerTicketsRaw, eventsByEventId]);

  useEffect(() => {
    esl && console.log('sellerOrders_1');
    esl && console.log('checking sellerOrdersRaw', sellerOrdersRaw);
  
    if (sellerOrdersRaw) {
      esl && console.log('sellerOrders_2: ', sellerOrdersRaw);

      const flattenedOrdersWithId = sellerOrdersRaw.flat();
      const validOrders = flattenedOrdersWithId.filter((orderWithId: any) => orderWithId.order.buyer !== ZERO_ADDRESS);
      if (validOrders.length > 0) {
        esl && console.log('sellerOrders_3: ', validOrders);
  
        const sanitizedOrders = sanitizeRawOrders(validOrders);
        setSellerOrders(sanitizedOrders);
      } else {
        esl && console.log('sellerOrders_4');
    
        setSellerOrders([]); // Return empty array to indicate loaded state with no orders
      }
    } else {
      esl && console.log('sellerOrders_5');
  
      setSellerOrders(null);
    }
  }, [sellerOrdersRaw]);
  
  useEffect(() => {
    esl && console.log('sellerListingstore_1');
    esl && console.log('checking sellerListings: ', sellerListings);
    esl && console.log('checking sellerTickets: ', sellerTickets);
    esl && console.log('checking sellerOrders: ', sellerOrders);
    esl && console.log('checking eventsByEventId: ', eventsByEventId);
  
    if (sellerListings && sellerTickets && sellerOrders && eventsByEventId) {
      esl && console.log('sellerListingstore_2');

      const ordersByListingIdMap: { [listingId: string]: Order[] } = {};
      sellerOrders.forEach(order => {
        const listingId = order.listingId.toString();
        if (!ordersByListingIdMap[listingId]) {
          ordersByListingIdMap[listingId] = [];
        }

        ordersByListingIdMap[listingId].push(order);
      });

      // Events map for the events that have listings
      const filteredEventsByEventId: { [eventId: string]: Event } = {};

      // Map listings to DetailedListing
      const detailedSellerListings: DetailedListing[] = sellerListings.map((listing: Listing) => {
        const unfilledTickets = listing.unfilledTicketIds.map(ticketId => 
          sellerTickets.find(ticket => ticket.ticketId === ticketId)
        ).filter((ticket): ticket is DetailedTicket => ticket !== undefined);

        const pendingTickets = listing.pendingTicketIds.map(ticketId => 
          sellerTickets.find(ticket => ticket.ticketId === ticketId)
        ).filter((ticket): ticket is DetailedTicket => ticket !== undefined);

        const listingOrders = ordersByListingIdMap[listing.listingId.toString()];

        // Populate filteredEventsByEventId
        const event = eventsByEventId[listing.eventId];
        if (!event) {
          setSellerListingsStore(null);

          throw new Error(`Event not found for listing ${listing.listingId}`);
        } else {
          if (!filteredEventsByEventId[listing.eventId]) {
            filteredEventsByEventId[listing.eventId] = event;
          }
        };

        // Populate detailedSellerListings
        return {
          ...listing,
          unfilledTickets,
          pendingTickets,
          orders: listingOrders
        };
      });

      const newStore: SellerListingsStore = {
        listings: detailedSellerListings,
        events: filteredEventsByEventId
      };

      setSellerListingsStore(newStore);
    } else {
      esl && console.log('sellerListingstore_3');
  
      setSellerListingsStore(null);
    }
  }, [sellerListings, sellerTickets, sellerOrders, eventsByEventId]);

  /*
   * Helpers
   */

  const sanitizeRawListings = (rawListingsData: any[]) => {
    const sanitizedListings: Listing[] = [];
  
    for (let i = rawListingsData.length - 1; i >= 0; i--) {
      const listingWithIdData = rawListingsData[i];
      const listingData = listingWithIdData.listing;
      
      const listing: Listing = {
        listingId: listingWithIdData.listingId,
        seller: listingData.seller.toString(),
        price: listingData.price,
        createdAt: listingData.createdAt,
        expiration: listingData.expiration,
        unfilledTicketIds: listingData.unfilledTickets,
        pendingTicketIds: listingData.pendingTickets,
        eventId: listingData.eventId,
        encryptionKey: listingData.encryptionKey.substring(2),
      };
  
      sanitizedListings.push(listing);
    }
  
    return sanitizedListings;
  };

  const sanitizeRawOrders = (rawOrdersData: any[]): Order[] => {
    const sanitizedOrders: Order[] = [];

    for (let i = rawOrdersData.length - 1; i >= 0; i--) {
      const orderWithIdData = rawOrdersData[i];
      const orderData = orderWithIdData.order;
      if (!orderData) {
        continue;
      }
      
      const order: Order = {
        orderId: orderWithIdData.orderId,
        buyer: orderData.buyer,
        encryptedEmail: orderData.encryptedEmail,
        emailHash: orderData.emailHash,
        listingId: orderData.listingId,
        transferId: orderData.transferId,
        ticketIds: orderData.ticketIds,
        purchaseValue: orderData.purchaseValue,
        createdAt: orderData.createdAt,
        refundInitiatedAt: orderData.refundInitiatedAt,
      };
  
      sanitizedOrders.push(order);
    }

    return sanitizedOrders;
  };

  const sanitizeTickets = (rawTicketsData: any[]): Ticket[] => {
    const sanitizedTickets: Ticket[] = [];

    for (let i = rawTicketsData.length - 1; i >= 0; i--) {
      const rawTicketWithIdData = rawTicketsData[i];
      const rawTicketData = rawTicketWithIdData.ticket;

      const sanitizedTicket: Ticket = {
        ticketId: rawTicketWithIdData.ticketId,
        owner: rawTicketData.owner,
        ticketmasterId: rawTicketData.ticketmasterId,
        section: rawTicketData.section,
        row: rawTicketData.row,
        seat: rawTicketData.seat,
        status: Number(rawTicketData.status),
        eventId: rawTicketData.eventId,
        exchange: rawTicketData.exchange,
        listingId: rawTicketData.listingId,
      };

      sanitizedTickets.push(sanitizedTicket);
    }

    return sanitizedTickets;
  };

  return (
    <SellersContext.Provider
      value={{
        sellerListingsStore,
        refetchSellerListingStore: refetchSellerListings,
        shouldFetchSellerListings,

        sellerOrders,
        refetchSellerOrders,
        shouldFetchSellerListingOrders,
        
        sellerTickets,
        shouldFetchSellerTickets,
        refetchSellerTickets
      }}
    >
      {children}
    </SellersContext.Provider>
  );
};

export default Sellers;

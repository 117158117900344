import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Tag } from 'react-feather';

import { CustomCheckbox } from '@components/common/Checkbox';
import { colors } from '@theme/colors';


interface TicketsRowProps {
  isScrolling: boolean;
  seatSummaryText: string;
  isSelected: boolean;
  onRowClick: () => void;
  rowIndex: number;
}

export const TicketsRow: React.FC<TicketsRowProps> = ({
  isScrolling,
  seatSummaryText,
  isSelected,
  onRowClick,
}: TicketsRowProps) => {
  TicketsRow.displayName = "TicketsRow";

  return (
    <Container
      onClick={onRowClick}
      selected={isSelected}
      scrolling={isScrolling}
    >
      <TicketDetailsAndCheckboxContainer
        $scrolling={isScrolling}
      >
        <TicketDetailsContainer>
          <SummaryLabelsAndIconContainer>
            <StyledTag />

            <SummaryLabel>
              <SummaryLabelValue>{seatSummaryText}</SummaryLabelValue>
            </SummaryLabel>
          </SummaryLabelsAndIconContainer>

        </TicketDetailsContainer>

        <CheckboxContainer>
          <CustomCheckbox
            checked={isSelected}
            onChange={onRowClick}
            color={'neutral'}
            boxVariant={'outlined'}
          />
        </CheckboxContainer>
      </TicketDetailsAndCheckboxContainer>
    </Container>
  );
};

const Container = styled.div<{ selected: boolean, scrolling: boolean }>`
  display: flex;
  flex-direction: row;

  ${({ selected }) => selected && `
    background-color: #D2EDD2;
    box-shadow: none;
  `}

  ${({ selected, scrolling }) => (!selected && !scrolling) && `
    &:hover {
      background-color: #D2EDD2;
      box-shadow: none;
    }
  `}
`;

const TicketDetailsAndCheckboxContainer = styled.div<{ $scrolling: boolean }>`
  width: 100%; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem;
  padding: 1.25rem 1.5rem;

  ${({ $scrolling }) => $scrolling && css`
    padding-right: 1.5rem;
    transition: padding-right 0.3s ease;
  `}

  ${({ $scrolling }) => !$scrolling && css`
    padding-right: 1rem;
    transition: padding-right 0.3s ease;
  `}
`;

const TicketDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SummaryLabelsAndIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

const StyledTag = styled(Tag)`
  color: ${colors.darkText};
  width: 20px;
  height: 20px;
`;

const SummaryLabel = styled.label`
  display: flex;
  font-size: 15px;
  color: #FFFFFF;
  align-items: center;
`;

const SummaryLabelValue = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 3;
`;

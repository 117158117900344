import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { AutoColumn } from '@components/layouts/Column';
import { OrderRefundData, OrderTable } from '@components/Orders/OrderTable';
import { InitiateRefundForm } from '@components/Orders/InitiateRefund';
import { STATE_REFETCH_INTERVAL } from '@helpers/constants';
import useBuyers from '@hooks/contexts/useBuyers';


export default function Orders() {
  /*
   * Context
   */

  const { refetchBuyerOrders, shouldFetchBuyerOrders } = useBuyers();

  /*
   * State
   */

  const [selectedOrderForRefundDetails, setSelectedOrderForRefundDetails] = useState<OrderRefundData | null>(null);
  const [isInitiateRefund, setIsInitiateRefund] = useState<boolean>(false);

  /*
   * Hooks
   */

  useEffect(() => {
    if (shouldFetchBuyerOrders) {
      const intervalId = setInterval(() => {
        refetchBuyerOrders?.();
      }, STATE_REFETCH_INTERVAL);

      return () => clearInterval(intervalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchBuyerOrders]);

  /*
   * Handlers
   */

  const handleInitializeRefundClicked = (details: OrderRefundData) => {
    setSelectedOrderForRefundDetails(details);
    setIsInitiateRefund(true);
  }

  const handleInitializeRefundBackClicked = () => {
    setIsInitiateRefund(false);

    setSelectedOrderForRefundDetails(null);
  }

  /*
   * Component
   */
  function renderContent() {
    if (isInitiateRefund) {
      return (
        <InitiateRefundForm
          handleBackClick={handleInitializeRefundBackClicked}
          orderDetails={selectedOrderForRefundDetails}
        />
      );
    } else {
      return (
        <OrderTable
        handleInitiateRefundPressed={handleInitializeRefundClicked}
      />
      )
    }
  };

  return (
    <Wrapper>
      {renderContent()}
    </Wrapper>
  );
};

const Wrapper = styled(AutoColumn)`
  width: 920px;
  margin: auto;
`;

import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { AlertTriangle, Sidebar, Tag } from 'react-feather';
import Link from '@mui/material/Link';
import { browserName } from 'react-device-detect';

import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { Button } from '@components/common/Button'
import { ProofRow } from '@components/Listings/NewListing/VerifyTickets/ProofRow';
import { ExtensionNotaryProofRequest } from '@hooks/useBrowserExtension';
import { VerifyTicketTransactionStatus } from '@helpers/types';
import { formatEventDateTime } from '@helpers/dateFormat';
import { keccak256 } from '@helpers/keccack';
import { EXTENSION_DOCS_URL } from '@helpers/docUrls';
import { commonStrings } from "@helpers/strings";
import { CHROME_EXTENSION_URL } from '@helpers/constants';
import useExtensionNotarizations from '@hooks/contexts/useExtensionProxyProofs';
import useTableScroll from '@hooks/useTableScroll';

import chromeSvg from '@assets/images/browsers/chrome.svg';
import braveSvg from '@assets/images/browsers/brave.svg';


const NOTARY_PROOF_FETCH_INTERVAL = 5000;

type ExtensionNotaryProofRow = {
  proof: string;
  metadata: string;
  eventParent: string;
  eventChild: string;
  seating: string;
  date: string;
};

interface ProofTableProps {
  sellerRawTicketIds: string[];
  setProofsToSubmit: (proofs: string[]) => void;
  handleSubmitTransactionPressed: () => void;
  proofSelectionStatus: string;
};

export const ProofTable: React.FC<ProofTableProps> = ({
  sellerRawTicketIds,
  setProofsToSubmit,
  handleSubmitTransactionPressed,
  proofSelectionStatus,
}) => {
  /*
   * Context
   */

  const {
    openSidebarVerifyTickets,
    refetchExtensionVersion,
    refetchVerifiedTicketProofs,
    isSidebarInstalled,
    verifiedTicketProofs,
  } = useExtensionNotarizations();

  /*
   * State
   */

  const [isNotSupportedBrowser, setIsNotSupportedBrowser] = useState<boolean>(false);

  const [loadedProxyProofs, setLoadedProxyProofs] = useState<ExtensionNotaryProofRow[]>([]);

  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);

  const [ctaButtonTitle, setCtaButtonTitle] = useState<string>("");
  
  const [isInstallExtensionClicked, setIsInstallExtensionClicked] = useState<boolean>(false);

  /*
   * Handlers
   */

  const handleJoinTelegramClicked = () => {
    window.open('https://t.me/+XDj9FNnW-xs5ODNl', '_blank');
  };

  const handleInstallExtensionClicked = () => {
    window.open(CHROME_EXTENSION_URL, '_blank');
    setIsInstallExtensionClicked(true)
  };

  const handleRowClick = (index: number) => {
    if (selectedIndices.includes(index)) {
      setSelectedIndices(selectedIndices.filter(i => i !== index));
    } else {
      setSelectedIndices([...selectedIndices, index]);
    }
  };

  /*
   * Helpers
   */

  const browserSvg = () => {
    switch (browserName) {
      case 'Brave':
        return braveSvg;

      case 'Chrome':
      default:
        return chromeSvg;
    }
  };

  const addToBrowserCopy = () => {
    switch (browserName) {
      case 'Brave':
        return 'Add to Brave';

      case 'Chrome':
        return 'Add to Chrome';

      default:
        return 'Add to browser';
    }
  };

  const ctaDisabled = (): boolean => {
    switch (proofSelectionStatus) {
      case VerifyTicketTransactionStatus.DEFAULT:
      case VerifyTicketTransactionStatus.TRANSACTION_SIGNING:
      case VerifyTicketTransactionStatus.TRANSACTION_MINING:
        return true;

      case VerifyTicketTransactionStatus.VALID:
      default:
        return false;
    }
  };

  const shouldShowOpenSidebarButton = useCallback(() => {
    const noProofsFromExtension = loadedProxyProofs.length === 0;

    const statusIsNotSucceeded = proofSelectionStatus !== VerifyTicketTransactionStatus.TRANSACTION_SUCCEEDED;

    return noProofsFromExtension && statusIsNotSucceeded;
  }, [loadedProxyProofs, proofSelectionStatus]);

  /*
   * Hooks
   */

  useEffect(() => {
    if (loadedProxyProofs.length > 0 && selectedIndices.length > 0) {
      const selectedProxyProofs = loadedProxyProofs.filter((_, index) => selectedIndices.includes(index));

      const parsedProofs = selectedProxyProofs.map(selectedNotarization => selectedNotarization.proof);

      setProofsToSubmit(parsedProofs);
    } else {
      setProofsToSubmit([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedProxyProofs, selectedIndices]);

  useEffect(() => {
    const supportedBrowsers = ['Chrome', 'Brave'];
    setIsNotSupportedBrowser(supportedBrowsers.indexOf(browserName) === -1);

    // Moot to run this on an interval because the content script needs to be injected
    refetchExtensionVersion();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSidebarInstalled) {
      return;
    }
  
    let intervalId: NodeJS.Timeout | null = null;
  
    const setupInterval = (callback: () => void) => {
      callback();
  
      if (intervalId) {
        clearInterval(intervalId);
      };
      
      intervalId = setInterval(callback, NOTARY_PROOF_FETCH_INTERVAL);
    };
  
    setupInterval(refetchVerifiedTicketProofs);
  
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarInstalled]);
  
  useEffect(() => {
    if (!isInstallExtensionClicked) {
      return;
    }
  
    let intervalId: NodeJS.Timeout | null = null;
  
    const setupInterval = (callback: () => void) => {
      callback();
  
      if (intervalId) {
        clearInterval(intervalId);
      };
      
      intervalId = setInterval(callback, NOTARY_PROOF_FETCH_INTERVAL);
    };
  
    if (!isSidebarInstalled) {
      setupInterval(refetchExtensionVersion);
    }
  
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInstallExtensionClicked, isSidebarInstalled]);

  useEffect(() => {
    if (verifiedTicketProofs && verifiedTicketProofs.length > 0) {
      const fetchedVerifiedTicketProofs: ExtensionNotaryProofRow[] = [];
      verifiedTicketProofs.forEach((request: ExtensionNotaryProofRequest) => {
        const metadata = request.metadata;
        
        // [
        //   "Thu, 08 Aug 2024 20:24:02 GMT",
        //   ["San Jose Sharks vs. Columbus Blue Jackets"],
        //   [ "18"],
        //   [ "17"],
        //   ["222"],
        //   [2024-11-06T03:30:00Z"],
        //   ["San Jose Sharks"],
        //   ["cop-ticket-29000476521080043"]
        // ]

        const rawTicketId = metadata[7][0];
        const rawTicketIdHash = keccak256(rawTicketId);
        const isTicketIdAlreadyRegistered = sellerRawTicketIds.includes(rawTicketIdHash);
        if (isTicketIdAlreadyRegistered) {
          return;
        }

        const eventChild = metadata[1];
        const eventParent = metadata[6];

        const eventDateString = new Date(metadata[5]);
        const eventTimestampUnix = BigInt(Math.floor(eventDateString.getTime() / 1000));
        const eventDate = formatEventDateTime(eventTimestampUnix);

        const section = metadata[4];
        const row = metadata[3];
        const seat = metadata[2];
        const seating = `Sec ${section}, Row ${row}, Seat ${seat}`;

        fetchedVerifiedTicketProofs.push({
          proof: JSON.stringify(request.proof),
          metadata: eventParent,
          eventParent,
          eventChild,
          seating,
          date: eventDate,
        } as ExtensionNotaryProofRow);
      });

      setLoadedProxyProofs(fetchedVerifiedTicketProofs);
    } else {
      setLoadedProxyProofs([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifiedTicketProofs, sellerRawTicketIds]);

  useEffect(() => {
    switch (proofSelectionStatus) {
      case VerifyTicketTransactionStatus.VALID:
        const selectedTicketCount = selectedIndices.length;
        const ticketPlural = selectedTicketCount > 1 ? 'tickets' : 'ticket';

        setCtaButtonTitle(`Register ${selectedTicketCount} ${ticketPlural}`);
        break;

      case VerifyTicketTransactionStatus.TRANSACTION_SIGNING:
        setCtaButtonTitle('Signing Transaction');
        break;

      case VerifyTicketTransactionStatus.TRANSACTION_MINING:
        setCtaButtonTitle('Mining Transaction');
        break;

      case VerifyTicketTransactionStatus.TRANSACTION_SUCCEEDED:
        setCtaButtonTitle('Go Back');
        break;

      default:
      case VerifyTicketTransactionStatus.DEFAULT:
        setCtaButtonTitle(`Select tickets to register`);
        break;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proofSelectionStatus, selectedIndices]);

  const {
    tableRef,
    isScrolling
  } = useTableScroll();

  /*
   * Component
   */

  return (
    <Container>
      {isNotSupportedBrowser ? (
        <UnsupportedBrowserContainer>
          <IconAndCopyContainer>
            <StyledAlertTriangle />

            <ThemedText.DeprecatedBody textAlign="center">
              <div>
                { commonStrings.get('BROWSER_NOT_SUPPORTED_INSTRUCTIONS') }
                <Link
                  href={EXTENSION_DOCS_URL}
                  target="_blank"
                >
                  Learn More ↗
                </Link>
              </div>
            </ThemedText.DeprecatedBody>
          </IconAndCopyContainer>

          <Button
            onClick={handleJoinTelegramClicked}
            width={216}
          >
            Join our Telegram
          </Button>
        </UnsupportedBrowserContainer>
      ) : (
        !isSidebarInstalled ? (
          <InstallExtensionContainer>
            <IconAndCopyContainer>
              <SidebarIcon strokeWidth={1} style={{ marginTop: '2em' }} />

              <ThemedText.DeprecatedBody textAlign="center">
                <div>
                  { commonStrings.get('EXTENSION_DOWNLOAD_INSTRUCTIONS') }
                  <Link
                    href={EXTENSION_DOCS_URL}
                    target="_blank"
                  >
                    Learn More ↗
                  </Link>
                </div>
              </ThemedText.DeprecatedBody>
            </IconAndCopyContainer>

            <Button
              onClick={handleInstallExtensionClicked}
              height={48}
              width={216}
              leftAccessorySvg={browserSvg()}
              loading={isInstallExtensionClicked}
              disabled={isInstallExtensionClicked}
            >
              { addToBrowserCopy() }
            </Button>

            { isInstallExtensionClicked && (
              <ThemedText.LabelSmall textAlign="left">
                Waiting for installation...
              </ThemedText.LabelSmall>
            )}
          </InstallExtensionContainer>
        ) : (
          <ExtensionDetectedContainer>
            <NotaryTableTitleContainer>
              Ticket Proofs ({loadedProxyProofs.length})
            </NotaryTableTitleContainer>

            <TitleAndTableContainer>
              {loadedProxyProofs.length === 0 ? (
                <EmptyNotarizationsContainer>
                  <StyledTag />

                  <EmptyNotarizationErrorLabel>
                    { commonStrings.get('NO_VERIFIED_TICKET_PROOFS_ERROR') }
                  </EmptyNotarizationErrorLabel>
                </EmptyNotarizationsContainer>
              ) : (
                <TableContainer>
                  <TableHeaderRow>
                    <ColumnHeader>#</ColumnHeader>

                    <ColumnHeader>Event</ColumnHeader>

                    <ColumnHeader>Selected</ColumnHeader>
                  </TableHeaderRow>

                  <Table ref={tableRef}>
                    {loadedProxyProofs.map((proof, index) => (
                      <ProofRowStyled key={index}>
                        <ProofRow
                          key={index}
                          eventTimeText={proof.date}
                          eventParentName={proof.eventParent}
                          eventChildName={proof.eventChild}
                          seatDetailsText={proof.seating}
                          isSelected={selectedIndices.includes(index)}
                          onRowClick={() => handleRowClick(index)}
                          rowIndex={index + 1}
                          isScrolling={isScrolling}
                        />
                      </ProofRowStyled>
                    ))}
                  </Table>
                </TableContainer>
              )}
            </TitleAndTableContainer>

            {shouldShowOpenSidebarButton() ? (
              <ButtonContainer>
                <Button
                  onClick={openSidebarVerifyTickets}
                >
                  {'Open Sidebar'}
                </Button>
              </ButtonContainer>
            ) : (
              <ButtonContainer>
                <Button
                  disabled={ctaDisabled()}
                  onClick={handleSubmitTransactionPressed}
                >
                  {ctaButtonTitle}
                </Button>
              </ButtonContainer>
            )}
          </ExtensionDetectedContainer>
        )
      )}
    </Container>
  )
};

const Container = styled.div`
  background-color: ${colors.container};
  border-radius: 16px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
`;

const UnsupportedBrowserContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 2rem 2rem 3rem 2rem;
  max-width: 50vh;
  min-height: 25vh;
  line-height: 1.3;
  gap: 1rem;
`;

const StyledAlertTriangle = styled(AlertTriangle)`
  color: #FFF;
  width: 48px;
  height: 48px;
`;

const InstallExtensionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 2rem 2rem 3rem 2rem;
  max-width: 50vh;
  min-height: 25vh;
  line-height: 1.3;
  gap: 1rem;
`;

const IconAndCopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 18px;
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const SidebarIcon = styled(Sidebar)`
  ${IconStyle}
  transform: rotate(180deg);
`;

const ExtensionDetectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;

const NotaryTableTitleContainer = styled.div`
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
`;

const EmptyNotarizationsContainer = styled.div`
  text-align: center;
  padding: 1.9rem 2rem;
`;

const EmptyNotarizationErrorLabel = styled(ThemedText.SubHeaderSmall)`
  padding-top: 1rem;
  line-height: 1.3;
`;

const TitleAndTableContainer = styled.div`
  border: 1px solid ${colors.defaultBorderColor};
  border-radius: 8px;
  overflow: hidden;
  background-color: #FAF9F6;
`;

const TableHeaderRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  padding: 1rem 0rem 0.75rem 1.5rem;
  text-align: left;
  color: ${colors.darkText};
  border-bottom: 1px solid ${colors.defaultBorderColor};
`;

const ColumnHeader = styled.div`
  text-align: left;
  font-size: 14px;
  
  @media (max-width: 600px) {
    font-size: 13px;
  };
`;

const TableContainer = styled.div`
  width: 100%;
  color: #616161;
`;

const Table = styled.div`
  font-size: 16px;
  color: #616161;
  max-height: 260px;
  overflow-y: auto;

  & > * {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${colors.defaultBorderColor};
    }
  }

  & > *:last-child::after {
    display: none;
  }
`;

const ProofRowStyled = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

const ButtonContainer = styled.div`
  display: grid;
`;

const StyledTag = styled(Tag)`
  color: ${colors.black};
  width: 28px;
  height: 28px;
`;

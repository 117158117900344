import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useInitiateRefundTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { swapTicketExchangeAddress, swapTicketExchangeAbi } = useSmartContracts();

  /*
   * State
   */
  const [orderIdInput, setOrderIdInput] = useState<bigint | null>(null); 

  const [shouldConfigureInitiateRefundWrite, setShouldConfigureInitiateRefundWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  //function initiateRefund(uint256 _orderId)

  const { config: writeInitiateRefundConfig } = usePrepareContractWrite({
    address: swapTicketExchangeAddress,
    abi: swapTicketExchangeAbi,
    functionName: 'initiateRefund',
    args: [
      orderIdInput,
    ],
    onError(error: any) {
      console.log('writeInitiateRefundConfig failed: ', error.message);

      setShouldConfigureInitiateRefundWrite(false);

      onError?.(error);
    },
    enabled: shouldConfigureInitiateRefundWrite,
  });

  const {
    data: submitInitiateRefundResult,
    status: signInitiateRefundTransactionStatus,
    writeAsync: writeInitiateRefundAsync,
  } = useContractWrite(writeInitiateRefundConfig);

  const {
    status: mineInitiateRefundTransactionStatus,
  } = useWaitForTransaction({
    hash: submitInitiateRefundResult ? submitInitiateRefundResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeInitiateRefundAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeInitiateRefundAsync,
    orderIdInput,
    setOrderIdInput,
    setShouldConfigureInitiateRefundWrite,
    shouldConfigureInitiateRefundWrite,
    signInitiateRefundTransactionStatus,
    mineInitiateRefundTransactionStatus,
    transactionHash: submitInitiateRefundResult?.hash,
  };
};

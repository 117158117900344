import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { AlertTriangle, Sidebar, Slash, CheckCircle } from 'react-feather';
import Link from '@mui/material/Link';
import { browserName } from 'react-device-detect';

import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { Button } from '@components/common/Button'
import { OrderSettlementDetails } from '@components/Listings/Seller/OrderTable';
import { ExtensionNotaryProofRequest } from '@hooks/useBrowserExtension';
import { FinalizeSwapTransactionStatus, TicketStub } from '@helpers/types';
import { formatEventDateTime } from '@helpers/dateFormat';
import { formatTicketSeatsSummary } from '@helpers/ticketFormat';
import { EXTENSION_DOCS_URL } from '@helpers/docUrls';
import { commonStrings } from '@helpers/strings';
import useExtensionNotarizations from '@hooks/contexts/useExtensionProxyProofs';

import chromeSvg from '@assets/images/browsers/chrome.svg';
import braveSvg from '@assets/images/browsers/brave.svg';


const NOTARY_PROOF_FETCH_INTERVAL = 5000;
const CHROME_EXTENSION_URL = 'https://chromewebstore.google.com/detail/zkp2p-extension/ijpgccednehjpeclfcllnjjcmiohdjih';

type ExtensionNotaryProofRow = {
  proof: string;
  recipientEmail: string;
  eventName: string;
  seating: string;
  date: string;
};

interface ProofTableProps {
  setProofToSubmit: (proof: string) => void;
  handleSubmitTransactionPressed: () => void;
  proofSelectionStatus: string;
  recipientEmail: string;
  ticketSeatsFromOrder: OrderSettlementDetails;
};

export const ProofTable: React.FC<ProofTableProps> = ({
  setProofToSubmit,
  handleSubmitTransactionPressed,
  proofSelectionStatus,
  recipientEmail,
  ticketSeatsFromOrder
}) => {
  /*
   * Context
   */

  const {
    openSidebarInitializeTransfer,
    refetchExtensionVersion,
    refetchInitializeTransferProofs,
    isSidebarInstalled,
    initializeTransferProofs
  } = useExtensionNotarizations();

  /*
   * State
   */

  const [isNotSupportedBrowser, setIsNotSupportedBrowser] = useState<boolean>(false);

  const [matchingProofFromExtension, setMatchingProofFromExtension] = useState<ExtensionNotaryProofRow | null>(null);

  const [ctaButtonTitle, setCtaButtonTitle] = useState<string>("");
  
  const [isInstallExtensionClicked, setIsInstallExtensionClicked] = useState<boolean>(false);

  /*
   * Handlers
   */

  const handleJoinTelegramClicked = () => {
    window.open('https://t.me/+XDj9FNnW-xs5ODNl', '_blank');
  };

  const handleInstallExtensionClicked = () => {
    window.open(CHROME_EXTENSION_URL, '_blank');
    
    setIsInstallExtensionClicked(true)
  };

  /*
   * Helpers
   */

  const browserSvg = () => {
    switch (browserName) {
      case 'Brave':
        return braveSvg;

      case 'Chrome':
      default:
        return chromeSvg;
    }
  };

  const addToBrowserCopy = () => {
    switch (browserName) {
      case 'Brave':
        return 'Add to Brave';

      case 'Chrome':
        return 'Add to Chrome';

      default:
        return 'Add to browser';
    }
  };

  const ctaDisabled = (): boolean => {
    switch (proofSelectionStatus) {
      case FinalizeSwapTransactionStatus.DEFAULT:
      case FinalizeSwapTransactionStatus.TRANSACTION_SIGNING:
      case FinalizeSwapTransactionStatus.TRANSACTION_MINING:
        return true;

      case FinalizeSwapTransactionStatus.VALID:
      default:
        return false;
    }
  }

  /*
   * Hooks
   */

  useEffect(() => {
    if (matchingProofFromExtension) {
      setProofToSubmit(matchingProofFromExtension.proof);
    } else {
      setProofToSubmit('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchingProofFromExtension]);

  useEffect(() => {
    const supportedBrowsers = ['Chrome', 'Brave'];
    setIsNotSupportedBrowser(supportedBrowsers.indexOf(browserName) === -1);

    // Moot to run this on an interval because the content script needs to be injected
    refetchExtensionVersion();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSidebarInstalled) {
      return;
    }
  
    let intervalId: NodeJS.Timeout | null = null;
  
    const setupInterval = (callback: () => void) => {
      callback();
  
      if (intervalId) {
        clearInterval(intervalId);
      };
      
      intervalId = setInterval(callback, NOTARY_PROOF_FETCH_INTERVAL);
    };
  
    setupInterval(refetchInitializeTransferProofs);
  
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarInstalled]);
  
  useEffect(() => {
    if (!isInstallExtensionClicked) {
      return;
    }
  
    let intervalId: NodeJS.Timeout | null = null;
  
    const setupInterval = (callback: () => void) => {
      callback();
  
      if (intervalId) {
        clearInterval(intervalId);
      };
      
      intervalId = setInterval(callback, NOTARY_PROOF_FETCH_INTERVAL);
    };
  
    if (!isSidebarInstalled) {
      setupInterval(refetchExtensionVersion);
    }
  
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInstallExtensionClicked, isSidebarInstalled]);

  useEffect(() => {
    if (initializeTransferProofs && initializeTransferProofs.length > 0) {

      let matchingTransferProof: ExtensionNotaryProofRow | null = null;
      initializeTransferProofs.forEach((request: ExtensionNotaryProofRequest) => {
        // Extract recipient email from transfer proof
        const transferRecipientEmail = request.metadata[3][0];
        const proofTransferRecipientMatchesOrderRecipient = transferRecipientEmail === recipientEmail;

        // Extract transferred tickets seats from transfer proof
        const transferredTicketIds = request.metadata[2][0];

        // Format all tickets in the group into a map with the ticket id as the key
        const allTicketsDetails = request.metadata[1][0];
        const ticketDetailsByTicketMap = new Map();
        allTicketsDetails.forEach((ticket: any) => {
          ticketDetailsByTicketMap.set(ticket.id, ticket);
        });

        // Fetch the transferred tickets from the map
        const transferredTickets = transferredTicketIds.map((ticketId: string) => ticketDetailsByTicketMap.get(ticketId));
        const transferredTicketSeats = transferredTickets.map((ticket: any) => ticket.location.seat);
        const proofTicketSeatsTransferredMatchesOrderSeats = JSON.stringify(transferredTicketSeats.sort()) === JSON.stringify(ticketSeatsFromOrder.seats.sort());

        // If the transfer proof recipient and tickets seats match the order details, then use transfer proof
        if (proofTransferRecipientMatchesOrderRecipient && proofTicketSeatsTransferredMatchesOrderSeats) {
          const eventName = request.metadata[1][0].name;

          const eventDateString = new Date(request.metadata[4]);
          const eventTimestampUnix = BigInt(Math.floor(eventDateString.getTime() / 1000));
          const eventDate = formatEventDateTime(eventTimestampUnix);

          const tempTicketStructs: TicketStub[] = transferredTickets.map((ticket: any) => {
            return {
              section: ticket.location.section,
              row: ticket.location.row,
              seat: ticket.location.seat,
            } as TicketStub;
          });
          const seating = formatTicketSeatsSummary(tempTicketStructs);

          matchingTransferProof = {
            proof: JSON.stringify(request.proof),
            recipientEmail: transferRecipientEmail,
            eventName,
            seating,
            date: eventDate,
          } as ExtensionNotaryProofRow;
        };
      });

      setMatchingProofFromExtension(matchingTransferProof);
    } else {
      setMatchingProofFromExtension(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializeTransferProofs, recipientEmail, ticketSeatsFromOrder]);

  useEffect(() => {
    switch (proofSelectionStatus) {
      case FinalizeSwapTransactionStatus.VALID:
        setCtaButtonTitle(`Complete order`);
        break;

      case FinalizeSwapTransactionStatus.TRANSACTION_SIGNING:
        setCtaButtonTitle('Signing Transaction');
        break;

      case FinalizeSwapTransactionStatus.TRANSACTION_MINING:
        setCtaButtonTitle('Mining Transaction');
        break;

      case FinalizeSwapTransactionStatus.TRANSACTION_SUCCEEDED:
        setCtaButtonTitle('Go Back');
        break;

      default:
      case FinalizeSwapTransactionStatus.DEFAULT:
        setCtaButtonTitle(`Select completed transfer`);
        break;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proofSelectionStatus]);

  /*
   * Handlers
   */

  const handleOpenSidebarTransfer = () => {
    openSidebarInitializeTransfer();
  };

  /*
   * Component
   */

  return (
    <Container>
      {isNotSupportedBrowser ? (
        <UnsupportedBrowserContainer>
          <IconAndCopyContainer>
            <StyledAlertTriangle />

            <ThemedText.DeprecatedBody textAlign="center">
              <div>
                { commonStrings.get('BROWSER_NOT_SUPPORTED_INSTRUCTIONS') }
                <Link
                  href={EXTENSION_DOCS_URL}
                  target="_blank"
                >
                  Learn More ↗
                </Link>
              </div>
            </ThemedText.DeprecatedBody>
          </IconAndCopyContainer>

          <Button
            onClick={handleJoinTelegramClicked}
            width={216}
          >
            Join our Telegram
          </Button>
        </UnsupportedBrowserContainer>
      ) : (
        !isSidebarInstalled ? (
          <InstallExtensionContainer>
            <IconAndCopyContainer>
              <SidebarIcon strokeWidth={1} style={{ marginTop: '2em' }} />

              <ThemedText.DeprecatedBody textAlign="center">
                <div>
                  { commonStrings.get('EXTENSION_DOWNLOAD_INSTRUCTIONS') }
                  <Link
                    href={EXTENSION_DOCS_URL}
                    target="_blank"
                  >
                    Learn More ↗
                  </Link>
                </div>
              </ThemedText.DeprecatedBody>
            </IconAndCopyContainer>

            <Button
              onClick={handleInstallExtensionClicked}
              height={48}
              width={216}
              leftAccessorySvg={browserSvg()}
              loading={isInstallExtensionClicked}
              disabled={isInstallExtensionClicked}
            >
              { addToBrowserCopy() }
            </Button>

            { isInstallExtensionClicked && (
              <ThemedText.LabelSmall textAlign="left">
                Waiting for installation...
              </ThemedText.LabelSmall>
            )}
          </InstallExtensionContainer>
        ) : (
          <ExtensionDetectedContainer>
            <NotaryTableTitleContainer>
              Transfer Proof
            </NotaryTableTitleContainer>

            <TitleAndTableContainer>
              {!matchingProofFromExtension ? (
                <EmptyNotarizationsContainer>
                  <StyledSlash />

                  <EmptyNotarizationErrorLabel>
                    No proof of initiated transfer found.
                    Please follow instructions in the browser sidebar to generate proof of successful initiated
                    transfer to <strong>{recipientEmail}</strong>.
                  </EmptyNotarizationErrorLabel>
                </EmptyNotarizationsContainer>
              ) : (
                <IconAndMessageContainer>
                  <TagDetectionIconAndCopyContainer>
                  <StyledCheckCircle />

                    <ThemedText.SubHeaderSmall textAlign="center" lineHeight={1.3}>
                      Proof found for transfer of <strong>{matchingProofFromExtension.eventName}</strong> tickets
                      to <strong>{matchingProofFromExtension.recipientEmail}</strong> matching
                      seats <strong>{matchingProofFromExtension.seating}</strong>.
                      Submit to complete order and receive payment.
                    </ThemedText.SubHeaderSmall>
                  </TagDetectionIconAndCopyContainer>
                </IconAndMessageContainer>
              )}
            </TitleAndTableContainer>

            {!matchingProofFromExtension ? (
              <ButtonContainer>
                <Button
                  onClick={handleOpenSidebarTransfer}
                >
                  {'Open Sidebar'}
                </Button>
              </ButtonContainer>
            ) : (
              <ButtonContainer>
                <Button
                  disabled={ctaDisabled()}
                  onClick={handleSubmitTransactionPressed}
                >
                  {ctaButtonTitle}
                </Button>
              </ButtonContainer>
            )}
          </ExtensionDetectedContainer>
        )
      )}
    </Container>
  )
};

const Container = styled.div`
  background-color: ${colors.container};
  border-radius: 16px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
`;

const UnsupportedBrowserContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 36px;
  max-width: 50vh;
  min-height: 25vh;
  line-height: 1.3;
  gap: 36px;
`;

const StyledAlertTriangle = styled(AlertTriangle)`
  color: #FFF;
  width: 48px;
  height: 48px;
`;

const InstallExtensionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 36px;
  max-width: 50vh;
  min-height: 25vh;
  line-height: 1.3;
  gap: 36px;
`;

const IconAndCopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 18px;
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const SidebarIcon = styled(Sidebar)`
  ${IconStyle}
  transform: rotate(180deg);
`;

const ExtensionDetectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;

const NotaryTableTitleContainer = styled.div`
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
`;

const EmptyNotarizationsContainer = styled.div`
  text-align: center;
  padding: 1.9rem 2rem;
`;

const EmptyNotarizationErrorLabel = styled(ThemedText.SubHeaderSmall)`
  padding-top: 1rem;
  line-height: 1.3;
`;

const TitleAndTableContainer = styled.div`
  border: 1px solid ${colors.defaultBorderColor};
  border-radius: 8px;
  background-color: #FAF9F6;
`;

const IconAndMessageContainer = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid ${colors.container};
  border-radius: 8px;
`;

const TagDetectionIconAndCopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.9rem 4rem;
  gap: 1rem;

  border-radius: 8px;
`;

const ButtonContainer = styled.div`
  display: grid;
`;

const StyledSlash = styled(Slash)`
  color: ${colors.proofMissingRed};
  width: 28px;
  height: 28px;
`;

const StyledCheckCircle = styled(CheckCircle)`
  color: ${colors.proofFoundGreen};
  width: 28px;
  height: 28px;
`;

import { useEffect, useState, ReactNode } from 'react';
import { useContractRead } from 'wagmi';

import { Event } from '@helpers/types';
import { esl, CALLER_ACCOUNT } from '@helpers/constants';
import useSmartContracts from '@hooks/contexts/useSmartContracts';

import EventsContext from './EventsContext';


interface ProvidersProps {
  children: ReactNode;
}

const Events = ({ children }: ProvidersProps) => {
  /*
   * Contexts
   */

  const { eventRegistryAddress, eventRegistryAbi } = useSmartContracts();

  /*
   * State
   */

  const [eventIds, setEventIds] = useState<string[] | null>(null);
  const [eventsByEventId, setEventsByEventId] = useState<{ [eventId: string]: Event } | null>(null);

  const [shouldFetchEventIds, setShouldFetchEventIds] = useState<boolean>(false);
  const [shouldFetchEventsInfo, setShouldFetchEventsInfo] = useState<boolean>(false);

  /*
   * Contract Reads
   */

  // function getEventIds() external view returns(bytes32[] memory)
  const {
    data: eventIdsRaw,
    refetch: refetchEventIds,
  } = useContractRead({
    address: eventRegistryAddress,
    abi: eventRegistryAbi,
    functionName: 'getEventIds',
    account: CALLER_ACCOUNT,
    enabled: shouldFetchEventIds
  })

  // function getEventsInfo(bytes32[] calldata _eventIds)
  const {
    data: eventsInfoRaw,
    refetch: refetchEventsInfo,
  } = useContractRead({
    address: eventRegistryAddress,
    abi: eventRegistryAbi,
    functionName: 'getEventsInfo',
    args: [
      eventIds
    ],
    account: CALLER_ACCOUNT,
    enabled: shouldFetchEventsInfo
  })

  /*
   * Hooks
   */

  useEffect(() => {
    esl && console.log('shouldFetchEventIds_1');
    esl && console.log('checking eventRegistryAddress: ', eventRegistryAddress);

    if (eventRegistryAddress) {
      esl && console.log('shouldFetchEventIds_2');

      setShouldFetchEventIds(true);
    } else {
      esl && console.log('shouldFetchEventIds_3');

      setShouldFetchEventIds(false);

      setEventIds(null);
    }
  }, [eventRegistryAddress]);

  useEffect(() => {
    esl && console.log('eventIdsRaw_1');
    esl && console.log('checking eventIdsRaw: ', eventIdsRaw);

    if (eventIdsRaw && eventIdsRaw.length > 0) {
      esl && console.log('eventIdsRaw_2');

      setEventIds(eventIdsRaw);

      setShouldFetchEventsInfo(true);
    } else {
      esl && console.log('eventIdsRaw_3');

      setEventIds(null);

      setShouldFetchEventsInfo(false);
    }
  }, [eventIdsRaw]);

  useEffect(() => {
    esl && console.log('eventsInfoRaw_1');
    esl && console.log('checking eventsInfoRaw: ', eventsInfoRaw);

    if (eventsInfoRaw && eventsInfoRaw.length > 0) {
      esl && console.log('eventsInfoRaw_2');

      const eventsInfoArrayRaw = eventsInfoRaw as any[];
      const sanitizedEventsInfo: Event[] = [];

      for (let i = eventsInfoArrayRaw.length - 1; i >= 0; i--) {
        const EventInfoWithIdData = eventsInfoArrayRaw[i];

        const eventInfoData = EventInfoWithIdData.eventInfo;
        const eventInfo: Event = {
          id: EventInfoWithIdData.eventId,
          name: eventInfoData.eventName,
          venue: eventInfoData.venue,
          startTime: eventInfoData.startTime,
          eventUrl: eventInfoData.eventUrl,
        };

        sanitizedEventsInfo.push(eventInfo);
      };

      const eventsMap: { [eventId: string]: Event } = {};
      sanitizedEventsInfo.forEach(event => {
        eventsMap[event.id] = event;
      });
          
      // TODO: Prune Ids for events where startTime has expired
      setEventsByEventId(eventsMap);
    } else {
      esl && console.log('eventsInfoRaw_3');

      setEventsByEventId(null);
    }
  }, [eventsInfoRaw]);

  return (
    <EventsContext.Provider
      value={{
        eventsByEventId,

        refetchEventIds,
        shouldFetchEventIds,
        
        refetchEvents: refetchEventsInfo,
        shouldFetchEvents: shouldFetchEventsInfo,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};

export default Events;

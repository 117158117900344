import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useFinalizeRefundTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { swapTicketExchangeAddress, swapTicketExchangeAbi } = useSmartContracts();

  /*
   * State
   */
  
  const [orderIdInput, setOrderIdInput] = useState<bigint | null>(null); 

  const [shouldConfigureFinalizeRefundWrite, setShouldConfigureFinalizeRefundWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  //function finalizeRefund(uint256 _orderId)

  const { config: writeFinalizeRefundConfig } = usePrepareContractWrite({
    address: swapTicketExchangeAddress,
    abi: swapTicketExchangeAbi,
    functionName: 'finalizeRefund',
    args: [
      orderIdInput,
    ],
    onError(error: any) {
      console.log('writeFinalizeRefundConfig failed: ', error.message);

      setShouldConfigureFinalizeRefundWrite(false);

      onError?.(error);
    },
    enabled: shouldConfigureFinalizeRefundWrite,
  });

  const {
    data: submitFinalizeRefundResult,
    status: signFinalizeRefundTransactionStatus,
    writeAsync: writeFinalizeRefundAsync,
  } = useContractWrite(writeFinalizeRefundConfig);

  const {
    status: mineFinalizeRefundTransactionStatus,
  } = useWaitForTransaction({
    hash: submitFinalizeRefundResult ? submitFinalizeRefundResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeFinalizeRefundAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeFinalizeRefundAsync,
    orderIdInput,
    setOrderIdInput,
    setShouldConfigureFinalizeRefundWrite,
    shouldConfigureFinalizeRefundWrite,
    signFinalizeRefundTransactionStatus,
    mineFinalizeRefundTransactionStatus,
    transactionHash: submitFinalizeRefundResult?.hash,
  };
};

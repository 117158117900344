import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import useMediaQuery from '@hooks/useMediaQuery';
import { AccessoryButton } from '@components/common/AccessoryButton';
import { TransactionIconButton } from '@components/common/TransactionIconButton';
import { colors } from '@theme/colors';
import useCancelOrderTransaction from '@hooks/transactions/useSellerCancelOrder';
import useSellers from '@hooks/contexts/useSellers';


export enum SellerOrderStatus {
  WAITING_TRANSFER = 'waiting_transfer',
  WAITING_TO_ACCEPT = 'waiting_to_accept',
  PENDING_REFUND = 'pending_refund',
};

interface OrderRowProps {
  orderId: bigint;
  status: SellerOrderStatus;
  sectionText: string;
  rowText: string;
  seatText: string;
  orderPriceText: string;
  recipientEmail: string;
  rowIndex: number;
  handleCompleteOrderPressed: () => void;
  handleTransferOrderPressed: () => void;
}

export const OrderRow: React.FC<OrderRowProps> = ({
  orderId,
  status,
  sectionText,
  rowText,
  seatText,
  orderPriceText,
  recipientEmail,
  rowIndex,
  handleCompleteOrderPressed,
  handleTransferOrderPressed,
}: OrderRowProps) => {
  OrderRow.displayName = "OrderRow";

  const isMobile = useMediaQuery() === 'mobile';

  /*
   * Contexts
   */

  const { refetchSellerOrders } = useSellers();

  /*
   * Hooks
   */

  const onSuccessCallback = useCallback((data: any) => {
    console.log('sellerCancelOrder Succeeded: ', data);

    refetchSellerOrders?.();
  }, [refetchSellerOrders]);

  const {
    writeSellerCancelOrderAsync,
    shouldConfigureSellerCancelOrderWrite,
    setOrderIdInput,
    setShouldConfigureSellerCancelOrderWrite,
    signSellerCancelOrderTransactionStatus,
    mineSellerCancelOrderTransactionStatus,
    // transactionHash
  } = useCancelOrderTransaction(onSuccessCallback);

  useEffect(() => {
    const submitSellerCancelOrder = async () => {
      if (shouldConfigureSellerCancelOrderWrite && writeSellerCancelOrderAsync) {
        try {
          await writeSellerCancelOrderAsync?.();
        } catch (error) {
          console.log('writeSellerCancelOrderAsync failed: ', error);

          setShouldConfigureSellerCancelOrderWrite(false);

          setOrderIdInput(null);
        }
      };
    };

    submitSellerCancelOrder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldConfigureSellerCancelOrderWrite, writeSellerCancelOrderAsync]);

  /*
   * Handlers
   */

  const handleCancelOrderRowPressed = () => {
    setOrderIdInput(orderId);

    setShouldConfigureSellerCancelOrderWrite(true);
  };

  /*
   * Helpers
   */

  const isCancelListingLoading = (): boolean => {
    const isSigningTransctionMining = signSellerCancelOrderTransactionStatus === 'loading';
    const isMiningTransaction = mineSellerCancelOrderTransactionStatus === 'loading';

    return isSigningTransctionMining || isMiningTransaction;
  };

  /*
   * Component
   */

  function renderStatus() {
    switch(status) {
      case SellerOrderStatus.WAITING_TRANSFER:
        return 'Awaiting Transfer';
      case SellerOrderStatus.WAITING_TO_ACCEPT:
        return 'Pending Accept';
      case SellerOrderStatus.PENDING_REFUND:
        return 'Refund Pending';
      default:
        return null;
    }
  }

  function renderActionButton() { 
    if (status === SellerOrderStatus.WAITING_TRANSFER) {
      return (
        <AccessoryButton
          onClick={handleTransferOrderPressed}
          height={36}
          title={'Transfer'}
        />
      );
    } else if (status === SellerOrderStatus.WAITING_TO_ACCEPT || status === SellerOrderStatus.PENDING_REFUND) {
      return (
        <AccessoryButton
          onClick={handleCompleteOrderPressed}
          height={36}
          title={'Complete'}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <Container>
      <OrderDetailsContainer isMobile={isMobile}>
        <RowNumberContainer>
          <SummaryLabel>
            <SummaryLabelTitle>{rowIndex + 1}</SummaryLabelTitle>
          </SummaryLabel>
        </RowNumberContainer>

        <TicketDetailsContainer>
          <StatusLabel $status={status}>
            {renderStatus()}
          </StatusLabel>
        </TicketDetailsContainer>

        <TicketDetailsContainer>
          <SummaryLabelValue>{sectionText}</SummaryLabelValue>
          <SummaryLabelValue>{rowText}, {seatText}</SummaryLabelValue>
        </TicketDetailsContainer>

        <RecipientDetailsContainer>
          <SummaryLabel>
            <TicketPriceLabel>
              {recipientEmail}
            </TicketPriceLabel>
          </SummaryLabel>
        </RecipientDetailsContainer>

        <PriceDetailsContainer>
          <SummaryLabel>
            <TicketPriceLabel>
              {orderPriceText}
            </TicketPriceLabel>
          </SummaryLabel>
        </PriceDetailsContainer>

        <ActionsContainer>
          {renderActionButton()}

          <TransactionIconButton
            icon="trash"
            text='Delete Order'
            onClick={() => handleCancelOrderRowPressed()}
            loading={isCancelListingLoading()}
            disabled={false}
          />
        </ActionsContainer>
      </OrderDetailsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const OrderDetailsContainer = styled.div<{isMobile?: boolean}>`
  width: 100%;
  display: grid;
  grid-template-columns: 0.75fr 2.5fr 3fr 3fr 2fr 2fr;
  grid-gap: 4px;
  align-items: center;
  padding: 1rem 1.5rem 0.75rem 1.5rem;
  line-height: 24px;
`;

const TicketDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PriceDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RowNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RecipientDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  width: 100%;
`;

const SummaryLabel = styled.label`
  font-size: 15px;
  color: #FFFFFF;
`;

const StatusLabel = styled.label<{ $status: SellerOrderStatus }>`
  font-size: 15px;
  color: ${props => props.$status === SellerOrderStatus.WAITING_TRANSFER ?
    colors.orderStatusWaitingTransfer : 
    SellerOrderStatus.WAITING_TO_ACCEPT ===  props.$status ? colors.orderStatusWaitingToAccept : colors.orderStatusPendingRefund
  };
`;

const SummaryLabelTitle = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
`;

const SummaryLabelValue = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
`;

const TicketPriceLabel = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
`;

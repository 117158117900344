import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { Check, CreditCard, DollarSign, List, Package, Rewind, Send, ShoppingBag } from 'react-feather';

import { SVGIconThemed } from '@components/common/SVGIconThemed';
import { Z_INDEX } from '@theme/zIndex';
import useMediaQuery from '@hooks/useMediaQuery';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { CHROME_EXTENSION_URL } from '@helpers/constants';



export const HowItWorks: React.FC = () => {
  /*
   * Contexts
   */
  
  const currentDeviceSize = useMediaQuery();

  const [sectionRefs, setSectionRefs] = useState<{ [key: string]: React.RefObject<HTMLDivElement> }>({});

  useEffect(() => {
    const refs: { [key: string]: React.RefObject<HTMLDivElement> } = {
      'Funding Account': React.createRef<HTMLDivElement>(),
      'Purchasing Tickets': React.createRef<HTMLDivElement>(),
      'Accepting Tickets': React.createRef<HTMLDivElement>(),
      'Refunding Purchases': React.createRef<HTMLDivElement>(),
      'Verifying Tickets': React.createRef<HTMLDivElement>(),
      'Creating Listing': React.createRef<HTMLDivElement>(),
      'Fulfilling Purchase': React.createRef<HTMLDivElement>(),
      'Claim Payment': React.createRef<HTMLDivElement>(),
      'FAQ': React.createRef<HTMLDivElement>(),
    };
    setSectionRefs(refs);
  }, []);

  const scrollToSection = (sectionTitle: string) => {
    sectionRefs[sectionTitle]?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const [openFAQ, setOpenFAQ] = useState<string | null>(null);

  const toggleFAQ = (question: string) => {
    setOpenFAQ(openFAQ === question ? null : question);
  };

  /*
   * Handlers
   */

  const jumpToMedia = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <PageWrapper $isMobile={currentDeviceSize === 'tablet' || currentDeviceSize === 'mobile'}>
      <Container>
        <HeroContainer style={{ padding: currentDeviceSize === 'mobile' ? '0 1.6rem' : '0', width: '100%' }}>
          <HeroTextContainer>
            <ThemedText.Hero>
              <div style={{ display: 'flex', justifyContent: 'center', fontSize: currentDeviceSize === 'mobile' ? 44 : 60, fontWeight: 600 }}>
                <span>How ZKP2P <i>Tickets</i> Works</span>
              </div>
            </ThemedText.Hero>
          </HeroTextContainer>

          <SubHeaderContainer>
            <ThemedText.SubHeaderLarge style={{ textAlign: 'center', lineHeight: '1.3', fontSize: currentDeviceSize === 'mobile' ? 20 : 24 }}>
              Take ownership of your ticket experience and enjoy lower fees, guaranteed authenticity, and fast settlement.
            </ThemedText.SubHeaderLarge>
          </SubHeaderContainer>

          <BoxesContainer>
            <Box>
              <BoxHeader style={{ textAlign: 'center' }}>For Buyers</BoxHeader>
              <UnorderedList>
                <ListItem onClick={() => scrollToSection('Funding Account')}><CreditCard />Funding Account</ListItem>
                <ListItem onClick={() => scrollToSection('Purchasing Tickets')}><ShoppingBag />Purchasing Tickets</ListItem>
                <ListItem onClick={() => scrollToSection('Accepting Tickets')}><Package />Accepting Tickets</ListItem>
                <ListItem onClick={() => scrollToSection('Refunding Purchases')}><Rewind />Refunding Purchases</ListItem>
              </UnorderedList>
            </Box>
            <Box>
              <BoxHeader style={{ textAlign: 'center' }}>For Sellers</BoxHeader>
              <UnorderedList>
                <ListItem onClick={() => scrollToSection('Verifying Tickets')}><Check />Verifying Tickets</ListItem>
                <ListItem onClick={() => scrollToSection('Creating Listing')}><List />Creating Listing</ListItem>
                <ListItem onClick={() => scrollToSection('Fulfilling Purchase')}><Send />Fulfilling Purchase</ListItem>
                <ListItem onClick={() => scrollToSection('Claim Payment')}><DollarSign />Claim Payment</ListItem>
              </UnorderedList>
            </Box>
          </BoxesContainer>
        </HeroContainer>

        <LearnMoreContainer
            onClick={() => scrollToSection('FAQ')}
          >
            FAQs
            <Icon icon="arrow-down" />
        </LearnMoreContainer>


        <HowToSectionsContainer>
          <HowToSection ref={sectionRefs['Funding Account']}>
            <DialogBox $isLeft={true}>
              <IconWrapper>
                <CreditCard size={32} />
              </IconWrapper>
              
              <BoxHeader>Funding Account</BoxHeader>

              <HowToText>In order to purchase tickets, you must fund your account. Our ticket exchange uses USDC, a USD-equivalent stablecoin for payments. We provide three ways to fund your account</HowToText>
              <HowToText>1. On-ramp via ZKP2P using your Venmo or Revolut account</HowToText>
              <HowToText>2. Transfer USDC from your Coinbase account</HowToText>
              <HowToText>3. Transfer USDC from your MetaMask wallet</HowToText>
              <HowToText>If you do not currently have USDC on-chain or in your Coinbase account, we've recommended using our on-ramp service to fund your account. Like our ticket exchange, it is the fastest and cheapest way to obtain USDC.</HowToText>

              {/* To Do: Link to on-ramp using integration flow*/} 
              <SectionCTA to="" onClick={() => jumpToMedia("https://www.ramp.zkp2p.xyz")}>Fund Account with Venmo Now</SectionCTA>
            </DialogBox>
            <ImageWrapper $isLeft={false}>
              <SectionImage
                src={`${process.env.PUBLIC_URL}/fund_account.png`}
                alt="Funding Account"
              />
            </ImageWrapper>
          </HowToSection>

          <HowToSection ref={sectionRefs['Verifying Tickets']}>
            <DialogBox $isLeft={false}>
              <IconWrapper>
                <Check size={32} />
              </IconWrapper>
              <BoxHeader>Verifying Tickets</BoxHeader>
              <HowToText>We use a new form of cryptography called Web Proofs to verify tickets. Web Proofs allow a notary to verify and extract selected data from encrypted traffic between Ticketmaster's website and its servers. Since this traffic can contain sensitive data we use cryptography to ensure only the data necessary for verifying tickets is exposed to our notary so that your data cannot be leaked.</HowToText>
              <HowToText>In order to create Web Proofs you will need to download our companion browser extension. Our extension only reads data on Ticketmaster's website and does not have any functionality outside of creating Web Proofs.</HowToText>
              <HowToText>Once you have created proofs of your tickets you must submit the proofs to our contracts to enable them for listing. If you sign up using Google we'll cover costs for you, however any other wallet with require holding ETH.</HowToText>
              <SectionCTA to="" onClick={() => jumpToMedia(CHROME_EXTENSION_URL)}>Download Companion Browser Extension Now</SectionCTA>
            </DialogBox>
            <ImageWrapper $isLeft={true}>
              <SectionImage
                src={`${process.env.PUBLIC_URL}/verify_ticket.png`}
                alt="Verifying Tickets"
              />
            </ImageWrapper>
          </HowToSection>

          <HowToSection ref={sectionRefs['Creating Listing']}>
            <ImageWrapper $isLeft={false}>
              <SectionImage
                src={`${process.env.PUBLIC_URL}/create_listing.png`}
                alt="Creating Listing"
              />
            </ImageWrapper>
            <DialogBox $isLeft={true}>
              <IconWrapper>
                <List size={32} />
              </IconWrapper>
              <BoxHeader>Creating Listing</BoxHeader>
              <HowToText>Before creating a listing, you must have a verified ticket in your inventory, see the <SectionEmbeddedLink to="" onClick={() => scrollToSection('Verifying Tickets')}>Verifying Tickets section</SectionEmbeddedLink>. Once you have a verified ticket, you can create a listing on the <SectionEmbeddedLink to="/listings">Listings page</SectionEmbeddedLink> by selecting the tickets you wish the sell and specifying the amount per ticket you want the buyer to pay.</HowToText>
              <HowToText>A note on fees: We only charge a 5% fee upon successful sale of a ticket. We do not add any additional fees for the buyer, this means that the price you specify is the price the buyer will pay. Given that other ticket platforms generally charge a total spread of 25-30%, you could raise your list price by 10% vs those other platforms and the buyer will still save 10-15%!</HowToText>
              <SectionCTA to="/listings">Create A Listing Now</SectionCTA>
            </DialogBox>
          </HowToSection>

          <HowToSection ref={sectionRefs['Purchasing Tickets']}>
            <ImageWrapper $isLeft={true}>
              <SectionImage
                src={`${process.env.PUBLIC_URL}/purchase_ticket.png`}
                alt="Purchasing Tickets"
              />
            </ImageWrapper>
            <DialogBox $isLeft={false}>
              <IconWrapper>
                <ShoppingBag size={32} />
              </IconWrapper>
              <BoxHeader>Purchasing Tickets</BoxHeader>
              <HowToText>To purchase a ticket, you first must have funded your account with USDC, see the <SectionEmbeddedLink to="" onClick={() => scrollToSection('Funding Account')}>Funding Account section</SectionEmbeddedLink>. When you identify a listing you want to buy, you create an Order by selecting the tickets from the listing you would like to purchase and specifying an email to send the tickets to. We make sure only the buyer can see the email receiving the tickets by encrypting it for them.</HowToText>
              <HowToText>When you submit your order, your USDC will be transferred to escrow. This escrow can only be successfully released if the seller provides proof of transfer, see the <SectionEmbeddedLink to="" onClick={() => scrollToSection('Fulfilling Purchase')}>Fulfilling Purchase section</SectionEmbeddedLink> for details, or you release the funds to the seller. The seller has 24 hours to transfer the tickets to your email after which you must accept them, see <SectionEmbeddedLink to="" onClick={() => scrollToSection('Accepting Tickets')}>Accepting Tickets section</SectionEmbeddedLink>.</HowToText>
              <HowToText>If the seller does not transfer the tickets to your email within 24 hours, you can initiate a refund which upon successful completion will return all USDC to your account, see <SectionEmbeddedLink to="" onClick={() => scrollToSection('Refunding Purchases')}>Refunding Purchases section</SectionEmbeddedLink>.</HowToText>
              <SectionCTA to="/">Starting Browsing Events Now</SectionCTA>
            </DialogBox>
          </HowToSection>

          <HowToSection ref={sectionRefs['Fulfilling Purchase']}>
            <DialogBox $isLeft={true}>
              <IconWrapper>
                <Send size={32} />
              </IconWrapper>
              <BoxHeader>Fulfilling Purchase</BoxHeader>
              <HowToText>We recommend fulfilling your purchases within 24 hours. After 24 hours, the buyer has the ability to initiate a refund.</HowToText>
              <HowToText>You can fulfill purchases by going to the <SectionEmbeddedLink to="/listings">My Listings</SectionEmbeddedLink> page and viewing the Orders on your Listings. To fulfill, follow the instructions in the client that will navigate you to Ticketmaster's website. On Ticketmaster's website, you will navigate to the order containing the tickets you are fulfilling and then transfer the tickets to the buyer's email. Once you have transferred the tickets, you can generate a proof of transfer. We recommend generating the proof immediately after transfer so that details of the transfer can be captured in the pending state.</HowToText>
              <HowToText>Once you have captured the proof of transfer, you can submit it on-chain to mark the order as fulfilled. Since, transfers are revocable, we cannot release the funds until the buyer has accepted the transfer from their email address. </HowToText>
            </DialogBox>
            <ImageWrapper $isLeft={false}>
              <SectionImage
                src={`${process.env.PUBLIC_URL}/fulfill_order.png`}
                alt="Fulfilling Purchase"
              />
            </ImageWrapper>
          </HowToSection>

          <HowToSection ref={sectionRefs['Accepting Tickets']}>
            <DialogBox $isLeft={false}>
              <IconWrapper>
                <Package size={32} />
              </IconWrapper>
              <BoxHeader>Accepting Tickets</BoxHeader>
              <HowToText>In order to attend your event, you will need to accept the ticket transfer from your counterparty. It's important you monitor your email for this transfer. Until you accept the transfer, the tickets are not yours, the seller has the ability to revoke the transfer and cancel the order.</HowToText>
              <HowToText>Once you have accepted the transfer, you will be able to see the tickets in your Ticketmaster account. If after 24 hours you don't see the transfer, you have the right to initiate a refund, see the <SectionEmbeddedLink to="" onClick={() => scrollToSection('Refunding Purchases')}>Refunding Purchases section</SectionEmbeddedLink> for details.</HowToText>
            </DialogBox>
            <ImageWrapper $isLeft={true}>
              <SectionImage
                src={`${process.env.PUBLIC_URL}/accept_ticket.png`}
                alt="Accepting Tickets"
              />
            </ImageWrapper>
          </HowToSection>

          <HowToSection ref={sectionRefs['Claim Payment']}>
            <ImageWrapper $isLeft={false}>
              <SectionImage
                src={`${process.env.PUBLIC_URL}/claim_funds.png`}
                alt="Claim Payment"
              />
            </ImageWrapper>
            <DialogBox $isLeft={true}>
              <IconWrapper>
                <DollarSign size={32} />
              </IconWrapper>
              <BoxHeader>Claim Payment</BoxHeader>
              <HowToText>In order to claim payment, the buyer must accept the transfer of the tickets. Once the buyer has accepted the tickets, you can create a proof of acceptance that is used to unlock your funds.</HowToText>
              <HowToText>Similar to the fulfilling purchase process, you can start claiming payment by going to the <SectionEmbeddedLink to="/listings">My Listings</SectionEmbeddedLink> page and viewing the Orders on your Listings. To claim payment, follow the instructions in the client that will navigate you to Ticketmaster's website. On Ticketmaster's website, you will navigate to the order containing the tickets you transferred and follow the prompts in the extension to generate a proof of acceptance. Once the proof is generated, you can submit it on-chain to unlock your payment.</HowToText>
              <HowToText><b>Some notes on dispute resolution:</b> If the buyer does not accept your transfer in a timely manner or initiates a refund with an outstanding (non-accepted) transfer, we recommend that you revoke the transfer and cancel the order. If the user has initiated a refund and accepted the transfer, you can simply provide proof of acceptance to unlock your payment. To learn more about how refunds work, see the <SectionEmbeddedLink to="" onClick={() => scrollToSection('Refunding Purchases')}>Refunding Purchases section</SectionEmbeddedLink>.</HowToText>
            </DialogBox>
          </HowToSection>

          <HowToSection ref={sectionRefs['Refunding Purchases']}>
            <ImageWrapper $isLeft={true}>
              <SectionImage
                src={`${process.env.PUBLIC_URL}/refund_order.png`}
                alt="Refunding Purchases"
              />
            </ImageWrapper>
            <DialogBox $isLeft={false}>
              <IconWrapper>
                <Rewind size={32} />
              </IconWrapper>
              <BoxHeader>Refunding Purchases</BoxHeader>
              <HowToText>The refund process exists in case for whatever reason the seller is unable to deliver their tickets. A refund can be initiated 24 hours after an order is placed on a listing. If the buyer has accepted the transfer from the seller, a refund will not be possible. Additionally, refunds are not possible in the case an event is cancelled.</HowToText>
              <HowToText>In order to start a refund, navigate to the <SectionEmbeddedLink to="/orders">My Orders</SectionEmbeddedLink> page and view your Orders. In the Orders table, you should see the option to start a refund by submitting a transaction to our contracts. Once a refund is initiated, a 24-hour period must elapse to give the seller time to revoke their transfer in case it is still outstanding or submit proof of acceptance for the transfer. After this period has elapsed, the buyer can return to their My Orders page and finalize the refund, which will return USDC to their account.</HowToText>
            </DialogBox>
          </HowToSection>
        </HowToSectionsContainer>

        <FAQContainer ref={sectionRefs['FAQ']}>
          <FAQHeader>Frequently Asked Questions</FAQHeader>
          <FAQItem>
            <FAQQuestion onClick={() => toggleFAQ("Why ZKP2P Tickets?")}>
              <FAQIcon style={{ transform: openFAQ === "Why ZKP2P Tickets?" ? 'rotate(45deg)' : 'none' }}>+</FAQIcon>
              How is ZKP2P Tickets better than other ticket platforms?
            </FAQQuestion>
            <FAQAnswer $isOpen={openFAQ === "Why ZKP2P Tickets?"}>
              ZKP2Ps core innovation is our ability to verify tickets and transfer transactions. No other secondary ticket marketplace is able to get the visibility into the state of a ticket on primary marketplaces like ZKP2P. This has a couple core benefits:
            </FAQAnswer>
            <FAQAnswer $isOpen={openFAQ === "Why ZKP2P Tickets?"}>
              <b>No "On-Spec" Listings: </b>Since other secondary exchanges can't verify ticket ownership they get flooded with "fake" listings that the seller hopes to fulfill later. This leads to buyers either not receiving the tickets they expect or not receiving any tickets at all. Additionally, in order to combat this and fulfill unfilled orders for their users, secondary marketplaces have to invest in large customer support teams leading to higher fees for buyers and sellers.
            </FAQAnswer>
            <FAQAnswer $isOpen={openFAQ === "Why ZKP2P Tickets?"}>
              <b>Cheaper: </b>Because we are able to run such a lean marketplace, we are able to offer the lowest fees in the secondary ticket market (and it's not close). Most secondary markets take 25-30% of the price the buyer pays, we only take 5%. Events have gotten expensive enough without large, inefficient organizations needing to take their cut.
            </FAQAnswer>
            <FAQAnswer $isOpen={openFAQ === "Why ZKP2P Tickets?"}>
              <b>Fast Fulfillment: </b>Other secondary marketplaces don't require ticket transfer until a couple days before the event. Not only is this more lenient for "spec" listers but it also means the buyer must wait to see if they actually receive the tickets they expect. Furthermore, since other marketplaces don't pay out until after the event, there's no incentive for sellers to transfer the tickets. Our marketplace allows sellers to get immediately paid out.
            </FAQAnswer>
          </FAQItem>
          <FAQItem>
            <FAQQuestion onClick={() => toggleFAQ("Event Cancelled?")}>
              <FAQIcon style={{ transform: openFAQ === "Event Cancelled?" ? 'rotate(45deg)' : 'none' }}>+</FAQIcon>
              What happens if an event is cancelled?
            </FAQQuestion>
            <FAQAnswer $isOpen={openFAQ === "Event Cancelled?"}>
              Since our marketplace is fully peer-to-peer we don't have the ability to refund for cancelled events. We recommend taking likelihood of cancellation into account when making a purchase. Events such as sports events tend to have very low cancellation rates (excluding some playoff games).
            </FAQAnswer>
          </FAQItem>
          <FAQItem>
            <FAQQuestion onClick={() => toggleFAQ("Can't verify tickets?")}>
              <FAQIcon style={{ transform: openFAQ === "Can't verify tickets?" ? 'rotate(45deg)' : 'none' }}>+</FAQIcon>
              I have clicked into an order but the extension isn't showing any tickets to verify. What's wrong?
            </FAQQuestion>
            <FAQAnswer $isOpen={openFAQ === "Can't verify tickets?"}>
              Sometimes the extension doesn't pick up the tickets, if you refresh the page you should see the ticket populate in the extension for proving. If that doesn't work it's possible that the event has been set to non-transferable on Ticketmaster. We don't support listings for non-transferable tickets right now. Often tickets become transferrable in the days/weeks leading up to the event, you could check back to list then.
            </FAQAnswer>
          </FAQItem>
          <FAQItem>
            <FAQQuestion onClick={() => toggleFAQ("Why Blockchain?")}>
              <FAQIcon style={{ transform: openFAQ === "Why Blockchain?" ? 'rotate(45deg)' : 'none' }}>+</FAQIcon>
              Why use a blockchain?
            </FAQQuestion>
            <FAQAnswer $isOpen={openFAQ === "Why Blockchain?"}>
              Blockchains allow us to offer the lowest fees possible by not needing to pay a payment processor.
            </FAQAnswer>
          </FAQItem>
          <FAQItem>
            <FAQQuestion onClick={() => toggleFAQ("Blockchain scam")}>
              <FAQIcon style={{ transform: openFAQ === "Blockchain scam" ? 'rotate(45deg)' : 'none' }}>+</FAQIcon>
              Aren't blockchains scams?
            </FAQQuestion>
            <FAQAnswer $isOpen={openFAQ === "Blockchain scam"}>
              There are many token-based scams in the market today. However, we are not asking you to buy or sell any unregulated tokens. We use the blockchain purely for settling ticket transactions at the cheapest prices possible.
            </FAQAnswer>
          </FAQItem>
          <FAQItem>
            <FAQQuestion onClick={() => toggleFAQ("What is USDC")}>
              <FAQIcon style={{ transform: openFAQ === "What is USDC" ? 'rotate(45deg)' : 'none' }}>+</FAQIcon>
              What's this USDC token then? I thought you said you weren't asking me to buy any tokens?
            </FAQQuestion>
            <FAQAnswer $isOpen={openFAQ === "What is USDC"}>
              Great question! USDC is a USD-equivalent stablecoin that is backed by US dollars and issued by <SectionEmbeddedLink to="" onClick={() => jumpToMedia('https://www.circle.com/en/')}>Circle</SectionEmbeddedLink>, a regulated financial institution in the US. It is only used to settle transactions on our ticket exchange. Circle ensures that each USDC token is always backed by one US Dollar. To learn more about USDC see the FAQs on <SectionEmbeddedLink to="" onClick={() => jumpToMedia('https://www.circle.com/en/usdc')}>Circle's website</SectionEmbeddedLink>.
            </FAQAnswer>
          </FAQItem>
        </FAQContainer>

        <AboutContainer>
          <IconRow>
            <SocialIcon
              icon={'twitter'}
              onClick={() => jumpToMedia('https://twitter.com/zkp2p')}
            />

            <SocialIcon
              icon={'github'}
              onClick={() => jumpToMedia('https://github.com/zkp2p')}
            />

            <SocialIcon
              icon={'telegram'}
              onClick={() => jumpToMedia('https://t.me/+XDj9FNnW-xs5ODNl')}
            />
          </IconRow>

          <SignatureContainer>
            Made with<HeartIcon>♡</HeartIcon>
          </SignatureContainer>
          by the ZKP2P Team
        </AboutContainer>
      </Container>
    </PageWrapper>
  )
};

const PageWrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 4rem;
  
  @media (min-width: 600px) {
    padding: 12px 8px;
    padding-bottom: 0rem;
  }
`;

const Container = styled.div`
  width: 100%;
  min-height: 535px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  padding: 0 0 40px;
`;

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  min-height: 535px;
  max-height: 1000px;
`;

const HeroTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 6rem;
  gap: 0.5rem;
  width: 100%;
  z-index: ${Z_INDEX.landing_hero};
`;

const LearnMoreContainer = styled.div`
  display: flex;
  align-items: center;
  color: rgb(94, 94, 94);
  cursor: pointer;
  font-size: 20px;
  font-weight: 535;
  margin: 18px 0px 36px;
  padding-left: 12px;
  pointer-events: auto;
  gap: 8px;

  &:hover {
    opacity: 0.6;
  }
`;

const HeartIcon = styled.span`
  color: ${colors.heartRed};
  margin-top: -2px;
`;

const Icon = styled(SVGIconThemed)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
`;

const SocialIcon = styled(SVGIconThemed)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
`;

const SubHeaderContainer = styled.div`
  display: grid;
  justify-content: center;
  flex-direction: column;
  padding-top: 1rem;
  gap: 1rem;
`;

const AboutContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 1rem;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
`;

const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  margin-top: 0.75rem;
`;

const SignatureContainer = styled.div`
  padding-top: 1rem;
  gap: 0.25rem;
  display: flex;
`;

const BoxesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  max-width: 800px;
  margin-top: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Box = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  background-color: #FFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const BoxHeader = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #333;
`;

const UnorderedList = styled.ol`
  padding-left: 1.5rem;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  font-size: 1.1rem;
  color: #444;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 1rem;
    color: #0066CC;
  }

  &:hover {
    opacity: 0.7;
  }
`;

const HowToSectionsContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
`;

const HowToSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

const HowToText = styled.p`
  line-height: 1.5;
`;

const DialogBox = styled.div<{ $isLeft: boolean }>`
  width: 45%;
  padding: 2rem;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  order: ${props => props.$isLeft ? 0 : 1};

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
    text-align: center;
  }

  p {
    font-size: 1rem;
    color: #666;
  }

  @media (max-width: 768px) {
    width: 100%;
    order: 1;
  }
`;

const SectionCTA = styled(Link)`
  color: #0066CC;
  font-weight: 535;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  margin-top: auto;
  transition: opacity 250ms ease 0s;
  &:hover {
    opacity: 0.6;
  }
`;

const SectionEmbeddedLink = styled(Link)`
  color: #0066CC;
  font-weight: 535;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  margin-top: auto;
  transition: opacity 250ms ease 0s;
  &:hover {
    opacity: 0.6;
  }
`;

const ImageWrapper = styled.div<{ $isLeft: boolean }>`
  width: 45%;
  aspect-ratio: 1 / 1;
  max-height: 750px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  order: ${props => props.$isLeft ? 0 : 1};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    order: 0;
  }
`;

const SectionImage = styled.img`
  height: 100%;
  object-position: top center;
  border-radius: 8px;
  object-fit: cover;
  display: block;
  mask-image: linear-gradient(to bottom, 
    black 80%, 
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(to bottom, 
    black 80%, 
    transparent 100%
  );
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  color: #0066CC;
`;

const FAQContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
`;

const FAQHeader = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #333;
`;

const FAQItem = styled.div`
  margin-bottom: 2rem;
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 2rem;
`;

const FAQQuestion = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const FAQIcon = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 1rem;
  transition: transform 0.3s ease;
`;

const FAQAnswer = styled.div<{ $isOpen: boolean }>`
  font-size: 1rem;
  color: #666;
  margin-top: 0.5rem;
  line-height: 1.5;
  padding-left: 2.5rem;
  max-height: ${props => props.$isOpen ? '1000px' : '0'};
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  opacity: ${props => props.$isOpen ? 1 : 0};
`;

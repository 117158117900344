import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Inbox } from 'react-feather';
import styled, { css } from 'styled-components/macro';

import { Button } from '@components/common/Button';
import { TicketsRow } from '@components/Listings/NewListing/TicketsRow'; 
import { EventXScrollRow } from '@components/common/EventXScrollRow';
import { DetailedTicket, Event, TicketStatus } from '@helpers/types';
import { formatEventDateTime } from '@helpers/dateFormat';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import useTableScroll from '@hooks/useTableScroll';
import useAccount from '@hooks/contexts/useAccount';
import useSellers from '@hooks/contexts/useSellers';


export interface TicketRowData {
  ticketId: string;
  startTimeText: string;
  ownerAddress: string;
  seatSummaryText: string;
}

interface TicketsTableProps {
  handleVerifyTicketsPressed: () => void;
  setSelectedTicketIds: Dispatch<SetStateAction<string[]>>;
  setSelectedEventForTickets: Dispatch<SetStateAction<Event | null>>;
}

export const TicketsTable: React.FC<TicketsTableProps> = ({
  handleVerifyTicketsPressed,
  setSelectedTicketIds,
  setSelectedEventForTickets
}) => {
  /*
   * Contexts
   */

  const { isLoggedIn } = useAccount();
  const { sellerTickets } = useSellers();

  /*
   * State
   */

  const [ticketsRowData, setTicketsRowData] = useState<TicketRowData[]>([]);

  const [eventHeaderData, setEventHeaderData] = useState<Event[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);

  /*
   * Handlers
   */

  const handleSelectEventAtIndex = (index: number) => {
    const event = eventHeaderData[index];

    setSelectedEvent(event);
  };

  const handleRowClick = (index: number) => {
    let finalSelectedIndices = selectedIndices;
    if (selectedIndices.includes(index)) {
      finalSelectedIndices = selectedIndices.filter(i => i !== index);
    } else {
      finalSelectedIndices = [...selectedIndices, index];
    }

    setSelectedIndices(finalSelectedIndices);

    if (ticketsRowData && finalSelectedIndices.length > 0) {
      setSelectedEventForTickets(selectedEvent);

      const selectedTicketIds = finalSelectedIndices
        .map(index => ticketsRowData[index]?.ticketId)
        .filter((id): id is string => id !== undefined);
      setSelectedTicketIds(selectedTicketIds);
    } else {
      setSelectedEventForTickets(null);

      setSelectedTicketIds([]);
    }
  };
  
  /*
   * Hooks
   */

  useEffect(() => {
    // console.log('sellerTickets: ', sellerTickets);
    if (sellerTickets && selectedEvent) {

      // Filter only for the orders for the selected Event
      const ticketsForEvent = sellerTickets.filter((ticket: DetailedTicket) => ticket.eventId === selectedEvent.id); 

      if (ticketsForEvent.length > 0) {
        const sanitizedTickets: TicketRowData[] = [];
        ticketsForEvent.forEach((ticket: DetailedTicket) => {
          const ticketId = ticket.ticketId;

          // Ticket is not verified
          if (ticket.status !== TicketStatus.Verified) {
            return;
          }

          const ownerAddress = ticket.owner;

          const section = ticket.section;
          const row = ticket.row;
          const seat = ticket.seat;
          const seatSummaryText = `Sec ${section}, Row ${row}, Seat ${seat}`;

          const event = ticket.event;
          const startTimeText = formatEventDateTime(event.startTime);

          sanitizedTickets.push({
            ticketId,
            startTimeText,
            ownerAddress,
            seatSummaryText,
          } as TicketRowData);
        });

        setTicketsRowData(sanitizedTickets);
      } else {
        setTicketsRowData([]);
      }
    } else {
      setTicketsRowData([]);
    }
  }, [sellerTickets, selectedEvent]);

  useEffect(() => {
    if (sellerTickets) {
      const verfiedTickets = sellerTickets.filter((ticket: DetailedTicket) => ticket.status === TicketStatus.Verified);
      const events = Array.from(new Set(verfiedTickets.map((ticket: DetailedTicket) => ticket.event)));

      setEventHeaderData(events);

      setSelectedEvent(events[0]);
    } else {
      setEventHeaderData([]);
    }
  }, [sellerTickets]);

  useEffect(() => {
    setSelectedIndices([]);
  }, [selectedEvent]);

  const {
    tableRef,
    isScrolling
  } = useTableScroll();

  /*
   * Component
   */

  function renderContent() {
    return (
      <Content>
        {ticketsRowData.length === 0 ? (
          <ErrorContainer>
            <SelectTicketsAndAddTicketsContainer>
              <ThemedText.BodySmall textAlign="left">
                Included Tickets ({selectedIndices.length})
              </ThemedText.BodySmall>

              {isLoggedIn ? (
                <Button onClick={handleVerifyTicketsPressed} height={40}>
                  + Verify Tickets
                </Button>
              ) : null}
            </SelectTicketsAndAddTicketsContainer>

            <ErrorIconAndMessageContainer>
              <ThemedText.DeprecatedBody textAlign="center">
                <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
                <div>
                  You have no unlisted tickets.
                </div>
                <div>
                  Verify your tickets by clicking above to create a new listing.
                </div>
              </ThemedText.DeprecatedBody>
            </ErrorIconAndMessageContainer>
          </ErrorContainer>
        ) : (
          <>
            <SelectTicketsAndAddTicketsContainer>
              <ThemedText.BodySmall textAlign="left">
                Included Tickets ({selectedIndices.length})
              </ThemedText.BodySmall>

              {isLoggedIn ? (
                <Button onClick={handleVerifyTicketsPressed} height={40}>
                  + Verify Tickets
                </Button>
              ) : null}
            </SelectTicketsAndAddTicketsContainer>

            <EventXScrollRow
              events={eventHeaderData}
              didSelectEventAtIndex={handleSelectEventAtIndex}
            />

            <Table ref={tableRef}>
              {ticketsRowData.map((ticketRowData, rowIndex) => (
                <TicketRowStyled key={rowIndex}>
                  <TicketsRow
                    isScrolling={isScrolling}
                    startTimeText={ticketRowData.startTimeText}
                    seatSummaryText={ticketRowData.seatSummaryText}
                    isSelected={selectedIndices.includes(rowIndex)}
                    onRowClick={() => handleRowClick(rowIndex)}
                    rowIndex={rowIndex}
                  />
                </TicketRowStyled>
              ))}
            </Table>
          </>
        )}
      </Content>
    )
  }

  return (
    <>
      {renderContent()}
    </>
  )
};

const Content = styled.div`
  display: flex;
  background-color: #FAF9F6;
  flex-direction: column;
  overflow: hidden;

  @media (min-width: 600px) {
    border-radius: 16px;
    border: 1px solid ${colors.defaultBorderColor};
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 25vh;
  gap: 36px;
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`;

const SelectTicketsAndAddTicketsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.defaultBorderColor};
  padding: 0.75rem 1rem 0.75rem 1rem;
`;

const ErrorIconAndMessageContainer = styled.div`
  padding-bottom: 3rem;
`;

const Table = styled.div`
  font-size: 16px;
  color: #616161;
  max-height: 164px;
  overflow-y: auto;

  & > * {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${colors.defaultBorderColor};
    }
  }

  & > *:last-child::after {
    display: none;
  }
`;

const TicketRowStyled = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

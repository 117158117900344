export const CreateOrderTransactionStatus = {
  DEFAULT: 'default',
  NOT_LOGGED_IN: 'not_logged_in',
  MISSING_PRICE: 'missing_amounts',
  INVALID_TICKET_SELECTION: 'invalid_ticket_selection',
  MISSING_RECIPIENT: 'missing_recipient',
  APPROVAL_REQUIRED: 'approval_required',
  INSUFFICIENT_BALANCE: 'insufficient_balance',
  TRANSACTION_SIGNING: 'transaction_signing',
  TRANSACTION_MINING: 'transaction_mining',
  VALID: 'valid',
  TRANSACTION_SUCCEEDED: 'transaction_succeeded'
};

export type CreateOrderTransactionStatusType = typeof CreateOrderTransactionStatus[keyof typeof CreateOrderTransactionStatus];

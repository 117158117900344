import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components/macro';

import { TicketsRow } from '@components/Event/BuyModal/TicketsRow';
import { Ticket } from '@helpers/types';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import useTableScroll from '@hooks/useTableScroll';


export interface TicketRowData {
  seatSummaryText: string;
};

interface TicketsTableProps {
  listingTickets: Ticket[];
  setSelectedListingTickets: Dispatch<SetStateAction<string[]>>;
};

export const TicketsTable: React.FC<TicketsTableProps> = ({
  listingTickets,
  setSelectedListingTickets,
}) => {
  /*
   * State
   */

  const [ticketsRowData, setTicketsRowData] = useState<TicketRowData[]>([]);

  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);

  /*
   * Handlers
   */

  const handleRowClick = (index: number) => {
    let finalSelectedIndices = selectedIndices;
    if (selectedIndices.includes(index)) {
      finalSelectedIndices = selectedIndices.filter(i => i !== index);
    } else {
      finalSelectedIndices = [...selectedIndices, index];
    }

    setSelectedIndices(finalSelectedIndices);

    if (listingTickets && finalSelectedIndices.length > 0) {
      const selectedTicketIds = finalSelectedIndices
        .map(index => listingTickets[index]?.ticketId)
        .filter((id): id is string => id !== undefined);
      setSelectedListingTickets(selectedTicketIds);
    } else {
      setSelectedListingTickets([]);
    }
  };
  
  /*
   * Hooks
   */

  useEffect(() => {
    // console.log('listingTickets: ', listingTickets);

    if (listingTickets && listingTickets.length > 0) {
      var sanitizedTickets: TicketRowData[] = [];
      sanitizedTickets = listingTickets.map((ticket: Ticket, index: number) => {
        const section = ticket.section;
        const row = ticket.row;
        const seat = ticket.seat;
        const seatSummaryText = `Sec ${section}, Row ${row}, Seat ${seat}`;

        return {
          seatSummaryText
        };
      });

      setTicketsRowData(sanitizedTickets);
    } else {
      setTicketsRowData([]);
    }
  }, [listingTickets]);

  const {
    tableRef,
    isScrolling
  } = useTableScroll();

  /*
   * Component
   */

  function renderContent() {
    return (
      <Content>
        <PositionsContainer>
          <PositionCountTitle>
            <ThemedText.BodySmall textAlign="left">
              Selected Tickets ({selectedIndices.length})
            </ThemedText.BodySmall>
          </PositionCountTitle>

          <Table ref={tableRef}>
            {ticketsRowData.map((ticketRowData, rowIndex) => (
              <TicketRowStyled key={rowIndex}>
                <TicketsRow
                  isScrolling={isScrolling}
                  seatSummaryText={ticketRowData.seatSummaryText}
                  isSelected={selectedIndices.includes(rowIndex)}
                  onRowClick={() => handleRowClick(rowIndex)}
                  rowIndex={rowIndex}
                />
              </TicketRowStyled>
            ))}
          </Table>
        </PositionsContainer>
      </Content>
    )
  }

  return (
    <Container>
      {renderContent()}
    </Container>
  )
};

const Container = styled.div`
  width: 100%;
  gap: 1rem;
`;

const Content = styled.main`
  display: flex;
  background-color: #FAF9F6;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  @media (min-width: 600px) {
    border-radius: 16px;
    border: 1px solid ${colors.defaultBorderColor};
  }
`;

const PositionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const PositionCountTitle = styled.div`
  width: 100%;
  justify-content: space-between;
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  border-bottom: 1px solid ${colors.defaultBorderColor};
`;

const Table = styled.div`
  width: 100%;
  font-size: 16px;
  color: #616161;
  max-height: 120px;
  overflow-y: auto;

  & > * {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${colors.defaultBorderColor};
    }
  }

  & > *:last-child::after {
    display: none;
  }
`;

const TicketRowStyled = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

import React, { useEffect, useState } from 'react';
import { Inbox } from 'react-feather';
import styled, { css } from 'styled-components/macro';

import { Button } from '@components/common/Button';
import { RowBetween } from '@components/layouts/Row';
import { CustomConnectButton } from '@components/common/ConnectButton';
import { UpdateListingModal } from '@components/Listings/Seller/UpdateListing';
import { ShareListingModal } from '@components/Listings/Seller/ShareListing';
import { ListingsRow } from '@components/Listings/Seller/ListingsRow'; 
import { DetailedListing, Event } from '@helpers/types';
import { OrderSettlementDetails } from '@components/Listings/Seller/OrderTable';
import { formatEventDateTime } from '@helpers/dateFormat';
import { OrderTable } from '@components/Listings/Seller/OrderTable';
import { EventHeader } from '@components/common/EventHeader';
import { formatTicketSeatsSummary } from '@helpers/ticketFormat';
import { toUsdString } from '@helpers/units';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import useAccount from '@hooks/contexts/useAccount';
import useSellers from '@hooks/contexts/useSellers';


export interface ListingRowData {
  listingId: bigint;
  eventUrl: string;
  ticketsSummaryText: string;
  ticketPriceText: string;
  hasOrders: boolean;
}

interface ListingsTableProps {
  handleCreateListingPressed: () => void;
  handleTransferOrderPressed: (selectedOrderDetails: OrderSettlementDetails) => void;
  handleFinalizeOrderPressed: (selectedOrderDetails: OrderSettlementDetails) => void;
}

export const ListingsTable: React.FC<ListingsTableProps> = ({
  handleCreateListingPressed,
  handleTransferOrderPressed,
  handleFinalizeOrderPressed
}) => {
  /*
   * Contexts
   */

  const { isLoggedIn } = useAccount();
  const { sellerListingsStore } = useSellers();

  /*
   * State
   */

  const [listingRowData, setListingRowData] = useState<ListingRowData[]>([]);

  const [eventHeaderData, setEventHeaderData] = useState<Event[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

  const [selectedListingToEdit, setSelectedListingToEdit] = useState<DetailedListing | null>(null);
  const [editListingTicketsText, setEditListingTicketsText] = useState<string>('');
  const [showUpdateListingModal, setShowUpdateListingModal] = useState<boolean>(false);

  const [selectedListingToShare, setSelectedListingToShare] = useState<ListingRowData | null>(null);
  const [showShareListingModal, setShowShareListingModal] = useState<boolean>(false);
  /*
   * Hooks
   */

  useEffect(() => {
    if (sellerListingsStore && selectedEvent) {
      const sellerListings = sellerListingsStore.listings;

      // Filter only for the orders for the selected Event
      const listingsForEvent = sellerListings.filter((listing: DetailedListing) => listing.eventId === selectedEvent.id);

      if (listingsForEvent.length > 0) {
        var listingRowsData: ListingRowData[] = [];
        listingRowsData = listingsForEvent.map((detailedListing: DetailedListing, index: number) => {
          const eventText = selectedEvent.name;
          const startTimeText = formatEventDateTime(selectedEvent.startTime);
          const venueText = selectedEvent.venue;
          const eventUrl = selectedEvent.eventUrl;

          const ticketsOnListing = [...detailedListing.unfilledTickets, ...detailedListing.pendingTickets];
          const ticketsSummaryText = formatTicketSeatsSummary(ticketsOnListing);

          const ticketPriceText = `$${toUsdString(detailedListing.price)}`;

          return {
            listingId: detailedListing.listingId,
            eventText,
            venueText,
            startTimeText,
            ticketsSummaryText,
            ticketPriceText,
            eventUrl,
            hasOrders: detailedListing.orders?.length > 0
          } as ListingRowData;
        });

        setListingRowData(listingRowsData);
      } else {
        setListingRowData([]);
      }
    } else {
      setListingRowData([]);
    }
  }, [sellerListingsStore, selectedEvent]);

  useEffect(() => {
    if (sellerListingsStore) {
      const events = Object.values(sellerListingsStore.events);

      setEventHeaderData(events);

      setSelectedEvent(events[0]);
    } else {
      setEventHeaderData([]);
    }
  }, [sellerListingsStore]);

  /*
   * Handlers
   */

  const handleSelectEventAtIndex = (index: number) => {
    const event = eventHeaderData[index];

    setSelectedEvent(event);
  };

  const handleEditListingModalClose = () => {
    setShowUpdateListingModal(false);
  };

  const handleEditListingClicked = (listingRowIndex: number) => {
    if (sellerListingsStore) {
      const sellerListings = sellerListingsStore.listings;
      const selectedListingId = listingRowData[listingRowIndex].listingId;
      const listing = sellerListings.find(listing => listing.listingId === selectedListingId);

      if (listing) {
        setSelectedListingToEdit(listing);
        setEditListingTicketsText(listingRowData[listingRowIndex].ticketsSummaryText);

        setShowUpdateListingModal(true);
      } else {
        setShowUpdateListingModal(false);

        setEditListingTicketsText('');
        setSelectedListingToEdit(null);
      }
    } else {
      setShowUpdateListingModal(false);

      setEditListingTicketsText('');
      setSelectedListingToEdit(null);
    }
  };

  const handleShareListingModalClose = () => {
    setShowShareListingModal(false);
  };

  const handleShareListingClicked = (listingRowIndex: number) => {
    if (sellerListingsStore) {
      const selectedListing = listingRowData[listingRowIndex];

      if (selectedListing) {
        setSelectedListingToShare(selectedListing);
    
        setShowShareListingModal(true);
      } else {
        setShowShareListingModal(false);

        setSelectedListingToShare(null);
      }
    } else {
      setShowShareListingModal(false);

      setSelectedListingToShare(null);
    }
  };

  /*
   * Component
   */

  return (
    <Container>
      {showUpdateListingModal && (
        (selectedListingToEdit && selectedEvent) ? ( 
          <UpdateListingModal
            listing={selectedListingToEdit}
            listingEvent={selectedEvent}
            ticketsText={editListingTicketsText}
            onBackClick={handleEditListingModalClose}
          />
        ) : (
          null
        )
      )}

      {showShareListingModal && (
        (selectedListingToShare && selectedEvent) ? ( 
          <ShareListingModal
            listingDetails={selectedListingToShare}
            listingEvent={selectedEvent}
            onBackClick={handleShareListingModalClose}
          />
        ) : (
          null
        )
      )}

      <div>
        <TitleRow>
          <ThemedText.HeadlineMedium>
            My Listings
          </ThemedText.HeadlineMedium>
          {isLoggedIn ? (
            <Button onClick={handleCreateListingPressed} height={40}>
                + Create Listing
            </Button>
          ) : null}
        </TitleRow>

        <Content>
          {!isLoggedIn ? (
            <ErrorContainer>
              <ThemedText.DeprecatedBody textAlign="center">
                <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
                <div>
                  Your active listings will appear here.
                </div>
              </ThemedText.DeprecatedBody>
              <CustomConnectButton width={152} />
            </ErrorContainer>
          ) : listingRowData.length === 0 ? (
              <ErrorContainer>
                <ThemedText.DeprecatedBody textAlign="center">
                  <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
                  <div>
                    You have no active listings.
                  </div>
                </ThemedText.DeprecatedBody>
              </ErrorContainer>
          ) : (
            <>
              <EventHeader
                events={eventHeaderData}
                didSelectEventAtIndex={handleSelectEventAtIndex}
              />

              <TableHeaderRow>
                <ColumnHeader>Details</ColumnHeader>

                <ColumnHeader>Tickets on Listing</ColumnHeader>

                <ColumnHeader>Price per Ticket</ColumnHeader>

                <ColumnHeader>Actions</ColumnHeader>
              </TableHeaderRow>

              <Table>
                {listingRowData.map((listingRowData, rowIndex) => (
                  <StyledListingRow key={rowIndex}>
                    <ListingsRow
                      listingId={listingRowData.listingId}
                      ticketsSummaryText={listingRowData.ticketsSummaryText}
                      ticketPriceText={listingRowData.ticketPriceText}
                      eventUrl={listingRowData.eventUrl}
                      hasOrders={listingRowData.hasOrders}
                      handleEditListingPressed={() => handleEditListingClicked(rowIndex)}
                      handleShareListingPressed={() => handleShareListingClicked(rowIndex)}
                    />
                  </StyledListingRow>
                ))}
              </Table>
            </>
          )}
        </Content>
      </div>

      {listingRowData.length > 0 && (
        <OrderTable
          selectedEventToFilterOrders={selectedEvent}
          handleTransferOrderPressed={handleTransferOrderPressed}
          handleCompleteOrderPressed={handleFinalizeOrderPressed}
        />
      )}
    </Container>
  )
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const TitleRow = styled(RowBetween)`
  align-items: flex-end;
  color: ${colors.darkText};
  padding: .5rem 1rem 1.75rem 1rem;
  height: 76px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    padding: 0 1.75rem;
  };
`;

const Content = styled.div`
  background-color: ${colors.container};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  overflow: hidden;
  position: relative;

  @media (min-width: 600px) {
    border-radius: 16px;
  }
`;

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 36px;
  max-width: 340px;
  min-height: 25vh;
  gap: 36px;
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`;

const TableHeaderRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 2fr 1fr;
  grid-gap: 4px;
  padding: 1rem 1.5rem 0.75rem 1.5rem;
  text-align: left;
  color: ${colors.darkText};
  border-bottom: 1px solid ${colors.defaultBorderColor};
`;

const ColumnHeader = styled.div`
  text-align: center;
  font-size: 14px;
  
  @media (max-width: 600px) {
    font-size: 13px;
  };
`;

const Table = styled.div`
  font-size: 16px;
  color: #616161;

  & > * {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${colors.defaultBorderColor};
    }
  }

  & > *:last-child::after {
    display: none;
  }
`;

const StyledListingRow = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

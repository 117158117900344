import { createContext } from 'react';

import { DetailedListing } from '@helpers/types';


interface ListingsValues {
  activeListingsStore: DetailedListing[] | null;

  refetchListingCounter: (() => void) | null;
  shouldFetchListingCounter: boolean;

  refetchActiveListings: (() => void) | null;
  shouldFetchActiveListings: boolean;
};

const defaultValues: ListingsValues = {
  activeListingsStore: null,

  refetchListingCounter: null,
  shouldFetchListingCounter: false,

  refetchActiveListings: null,
  shouldFetchActiveListings: false,
};

const ListingsContext = createContext<ListingsValues>(defaultValues);

export default ListingsContext;

import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Link from '@mui/material/Link';

import useMediaQuery from '@hooks/useMediaQuery';
import { TransactionIconButton } from '@components/common/TransactionIconButton';
import { colors } from '@theme/colors';
import useSellers from '@hooks/contexts/useSellers';
import useDeleteListing from '@hooks/transactions/useDeleteListing';


interface ListingRowProps {
  listingId: bigint;
  ticketsSummaryText: string;
  ticketPriceText: string;
  eventUrl: string;
  hasOrders: boolean;
  handleEditListingPressed: () => void;
  handleShareListingPressed: () => void;
}

export const ListingsRow: React.FC<ListingRowProps> = ({
  listingId,
  ticketsSummaryText,
  ticketPriceText,
  eventUrl,
  hasOrders,
  handleEditListingPressed,
  handleShareListingPressed
}: ListingRowProps) => {
  ListingsRow.displayName = "ListingsRow";

  const isMobile = useMediaQuery() === 'mobile';

  /*
   * Contexts
   */

  const { refetchSellerListingStore, refetchSellerTickets } = useSellers();

  /*
   * Hooks
   */

  const onSuccessCallback = useCallback((data: any) => {
    console.log('sellerDeleteListing Succeeded: ', data);

    refetchSellerTickets?.();

    refetchSellerListingStore?.();
  }, [refetchSellerListingStore, refetchSellerTickets]);

  const {
    writeDeleteListingAsync,
    shouldConfigureDeleteListing,
    setListingIdInput,
    setShouldConfigureDeleteListing,
    signDeleteListingTransactionStatus,
    mineDeleteListingTransactionStatus,
    // transactionHash,
  } = useDeleteListing(onSuccessCallback);

  useEffect(() => {
    const submitDeleteListing = async () => {
      if (shouldConfigureDeleteListing && writeDeleteListingAsync) {
        try {
          await writeDeleteListingAsync?.();
        } catch (error) {
          console.log('writeDeleteListingAsync failed: ', error);

          setShouldConfigureDeleteListing(false);

          setListingIdInput(null);
        }
      };
    };

    submitDeleteListing();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldConfigureDeleteListing, writeDeleteListingAsync]);

  /*
   * Handlers
   */

  const handleDeleteListingPressed = ()=> {
    console.log('handleDeleteListingPressed: ', listingId);

    setListingIdInput(listingId);

    setShouldConfigureDeleteListing(true);
  };

  /*
   * Helpers
   */

  const isDeleteListingLoading = (): boolean => {
    const isSigningTransctionMining = signDeleteListingTransactionStatus === 'loading';
    const isMiningTransaction = mineDeleteListingTransactionStatus === 'loading';

    return isSigningTransctionMining || isMiningTransaction;
  };

  /*
   * Component
   */

  return (
    <Container>
      <ListingDetailsContainer isMobile={isMobile}>
        <EventDetailsContainer>
          <SummaryLabelValue>
            <Link href={eventUrl} target="_blank">
              View Event
            </Link>
          </SummaryLabelValue>
        </EventDetailsContainer>

        <TicketDetailsContainer>
          <SummaryLabel>
            <SummaryLabelValue>{ticketsSummaryText}</SummaryLabelValue>
          </SummaryLabel>
        </TicketDetailsContainer>

        <PriceDetailsContainer>
          <SummaryLabel>
            <TicketPriceLabel>
              {ticketPriceText}
            </TicketPriceLabel>
          </SummaryLabel>
        </PriceDetailsContainer>

        <ActionsContainer>
        <TransactionIconButton
            icon="share"
            text='Share Listing'
            onClick={() => handleShareListingPressed()}
            loading={false}
            disabled={false}
          />

          <TransactionIconButton
            icon="edit"
            text='Edit Listing'
            onClick={() => handleEditListingPressed()}
            loading={false}
            disabled={false}
          />

          <TransactionIconButton
            icon="trash"
            text='Delete Listing'
            onClick={() => handleDeleteListingPressed()}
            loading={isDeleteListingLoading()}
            disabled={hasOrders}
            disabledText='Must fulfill or cancel outstanding orders on listing to delete'
          />
        </ActionsContainer>
      </ListingDetailsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const ListingDetailsContainer = styled.div<{isMobile?: boolean}>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr 2fr 1fr;
  align-items: center;
  padding: 1rem 1.5rem;
  line-height: 24px;
`;

const EventDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TicketDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PriceDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.25rem;
  justify-content: center;
  width: 100%;
`;

const SummaryLabel = styled.label`
  font-size: 15px;
  color: #FFFFFF;
`;

const SummaryLabelValue = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
`;

const TicketPriceLabel = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
`;

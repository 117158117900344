export type ExtensionEventMessage = {
  origin: string;
  data: {
    type: string;
    status: string;
    requestHistory: {
      notaryRequests: ExtensionNotaryProofRequest[];
    };
  }
};

export type ExtensionEventVersionMessage = {
  origin: string;
  data: {
    type: string;
    status: string;
    version: string;
  }
};

export type ExtensionNotaryProofRequest = {
  body: string,
  headers: string,
  id: string,
  maxTranscriptSize: number,
  method: string,
  notaryUrl: string,
  proof: any,                 // returned as an object
  secretHeaders: string[],
  secretResps: string[],
  status: string,
  url: string,
  verification: any,          // returned as an object
  metadata: any,              // different depending on the notarization
  websocketProxyUrl: string,
};

export const ExtensionPostMessage = {
  FETCH_EXTENSION_VERSION: "fetch_extension_version",

  FETCH_VERIFIED_TICKET_PROOFS: "fetch_verified_ticket_proofs",
  FETCH_INITIALIZE_TRANSFER_PROOFS: "fetch_transfer_initiated_proofs",
  FETCH_TRANSFER_PROOFS: "fetch_transfer_proofs",

  OPEN_SIDEBAR_VERIFY_TICKETS: "open_sidebar_verify_tickets",
  OPEN_SIDEBAR_INITIALIZE_TRANSFERS: "open_sidebar_transfer_initiated",
  OPEN_SIDEBAR_TRANSFERS: "open_sidebar_transfers", 
};

export const ExtensionReceiveMessage = {
  EXTENSION_VERSION_RESPONSE: "extension_version_response",
  VERIFIED_TICKET_PROOFS_RESPONSE: "verified_ticket_proofs_response",
  INITIALIZE_TRANSFER_PROOFS_RESPONSE: "verified_transfer_initiated_proofs_response",
  TRANSFER_PROOFS_RESPONSE: "verified_transfer_proofs_response",
};

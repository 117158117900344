import { ReactNode } from 'react';
import { readContract } from '@wagmi/core';

import { Ticket, Abi } from '@helpers/types';
import { esl, CALLER_ACCOUNT } from '@helpers/constants';
import useSmartContracts from '@hooks/contexts/useSmartContracts';

import TicketsContext from './TicketsContext';


interface ProvidersProps {
  children: ReactNode;
}

const Tickets = ({ children }: ProvidersProps) => {
  /*
   * Contexts
   */

  const { verifiedTicketRegistryAddress, verifiedTicketRegistryAbi } = useSmartContracts();

  /*
   * Contract Reads
   */

  const fetchTicketsBatch = async (ticketIds: string[]): Promise<Ticket[]> => {
    try {
      // getTickets(bytes32[] memory _tickets) external view returns (ITicketRegistry.Ticket[] memory ticketInfo)
      const rawTicketsData = await readContract({
        address: verifiedTicketRegistryAddress as `0x${string}`,
        abi: verifiedTicketRegistryAbi as Abi,
        functionName: 'getTickets',
        args: [
          ticketIds
        ],
        account: CALLER_ACCOUNT,
      });
      
      const sanitizedTickets =  sanitizeTickets(rawTicketsData as any[]);
      return sanitizedTickets;
    } catch (error) {
      esl && console.error('Error fetching tickets batch:', error); 
      
      return [];
    }
  };

  const fetchExchanges = async (): Promise<string[]> => {
    try {
      // function getExchanges() external view returns (address[] memory)
      const rawExchangeAddresses = await readContract({
        address: verifiedTicketRegistryAddress as `0x${string}`,
        abi: verifiedTicketRegistryAbi as Abi,
        functionName: 'getExchanges',
        account: CALLER_ACCOUNT,
      });

      esl && console.error('rawExchangeAddresses:', rawExchangeAddresses);

      const sanitizedExchanges = rawExchangeAddresses as string[];
      return sanitizedExchanges;
    } catch (error) {
      esl && console.error('Error fetching exchanges:', error);
      
      return [];
    }
  };

  const fetchUserTickets = async (userAddress: string): Promise<Ticket[]> => {
    try {
      // getUserTickets(address _user) external view returns (bytes32[] memory)
      const rawUserTicketIds = await readContract({
        address: verifiedTicketRegistryAddress as `0x${string}`,
        abi: verifiedTicketRegistryAbi as Abi,
        functionName: 'getUserTickets',
        args: [
          userAddress
        ],
        account: CALLER_ACCOUNT,
      });

      esl && console.error('rawUserTicketIds:', rawUserTicketIds);
      
      const sanitizedUserTicketIds = rawUserTicketIds as string[];
      if (sanitizedUserTicketIds.length === 0) {
        return [];
      } else {
        const tickets = await fetchTicketsBatch(sanitizedUserTicketIds);
        return tickets;
      }
    } catch (error) {
      esl && console.error('Error fetching user tickets:', error);
      
      return [];
    }
  };

  /*
   * Helpers
   */

  const sanitizeTickets = (rawTicketsData: any[]): Ticket[] => {
    const sanitizedTickets: Ticket[] = [];

    for (let i = rawTicketsData.length - 1; i >= 0; i--) {
      const rawTicketWithIdData = rawTicketsData[i];
      const rawTicketData = rawTicketWithIdData.ticket;

      const sanitizedTicket: Ticket = {
        ticketId: rawTicketWithIdData.ticketId,
        owner: rawTicketData.owner,
        ticketmasterId: rawTicketData.ticketmasterId,
        section: rawTicketData.section,
        row: rawTicketData.row,
        seat: rawTicketData.seat,
        status: Number(rawTicketData.status),
        eventId: rawTicketData.eventId,
        exchange: rawTicketData.exchange,
        listingId: rawTicketData.listingId,
      };

      sanitizedTickets.push(sanitizedTicket);
    }

    return sanitizedTickets;
  };

  return (
    <TicketsContext.Provider
      value={{
        fetchTicketsBatch,
        fetchExchanges,
        fetchUserTickets,
      }}
    >
      {children}
    </TicketsContext.Provider>
  );
};

export default Tickets;

import { useEffect, useState, ReactNode } from 'react';
import { useContractRead } from 'wagmi';
import { readContract } from '@wagmi/core';

import { esl, CALLER_ACCOUNT } from '@helpers/constants';
import { Abi, BuyerOrdersStore, DetailedOrder, Event, Listing, Order, Ticket } from '@helpers/types';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import useAccount from '@hooks/contexts/useAccount';
import useEvents from '@hooks/contexts/useEvents';
import useTickets from '@hooks/contexts/useTickets';

import BuyersContext from './BuyersContext';


interface ProvidersProps {
  children: ReactNode;
}

const Buyers = ({ children }: ProvidersProps) => {
  /*
   * Contexts
   */

  const { isLoggedIn, loggedInEthereumAddress } = useAccount();
  const { swapTicketExchangeAddress, swapTicketExchangeAbi } = useSmartContracts();
  const { fetchTicketsBatch } = useTickets();
  const { eventsByEventId } = useEvents();

  /*
   * State
   */

  const [buyerOrders, setBuyerOrders] = useState<Order[] | null>(null);

  const [buyerOrderListingIds, setBuyerOrderListingIds] = useState<bigint[] | null>(null);
  const [buyerOrderListings, setBuyerOrderListings] = useState<Listing[] | null>(null);

  const [buyerOrderTicketIds, setBuyerOrderTicketIds] = useState<string[] | null>(null);
  const [buyerOrderTickets, setBuyerOrderTickets] = useState<Ticket[] | null>(null);

  const [buyerOrdersStore, setBuyerOrdersStore] = useState<BuyerOrdersStore | null>(null);

  const [shouldFetchBuyerOrders, setShouldFetchBuyerOrders] = useState<boolean>(false);

  /*
   * Contract Reads
   */

  // function getUserListings(address _user) external view returns (ListingWithId[] memory listingsOutput) { 
  const {
    data: buyerOrdersRaw,
    refetch: refetchBuyerOrders,
  } = useContractRead({
    address: swapTicketExchangeAddress,
    abi: swapTicketExchangeAbi,
    functionName: 'getUserOrders',
    args: [
      loggedInEthereumAddress
    ],
    enabled: shouldFetchBuyerOrders
  });

  /*
   * Hooks
   */

  useEffect(() => {
    esl && console.log('shouldFetchBuyOrders_1');
    esl && console.log('checking isLoggedIn: ', isLoggedIn);
    esl && console.log('checking loggedInEthereumAddress: ', loggedInEthereumAddress);
    esl && console.log('checking swapTicketExchangeAddress: ', swapTicketExchangeAddress);

    if (isLoggedIn && loggedInEthereumAddress && swapTicketExchangeAddress) {
      esl && console.log('shouldFetchBuyOrders_2');

      setShouldFetchBuyerOrders(true);
    } else {
      esl && console.log('shouldFetchBuyOrders_3');

      setShouldFetchBuyerOrders(false);

      setBuyerOrders(null);
    }
  }, [isLoggedIn, loggedInEthereumAddress, swapTicketExchangeAddress]);
  
  useEffect(() => {
    esl && console.log('buyerOrders_1');
    esl && console.log('checking buyerOrders: ', buyerOrdersRaw);
  
    if (buyerOrdersRaw && buyerOrdersRaw.length > 0) {
      esl && console.log('buyerOrders_2');

      const sanitizedOrders = sanitizeRawOrders(buyerOrdersRaw);
  
      setBuyerOrders(sanitizedOrders.sanitizedOrders);

      setBuyerOrderTicketIds(sanitizedOrders.orderTicketIds);

      setBuyerOrderListingIds(sanitizedOrders.orderListingIds);
    } else {
      esl && console.log('buyerOrders_3');
  
      setBuyerOrders(null);

      setBuyerOrderTicketIds(null);

      setBuyerOrderListingIds(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerOrdersRaw]);

  useEffect(() => {
    esl && console.log('buyerOrderTickets_1');
    esl && console.log('checking buyerOrderTicketIds: ', buyerOrderTicketIds);
  
    if (buyerOrderTicketIds && buyerOrderTicketIds.length > 0 && fetchTicketsBatch) {
      esl && console.log('buyerOrderTickets_2');

      const fetchTickets = async () => {
        try {
          const sanitizedTickets = await fetchTicketsBatch(buyerOrderTicketIds);

          setBuyerOrderTickets(sanitizedTickets);
        } catch (error) {
          esl && console.log('buyerOrderTickets_3');
          
          setBuyerOrderTickets(null);
        }
      };
  
      fetchTickets();
    } else {
      esl && console.log('buyerOrderTickets_4');
  
      setBuyerOrderTickets(null);
    }
  }, [buyerOrderTicketIds, fetchTicketsBatch]);

  useEffect(() => {
    esl && console.log('buyerOrderListings_1');
    esl && console.log('checking buyerOrderListingIds: ', buyerOrderListingIds);
  
    if (buyerOrderListingIds && buyerOrderListingIds.length > 0) {
      esl && console.log('buyerOrderListings_2');

      const fetchListings = async () => {
        try {
          const sanitizedListings = await fetchingListingsBatch(buyerOrderListingIds);

          setBuyerOrderListings(sanitizedListings);
        } catch (error) {
          esl && console.log('buyerOrderListings_3');
          
          setBuyerOrderListings(null);
        }
      };
  
      fetchListings();
    } else {
      esl && console.log('buyerOrderListings_4');
  
      setBuyerOrderListings(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerOrderListingIds]);

  useEffect(() => {
    esl && console.log('buyerOrderStore_1');
    esl && console.log('checking buyerOrders: ', buyerOrders);
    esl && console.log('checking buyerOrderTickets: ', buyerOrderTickets); 
    esl && console.log('checking eventsByEventId: ', eventsByEventId);
  
    if (buyerOrders && buyerOrderTickets && buyerOrderListings && eventsByEventId) {
      esl && console.log('buyerOrderStore_2');

      // Events map for the events that have listings
      const filteredEventsByEventId: { [eventId: string]: Event } = {};

      // Map orders to DetailedOrder
      try {
        const detailedBuyerOrders: DetailedOrder[] = buyerOrders.map((order: Order) => {
          const filteredTickets = order.ticketIds.map(ticketId => buyerOrderTickets.find(ticket => ticket.ticketId === ticketId));
          const tickets = filteredTickets.filter((ticket): ticket is Ticket => ticket !== undefined);
  
          // TODO: investigate why casting to bigint is necessary
          const listing = buyerOrderListings.find(listing => listing.listingId === BigInt(order.listingId));
          if (!listing) {
            setBuyerOrdersStore(null);

            throw new Error(`Listing not found for order ${order.orderId}`);
          }
  
          // Populate filteredEventsByEventId
          const event = eventsByEventId[listing.eventId];
          if (!event) {
            setBuyerOrdersStore(null);
            
            throw new Error(`Event not found for listing ${listing.listingId}`);
          } else {
            if (!filteredEventsByEventId[listing.eventId]) {
              filteredEventsByEventId[listing.eventId] = event;
            }
          };
  
          return {
            ...order,
            tickets,
            listing
          };
        });

        const newStore: BuyerOrdersStore = {
          buyerOrders: detailedBuyerOrders,
          events: filteredEventsByEventId
        };
  
        setBuyerOrdersStore(newStore);
      } catch (error) {
        console.log('Error parsing buyerOrders: ', error);

        setBuyerOrdersStore(null);
      }
    } else {
      esl && console.log('buyerOrderStore_3');
  
      setBuyerOrdersStore(null);
    }
  }, [buyerOrders, buyerOrderTickets, buyerOrderListings, eventsByEventId]);

  /*
   * Helpers
   */

  interface SanitizedOrderResult {
    sanitizedOrders: Order[];
    orderTicketIds: string[];
    orderListingIds: bigint[];
  }

  const sanitizeRawOrders = (rawOrdersData: any[]): SanitizedOrderResult => {
    const sanitizedOrders: Order[] = [];
    const orderTicketIds: string[] = [];
    const orderListingIds: bigint[] = [];
  
    for (let i = rawOrdersData.length - 1; i >= 0; i--) {
      const orderWithIdData = rawOrdersData[i];
      const orderData = orderWithIdData.order;
      
      const order: Order = {
        orderId: orderWithIdData.orderId,
        buyer: orderData.buyer,
        encryptedEmail: orderData.encryptedEmail,
        emailHash: orderData.emailHash,
        listingId: orderData.listingId,
        transferId: orderData.transferId,
        ticketIds: orderData.ticketIds,
        purchaseValue: orderData.purchaseValue,
        createdAt: orderData.createdAt,
        refundInitiatedAt: orderData.refundInitiatedAt,
      };
  
      sanitizedOrders.push(order);

      orderTicketIds.push(...order.ticketIds);

      orderListingIds.push(order.listingId);
    }
  
    const uniqueTicketIds = [...new Set(orderTicketIds)];

    const uniqueListingIds = [...new Set(orderListingIds)];

    return {
      sanitizedOrders,
      orderTicketIds: uniqueTicketIds,
      orderListingIds: uniqueListingIds
    };
  };

  const fetchingListingsBatch = async (listingIdBatch: bigint[]) => {
    try {
      // function getListings(uint256[] memory _listingIds) external view returns (Listing[] memory listingInfo)
      const rawListingsData = await readContract({
        address: swapTicketExchangeAddress as `0x${string}`,
        abi: swapTicketExchangeAbi as Abi,
        functionName: 'getListings',
        args: [listingIdBatch],
        account: CALLER_ACCOUNT,
      });

      esl && console.error('rawListingsData:', rawListingsData);

      const sanitizedListings = sanitizeRawListings(rawListingsData as any[]);
      return sanitizedListings;
    } catch (error) {
      console.error('Error fetching listings batch:', error);
      
      return [];
    }
  };
  
  const sanitizeRawListings = (rawListingsData: any[]) => {
    const sanitizedListings: Listing[] = [];
  
    for (let i = rawListingsData.length - 1; i >= 0; i--) {
      const listingWithIdData = rawListingsData[i];
      const listingData = listingWithIdData.listing;
      
      const listing: Listing = {
        listingId: listingWithIdData.listingId,
        seller: listingData.seller.toString(),
        price: listingData.price,
        createdAt: listingData.createdAt,
        expiration: listingData.expiration,
        unfilledTicketIds: listingData.unfilledTickets,
        pendingTicketIds: listingData.pendingTickets,
        eventId: listingData.eventId,
        encryptionKey: listingData.encryptionKey.substring(2),
      };
  
      sanitizedListings.push(listing);
    }
  
    return sanitizedListings;
  };

  return (
    <BuyersContext.Provider
      value={{
        buyerOrdersStore,
        refetchBuyerOrders,
        shouldFetchBuyerOrders
      }}
    >
      {children}
    </BuyersContext.Provider>
  );
};

export default Buyers;

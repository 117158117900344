import axios from 'axios';

import { ethers } from 'ethers';
import { Interface, LogDescription } from 'ethers/lib/utils';
import { abi as swapTicketExchangeAbi } from './abi/swapTicketExchange.abi';

const NOTIFICATION_SERVICE_URL = process.env.NOTIFICATION_SERVICE_URL;
if (!NOTIFICATION_SERVICE_URL) {
  throw new Error("NOTIFICATION_SERVICE_URL environment variable is not defined.");
}

export const subscribeToBidNotifications = async (
  createListingTransactionReceipt: ethers.providers.TransactionReceipt,
  targetEmail: string
): Promise<boolean> => {
  try {
    const swapDomainExchangeAbiInterface = new Interface(swapTicketExchangeAbi);
    const parsedLogs = createListingTransactionReceipt.logs.map((log: ethers.providers.Log) => {
      try {
        return swapDomainExchangeAbiInterface.parseLog(log);
      } catch (error) {
        console.warn('Failed to parse log:', error);
        return null;
      }
    });
    const filteredLogs = parsedLogs.filter((parsedLog): parsedLog is LogDescription => parsedLog !== null);
    const ListingCreatedEvent = filteredLogs.find((log) => log.name === 'ListingCreated');

    if (ListingCreatedEvent && ListingCreatedEvent.args.listingId) {
      const listingId = ListingCreatedEvent.args.listingId.toString();

      await axios.post(`${NOTIFICATION_SERVICE_URL}/subscribe`, {
        listingId: listingId,
        email: targetEmail,
        market: 'tickets'
      });

      console.log('Notification subscription successful');
      return true;
    } else {
      console.error('ListingCreated event not found or listingId is missing');
      return false;
    }
  } catch (error) {
    console.error('Failed to subscribe to notifications:', error);
    return false;
  }
};

import React from 'react';
import styled from 'styled-components';
import Link from '@mui/material/Link';

import { colors } from '@theme/colors';
import { AccessoryButton } from '@components/common/AccessoryButton';


interface EventTableRowProps {
  venueText: string;
  eventText: string;
  startTimeText: string;
  eventUrl: string;
  ticketCount: number;
  rowIndex: number;
  handleSeeTicketsPressed: () => void;
}

export const EventTableRow: React.FC<EventTableRowProps> = ({
  venueText,
  eventText,
  startTimeText,
  eventUrl,
  ticketCount,
  rowIndex,
  handleSeeTicketsPressed
}: EventTableRowProps) => {
  EventTableRow.displayName = "EventTableRow";

  /*
   * Component
   */

  return (
    <Container>
      <EventDetailsContainer>
        <SummaryLabelValue>{rowIndex + 1}</SummaryLabelValue>

        <SummaryLabelValue>
            {eventText}
        </SummaryLabelValue>

        <VenueAndDateContainer>
          <SummaryLabelValue>
            {venueText}
          </SummaryLabelValue>

          <SummaryLabelValue>
            {startTimeText}
          </SummaryLabelValue>
        </VenueAndDateContainer>

        <AvailableTicketsLabel>{ticketCount}</AvailableTicketsLabel>

        <ActionsContainer>
          <AccessoryButton
            onClick={() => handleSeeTicketsPressed()}
            height={36}
            title={'See Tickets'}
          />
        </ActionsContainer>
      </EventDetailsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const EventDetailsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 6fr 4fr 2fr 3fr; 
  grid-gap: 4px;
  align-items: center;
  padding: 1rem 1.5rem 0.75rem 1.5rem;
  line-height: 24px;

  font-size: 15px;
  color: ${colors.darkText};
`;

const VenueAndDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SummaryLabelValue = styled.span`
`;

const AvailableTicketsLabel = styled.span`
  font-size: 16px;
  text-align: center;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;

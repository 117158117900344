import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Calendar } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import useEmblaCarousel from 'embla-carousel-react';

import { EventCarouselCard } from '@components/Browse/EventCarouselCard'; 
import { Event } from '@helpers/types';
import { formatEventDateTime } from '@helpers/dateFormat';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';


const CAROUSEL_SLIDE_TIMING = 5000;

export interface EventCardData {
  eventId: string;
  venueText: string;
  eventText: string;
  eventUrl: string;
  startTimeText: string;
}

interface EventCarouselProps {
  events: Event[];
}

export const EventCarousel: React.FC<EventCarouselProps> = ({
  events
}) => {
  /*
   * Contexts
   */

  const navigate = useNavigate();

  /*
   * State
   */

  const [loadedEvents, setLoadedEvents] = useState<Event[] | null>(null);
  const [eventCardData, setEventCardData] = useState<EventCardData[]>([]);

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, align: 'start' });
  const [isHovering, setIsHovering] = useState(false);

  /*
   * Hooks
   */

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    
    const startAutoScroll = () => {
      intervalId = setInterval(() => {
        emblaApi?.scrollNext();
      }, CAROUSEL_SLIDE_TIMING);
    };
  
    const stopAutoScroll = () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  
    if (emblaApi) {
      if (!isHovering) {
        startAutoScroll();
      } else {
        stopAutoScroll();
      }
    }
  
    return () => {
      stopAutoScroll();
    };
  }, [emblaApi, isHovering]);

  useEffect(() => {
    if (events) {
      setLoadedEvents(events);
    } else {
      setLoadedEvents(null);
    }
  }, [events]);

  useEffect(() => {
    if (loadedEvents && loadedEvents.length > 0) {
      const eventCardsData: EventCardData[] = loadedEvents.map((event: Event) => {
        const eventText = event.name;
        const startTimeText = formatEventDateTime(event.startTime);
        const venueText = event.venue;
        const eventUrl = event.eventUrl;

        return {
          eventId: event.id,
          eventText,
          venueText,
          startTimeText,
          eventUrl
        };
      });

      setEventCardData(eventCardsData);
    } else {
      setEventCardData([]);
    }
  }, [loadedEvents]);

  const handleMouseEnter = useCallback(() => {
    setIsHovering(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovering(false);
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    const emblaNode = emblaApi.rootNode();
    
    emblaNode.addEventListener('mouseenter', handleMouseEnter);
    emblaNode.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      emblaNode.removeEventListener('mouseenter', handleMouseEnter);
      emblaNode.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [emblaApi, handleMouseEnter, handleMouseLeave]);

  /*
   * Handlers
   */

  const handleEventCardClick = (index: number) => {
    if (loadedEvents) {
      const eventId = loadedEvents[index].id;

      navigate(`/browse/${eventId}`);
    }
  };
  
  /*
   * Component
   */

  return (
    <Container>
      {eventCardData.length === 0 ? (
        <ErrorContainer>
          <ThemedText.DeprecatedBody textAlign="center">
            <CalendarIcon strokeWidth={1} style={{ marginTop: '2em' }} />
            <div>
              Loading events...
            </div>
          </ThemedText.DeprecatedBody>
        </ErrorContainer>
      ) : (
        <Content
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <ViewPort ref={emblaRef}>
            <CarouselSlide>
              {eventCardData.map((eventCardData, index) => (
                <StyledEventCard
                  key={index}
                >
                  <EventCarouselCard
                    cardIndex={index}
                    venueText={eventCardData.venueText}
                    eventText={eventCardData.eventText}
                    startTimeText={eventCardData.startTimeText}
                    onCardClick={() => handleEventCardClick(index)}
                  />
                </StyledEventCard>
              ))}
            </CarouselSlide>
          </ViewPort>
        </Content>
      )}
    </Container>
  )
};

const Container = styled.div`
  width: 100vw;
  padding: 1rem 0rem 2.5rem 0rem;
`;

const ErrorContainer = styled.div`
  background-color: ${colors.container};
  overflow: hidden;
  position: relative;

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 36px;
  min-height: 12vh;
  gap: 36px;
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const CalendarIcon = styled(Calendar)`
  ${IconStyle}
`;

const Content = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const ViewPort = styled.div`
  overflow: hidden;
`;

const CarouselSlide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #1D1F22;
`;

const StyledEventCard = styled.div`
  flex: 0 0 100%;
`;

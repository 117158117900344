import { createContext } from 'react';

import { Abi } from '@helpers/types';


interface SmartContractsValues {
  blockscanUrl: string | null;
  usdcAddress: string | null;
  usdcAbi: Abi | null;

  verifiedTicketRegistryAbi: Abi | null;
  swapTicketExchangeAbi: Abi | null;
  eventRegistryAbi: Abi | null;

  verifiedTicketRegistryAddress: string | null;
  swapTicketExchangeAddress: string | null;
  eventRegistryAddress: string | null;

  // Socket
  socketBridgeAddress: string | null;
  
  // Lifi
  lifiBridgeAddress: string | null;
}

const defaultValues: SmartContractsValues = {
  blockscanUrl: null,
  usdcAddress: null,
  usdcAbi: null,

  verifiedTicketRegistryAbi: null,
  swapTicketExchangeAbi: null,
  eventRegistryAbi: null,

  verifiedTicketRegistryAddress: null,
  swapTicketExchangeAddress: null,
  eventRegistryAddress: null,

  // Socket
  socketBridgeAddress: null,
  
  // Lifi
  lifiBridgeAddress: null,
};

const SmartContractsContext = createContext<SmartContractsValues>(defaultValues)

export default SmartContractsContext

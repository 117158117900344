import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import { Proof } from '@helpers/types';
import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useInitiateSwap(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { swapTicketExchangeAddress, swapTicketExchangeAbi } = useSmartContracts();

  /*
   * State
   */ 

  const [orderIdInput, setOrderIdInput] = useState<bigint | null>(null);
  const [transferProofInput, setTransferProofInput] = useState<Proof | null>(null);

  const [shouldConfigureInitiateSwapWrite, setShouldConfigureInitiateSwapWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  // * @param _orderId       Id of the order being initialized
  // * @param _proof         Proof of ticket transfer and acceptance

  const { config: writeInitiateSwapConfig } = usePrepareContractWrite({
    address: swapTicketExchangeAddress,
    abi: swapTicketExchangeAbi,
    functionName: 'initiateSwap',
    args: [
      orderIdInput,
      transferProofInput,
    ],
    onError(error: any) {
      console.log('writeInitiateSwapConfig failed: ', error.message);

      setShouldConfigureInitiateSwapWrite(false);

      onError?.(error);
    },
    enabled: shouldConfigureInitiateSwapWrite,
  });

  const {
    data: submitInitiateSwapResult,
    status: signInitiateSwapTransactionStatus,
    writeAsync: writeInitiateSwapAsync,
  } = useContractWrite(writeInitiateSwapConfig);

  const {
    status: mineInitiateSwapTransactionStatus,
  } = useWaitForTransaction({
    hash: submitInitiateSwapResult ? submitInitiateSwapResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeInitiateSwapAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeInitiateSwapAsync,
    orderIdInput,
    transferProofInput,
    setOrderIdInput,
    setTransferProofInput,
    setShouldConfigureInitiateSwapWrite,
    signInitiateSwapTransactionStatus,
    mineInitiateSwapTransactionStatus,
    transactionHash: submitInitiateSwapResult?.hash,
  };
};

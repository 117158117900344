import React from 'react';
import styled from 'styled-components';

import { colors } from '@theme/colors';
import useMediaQuery from '@hooks/useMediaQuery';


interface ListingRowProps {
  sectionText: string;
  rowText: string;
  seatText: string;
  expirationText: string;
  ticketPriceText: string;
  rowIndex: number;
  lastRow: boolean;
  onRowClick: () => void;
};

export const ListingRow: React.FC<ListingRowProps> = ({
  sectionText,
  rowText,
  seatText,
  ticketPriceText,
  onRowClick,
  lastRow
}: ListingRowProps) => {
  ListingRow.displayName = "ListingRow";

  const isMobile = useMediaQuery() === 'mobile';

  return (
    <Container onClick={onRowClick}>
      <ListingDetailsContainer isMobile={isMobile} lastRow={lastRow}>
        <SeatDetailsContainer>
          <SectionContainer>
            <SummaryLabelValue>
              {sectionText}
            </SummaryLabelValue>
          </SectionContainer>

          <RowAndSeatsContainer>
            <SummaryLabelValue>
              {rowText}
            </SummaryLabelValue>
          </RowAndSeatsContainer>

          <RowAndSeatsContainer>
            <SummaryLabelValue>
              {seatText}
            </SummaryLabelValue>
          </RowAndSeatsContainer>
        </SeatDetailsContainer>

        <PriceDetailsContainer>
          <PriceLabel>
            {ticketPriceText}
          </PriceLabel>
        </PriceDetailsContainer>
      </ListingDetailsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;

  background-color: ${colors.background};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #F0F5FA;
  }
`;

const ListingDetailsContainer = styled.div<{isMobile?: boolean, lastRow: boolean}>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px;
  align-items: center;
  padding: 1rem 0.5rem 1rem 1.5rem;
  border-bottom: ${({ lastRow }) => lastRow ? 'none' : `1px solid ${colors.defaultBorderColor}`};
`;

const SeatDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

const PriceDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PriceLabel = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
  text-align: center;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowAndSeatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SummaryLabelValue = styled.span`
  font-size: 15px;
  text-align: center;
  color: ${colors.darkText};
`;

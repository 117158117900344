import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ListingTable } from '@components/Event/ListingTable';
import { SeatMap } from '@components/Event/SeatMap';
import { BuyModal } from '@components/Event/BuyModal';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { DetailedListing, Event } from '@helpers/types';
import { formatEventDateTimeShort } from '@helpers/dateFormat';
import useEvents from '@hooks/contexts/useEvents';
import useListings from '@hooks/contexts/useListings';
import useQuery from '@hooks/useQuery';


export interface EventContentProps {
  eventId: string | undefined;
};

export const EventContent: React.FC<EventContentProps> = ({
  eventId
}) => {

  const { queryParams } = useQuery();
  const listingIdFromQuery: BigInt | null = queryParams.LISTING_ID ? BigInt(queryParams.LISTING_ID) : null;

  /*
   * Contexts
   */

  const { activeListingsStore } = useListings();
  const { eventsByEventId } = useEvents();

  /*
   * State
   */

  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [selectedEventListings, setSelectedEventListings] = useState<DetailedListing[] | null>(null);
  const [selectedEventListingSections, setSelectedEventListingSections] = useState<string[]>([]);

  const [selectedListing, setSelectedListing] = useState<DetailedListing | null>(null);

  const [shouldShowBuyModal, setShouldShowBuyModal] = useState<boolean>(false);

  /*
   * Hooks
   */

  useEffect(() => {
    if (eventsByEventId && eventId) {
      setSelectedEvent(eventsByEventId[eventId]);
    } else {
      setSelectedEvent(null);
    }
  }, [eventsByEventId, eventId]);

  useEffect(() => {
    if (activeListingsStore && activeListingsStore.length > 0 && eventId) {
      const listingsForSelectedEvent: DetailedListing[] = activeListingsStore.filter(
        (detailedListing) => detailedListing.eventId === eventId
      );

      const listingsWithTickets = listingsForSelectedEvent.filter(
        (listing) => listing.unfilledTickets.length > 0
      ); 

      setSelectedEventListings(listingsWithTickets);
    } else {
      setSelectedEventListings([]);
    }
  }, [activeListingsStore, eventId]);

  useEffect(() => {
    console.log("listingIdFromQuery", listingIdFromQuery);
    console.log("selectedEventListings", selectedEventListings);
    if (listingIdFromQuery && selectedEventListings) {
      const listing = selectedEventListings.find(listing => listing.listingId === listingIdFromQuery);
      console.log("listing", listing);
      if (listing) {
        setSelectedListing(listing);
        setShouldShowBuyModal(true);
      } else {
        setSelectedListing(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingIdFromQuery, selectedEventListings]);

  useEffect(() => {
    if (selectedEventListings) {
      const unfilledTicketsOnEventsListings = selectedEventListings.map(listing => listing.unfilledTickets).flat();

      const sectionsOnTickets = Array.from(new Set(unfilledTicketsOnEventsListings.map(ticket => ticket.section)));

      setSelectedEventListingSections(sectionsOnTickets);
    } else {
      setSelectedEventListingSections([]);
    }
  }, [selectedEventListings]);

  /*
   * Handlers
   */

  const handleBuyModalClose = () => {
    setShouldShowBuyModal(false);
  };

  const handleListingPressedAtIndex = (listingRowIndex: number) => {
    if (!selectedEventListings) {
      setShouldShowBuyModal(false);

      return;
    };

    const listing = selectedEventListings[listingRowIndex];

    setSelectedListing(listing);

    setShouldShowBuyModal(true);
  };

  /*
   * Component
   */

  return (
    <Container>
      {shouldShowBuyModal && selectedListing && selectedEvent && (
        <BuyModal
          listing={selectedListing}
          onBackClick={handleBuyModalClose}
        />
      )}

      <TitleRow>
        <ThemedText.BodyPrimary>
          <EventPageLink to="/">Back to Browse</EventPageLink>
        </ThemedText.BodyPrimary>
        
        <ThemedText.HeadlineMedium>
          {selectedEvent?.name}
        </ThemedText.HeadlineMedium>

        <ThemedText.BodyPrimary>
          {selectedEvent?.startTime ? formatEventDateTimeShort(selectedEvent.startTime) : 'Start Time'}
        </ThemedText.BodyPrimary>

        <ThemedText.BodyPrimary>
          {selectedEvent?.venue ? selectedEvent.venue : 'Venue'}
        </ThemedText.BodyPrimary>

        <ThemedText.BodyPrimary>
          <EventPageLink to={selectedEvent?.eventUrl ?? ''} target="_blank">View Official Event Page ↗</EventPageLink>
        </ThemedText.BodyPrimary>
      </TitleRow>

      <ContentContainer>
        <SeatMap
          arenaName={selectedEvent?.venue}
          sections={selectedEventListingSections}
          eventUrl={selectedEvent?.eventUrl}
        />

        <VerticalDivider />

        <ListingTable
          listingsForSelectedEvent={selectedEventListings}
          handleListingPressed={handleListingPressedAtIndex}
        />
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 16px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  overflow: hidden;
  background-color: ${colors.white};
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${colors.white};
  padding: 1.5rem 3rem; 
  gap: 0.5rem;

  background-color: ${colors.buttonDefault};

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    padding: 0 1.75rem;
  };
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 6fr min-content 3fr;
  grid-gap: 2rem;
  padding: 0.5rem 2rem 2rem 2rem;
  align-items: center;
`;

const VerticalDivider = styled.div`
  height: 10%;
  width: 6px;
  border-radius: 6px;
  background-color: ${colors.buttonDefault};
`;

const EventPageLink = styled(Link)`
  color: ${colors.white};
  // if visited still make color white
  &:visited {
    color: ${colors.white};
  }
`;

/**
 * Add opacity information to a hex color
 * @param amount opacity value from 0 to 100
 * @param hexColor
 */
export function opacify(amount: number, hexColor: string): string {
  if (!hexColor.startsWith('#')) {
    return hexColor;
  };

  if (hexColor.length !== 7) {
    throw new Error(`opacify: provided color ${hexColor} was not in hexadecimal format (e.g. #000000)`);
  };

  if (amount < 0 || amount > 100) {
    throw new Error('opacify: provided amount should be between 0 and 100');
  };

  const opacityHex = Math.round((amount / 100) * 255).toString(16);
  const opacifySuffix = opacityHex.length < 2 ? `0${opacityHex}` : opacityHex;

  return `${hexColor.slice(0, 7)}${opacifySuffix}`;
};

export const colors = {
  heartRed: '#DF2E2D',
  warningRed: '#DF2E2D',
  
  themeGray: '#0E111C',

  darkText: '#333333',
  grayText: '#525252',
  lightGrayText: '#F8F9FA',

  white: '#FFFFFF',
  black: '#000000',
  offWhite: '#9CA3AA',

  buttonDefault: '#0066CC',
  buttonHover: '#0052CC',
  buttonDisabled: '#99ccff',

  container: '#FFFFFF',
  background: '#FAF9F6',

  environmentBannerColor: '#0066CC',

  defaultBorderColor: '#C0C0C0',
  focusBorderColor: '#808080',
  readOnlyBorderColor: '#98A1C03D',

  inputDefaultColor: '#FAF9F6',
  inputReadOnlyValueColor: '#505458',
  inputPlaceholderColor: '#999999',

  selectorColor: '#FAF9F6',
  selectorHover: '#F2F1ED',
  selectorHoverBorder: 'rgba(255, 255, 255, 0.1)',

  orderSummaryGreen: '#4BB543',

  proofFoundGreen: '#4BB543',
  proofMissingRed: '#DF2E2D',

  defaultSVGIconBackgroundColor: '#FAF9F6',

  paginationDefault: 'rgba(0, 0, 0, 0.5)',
  paginationHover: 'rgba(0, 0, 0, 0.8)',
  paginationDisabled: 'rgba(0, 0, 0, 0.2)',

  iconButtonDefault: '#F8F9FA',
  iconButtonHover: '#E9ECEF',
  iconButtonIconHover: '#0066CC',

  orderStatusWaitingTransfer: '#0066CC', // Same button blue
  orderStatusWaitingToAccept: '#FF9A00', // Orange
  orderStatusPendingRefund: '#FF0000', // Red
  // gloabl background: #FAF9F6
  // global color: #333333
  // box border: #C0C0C0
  // primary color: #0066CC
  // darker primary color: 
  // accent color: #32CD32
  // muted accent color: #D2EDD2

  //
  // Properly labeled colors
  //

  iconDefault: '#333333',
  accessoryButtonIconDefault: '#FFFFFF',

  chevronDown: '#CED4DA',

  labelValue: '#333333',
  labelName: '#333333',

  logoutButtonDefaultRed: '#E96069',

  dropdownItemHover: '#6C757D',
  dropdownLogoutHover: '#CA2221',

  cardTitleText: '#0066CC',
  cardCTAText: '#1F7A1F',

  selectedRowColor: '#F0F5FA',
};

export const gradientOptions = [
  {
    linear: [
      { offset: "0%", color: "#3182ce" },
      { offset: "14.29%", color: "#348fc5" },
      { offset: "28.57%", color: "#389ac2" },
      { offset: "42.86%", color: "#3ca5c1" },
      { offset: "57.14%", color: "#41b0c2" },
      { offset: "71.43%", color: "#45bbc3" },
      { offset: "100%", color: "#4fd1c5" }
    ],
    radial: [
      { offset: "0%", color: "#feb2b2" },
      { offset: "60%", color: "rgba(252, 184, 219, 0.75)" },
      { offset: "80%", color: "rgba(239, 194, 250, 0.5)" },
      { offset: "100%", color: "rgba(190, 227, 248, 0)" }
    ]
  },
  {
    linear: [
      { offset: "0%", color: "#6b46c1" },
      { offset: "25%", color: "#805ad5" },
      { offset: "50%", color: "#9f7aea" },
      { offset: "75%", color: "#b794f4" },
      { offset: "100%", color: "#d6bcfa" }
    ],
    radial: [
      { offset: "0%", color: "#fbd38d" },
      { offset: "50%", color: "rgba(246, 173, 85, 0.75)" },
      { offset: "75%", color: "rgba(237, 137, 54, 0.5)" },
      { offset: "100%", color: "rgba(221, 107, 32, 0)" }
    ]
  },
  {
    linear: [
      { offset: "0%", color: "#2c7a7b" },
      { offset: "25%", color: "#38b2ac" },
      { offset: "50%", color: "#4fd1c5" },
      { offset: "75%", color: "#81e6d9" },
      { offset: "100%", color: "#b2f5ea" }
    ],
    radial: [
      { offset: "0%", color: "#fbb6ce" },
      { offset: "50%", color: "rgba(246, 135, 179, 0.75)" },
      { offset: "75%", color: "rgba(237, 100, 166, 0.5)" },
      { offset: "100%", color: "rgba(213, 63, 140, 0)" }
    ]
  },
  {
    linear: [
      { offset: "0%", color: "#744210" },
      { offset: "25%", color: "#975a16" },
      { offset: "50%", color: "#b7791f" },
      { offset: "75%", color: "#d69e2e" },
      { offset: "100%", color: "#f6e05e" }
    ],
    radial: [
      { offset: "0%", color: "#9ae6b4" },
      { offset: "50%", color: "rgba(72, 187, 120, 0.75)" },
      { offset: "75%", color: "rgba(56, 161, 105, 0.5)" },
      { offset: "100%", color: "rgba(47, 133, 90, 0)" }
    ]
  },
  {
    linear: [
      { offset: "0%", color: "#2a4365" },
      { offset: "25%", color: "#3182ce" },
      { offset: "50%", color: "#4299e1" },
      { offset: "75%", color: "#63b3ed" },
      { offset: "100%", color: "#90cdf4" }
    ],
    radial: [
      { offset: "0%", color: "#faf089" },
      { offset: "50%", color: "rgba(236, 201, 75, 0.75)" },
      { offset: "75%", color: "rgba(215, 169, 49, 0.5)" },
      { offset: "100%", color: "rgba(183, 121, 31, 0)" }
    ]
  }
];
import { useState } from 'react';


const NOTARY_LIST_URL = 'https://raw.githubusercontent.com/zkp2p/venue-list/main/';

type ImageInfo = {
  height: number;
  type: string;
  url: string;
  width: number;
  x: number;
  y: number;
};

interface Shape {
  bounds: string[];
  filled: boolean;
  labels: Label[];
  mode: string;
  path: string;
}

interface Label {
  angle: number;
  height: number;
  size: number;
  styleId: string;
  text: string;
  width: number;
  x: number;
  y: number;
}

type Segment = {
  name: string;
  shapes: Shape[];
};

type SVGData = {
  height: number;
  image: ImageInfo;
  segments: Segment[];
};

export type FetchVenueDataResponse = {
  img_link: string;
  svg_data: SVGData;
};

type FetchVenueListError = {
  code: number;
};

function parseJsonFile(jsonData: any): any {
  try {
    const svgData = jsonData["pages"][0]["segments"].map((segment: any) => ({
      name: segment["segments"][0]["name"],
      shapes: segment["shapes"]
    }));
    
    return {
      height: jsonData["pages"][0]["height"],
      image: jsonData["pages"][0]["images"][0],
      segments: svgData,
    }
  } catch (error) {
    console.error('Error parsing JSON file:', error);
    process.exit(1);
  }
}

export default function useFetchVenueData() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<FetchVenueListError | null>(null);

  const fetchVenueData = async (eventId: string): Promise<FetchVenueDataResponse | null> => {
    setLoading(true);

    setError(null);

    try {
      const response = await fetch(`https://mapsapi.tmol.io/maps/geometry/3/event/${eventId}?useHostGrids=true&app=PRD2663_EDP_NA&sectionLevel=true&systemId=HOST`, {
        method: 'GET',
      });

      if (response.ok) {
        const result = await response.json();
        const parsedResult = parseJsonFile(result);

        setLoading(false);

        return {
          img_link: `https://mapsapi.tmol.io/maps/geometry/image/${parsedResult.image.url}`,
          svg_data: parsedResult,
         };
      } else {
        setError({ code: response.status });

        setLoading(false);

        return null;
      }
    } catch (err) {
      setError({ code: 0 });

      setLoading(false);

      return null;
    }
  };

  return { loading, error, fetchVenueData };
};

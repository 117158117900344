import { Event } from '@helpers/types'; 

// On the contract side, Tickets are returned zipped with their IDs
//
// enum TicketStatus {
//   Blank,
//   Verified,
//   Listed,
//   Sold
// }
//
// struct Ticket {
//   address owner;          // Owner of the ticket
//   string rawId;           // Raw ID of the ticket on Ticketmaster
//   string section;         // Section of the ticket
//   string row;             // Row of the ticket
//   string seat;            // Seat of the ticket
//   string status;          // Status of the ticket
//   bytes32 eventId;        // Event ID for the ticket in the EventRegistry
//   address exchange;       // Exchange address the ticket is listed on
//   uint256 listingId;      // Listing ID for the ticket on the exchange
// }
//
//          +
//
// struct TicketWithId {
//   bytes32 ticketId;
//   Ticket ticket;
// }

export interface Ticket {
  ticketId: string;
  owner: string;
  ticketmasterId: string;
  section: string;
  row: string;
  seat: string;
  status: TicketStatus;
  eventId: string;
  exchange: string;
  listingId: bigint;
};

export enum TicketStatus {
  Blank = 0,
  Verified = 1,
  Listed = 2,
  Sold = 3
};
export type TicketStatusString = 'blank' | 'verified' | 'listed' | 'sold';
export const ticketStatusToString: Record<TicketStatus, TicketStatusString> = {
  [TicketStatus.Blank]: 'blank',
  [TicketStatus.Verified]: 'verified',
  [TicketStatus.Listed]: 'listed',
  [TicketStatus.Sold]: 'sold'
};

//
// Hydrated with Event
//

export interface DetailedTicket extends Ticket {
  event: Event;
};

export interface TicketStub {
  section: string;
  row: string;
  seat: string;
}
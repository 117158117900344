import { createContext } from 'react';

import { ExtensionNotaryProofRequest } from '@hooks/useBrowserExtension';


interface ExtensionProxyProofsValues {
  isSidebarInstalled: boolean;
  sideBarVersion: string | null;
  refetchExtensionVersion: () => void;

  openSidebarVerifyTickets: () => void;
  refetchVerifiedTicketProofs: () => void;
  verifiedTicketProofs: ExtensionNotaryProofRequest[] | null;

  openSidebarInitializeTransfer: () => void;
  refetchInitializeTransferProofs: () => void;
  initializeTransferProofs: ExtensionNotaryProofRequest[] | null;

  openSidebarTransfer: () => void;
  refetchTransferProofs: () => void;
  transferProofs: ExtensionNotaryProofRequest[] | null;
};

const defaultValues: ExtensionProxyProofsValues = {
  isSidebarInstalled: false,
  sideBarVersion: null,
  refetchExtensionVersion: () => {},

  openSidebarInitializeTransfer: () => {},
  refetchInitializeTransferProofs: () => {},
  initializeTransferProofs: null,

  openSidebarVerifyTickets: () => {},
  refetchVerifiedTicketProofs: () => {},
  verifiedTicketProofs: null,

  openSidebarTransfer: () => {},
  refetchTransferProofs: () => {},
  transferProofs: null,
};

const ExtensionNotarizationsContext = createContext<ExtensionProxyProofsValues>(defaultValues);

export default ExtensionNotarizationsContext;

import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { ChevronDown } from 'react-feather';

import { SummaryItem } from '@components/Listings/NewListing/SummaryItem';
import { colors } from '@theme/colors';


interface SummaryProps {
  isLoading: boolean;
  pricePerTicket: string;
  ticketCount: number;
  listingFee: number;
};

export const Summary: React.FC<SummaryProps> = ({
  isLoading,
  pricePerTicket,
  ticketCount,
  listingFee,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  /*
   * State
   */

  const [isOpen, setIsOpen] = useState(true);

  /*
   * Hooks
   */

  useEffect(() => {
    if (isLoading) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [isLoading]);

  /*
   * Helpers
   */

  const ticketsListedForString = () => {
    if (ticketCount === 0 || pricePerTicket === '') {
      return 'Loading...';
    } else if (ticketCount === 1) {
      return `$${pricePerTicket}`;
    } else {
      return `$${pricePerTicket} x ${ticketCount}`;
    }
  };
  
  const serviceFeeString = () => {
    if (ticketCount === 0 || pricePerTicket === '') {
      return 'Loading...';
    } else if (ticketCount === 1) {
      return `$${serviceFeePerTicket.toFixed(2)}`;
    } else {
      return `$${serviceFeePerTicket.toFixed(2)} x ${ticketCount}`;
    }
  };

  const payoutPerTicketString = () => {
    if (ticketCount === 0 || pricePerTicket === '') {
      return 'Loading...';
    } else if (ticketCount === 1) {
      return `$${payoutPerTicket}`;
    } else {
      return `$${payoutPerTicket} x ${ticketCount}`;
    }
  };

  const earningsString = () => {
    if (ticketCount === 0 || pricePerTicket === '') {
      return 'Loading...';
    } else {
      return `$${earnings}`;
    }
  };

  const youMakeString = () => {
    if (ticketCount === 0 || pricePerTicket === '') {
      return 'Loading...';
    } else {
      return `$${savings}`;
    }
  };

  const helperString = () => {
    return [
      `A buyer paying $${pricePerTicket} on other sites:`,
      `Implies listing price (15% buy fees): $${impliedListingPrice.toFixed(2)}`,
      `Implies seller earning (10% sell fees): $${impliedEarnings.toFixed(2)}`,
      `Total extra earnings with ZKP2P: $${savings}`
    ].join('\n');
  };

  const serviceFeePerTicket = listingFee * Number(pricePerTicket);
  const payoutPerTicket = Number(pricePerTicket) - serviceFeePerTicket;
  const earnings = payoutPerTicket * ticketCount;
  const impliedListingPrice = Number(pricePerTicket) / 1.15;
  const impliedEarnings = impliedListingPrice * 0.9;
  const savings = ((payoutPerTicket - impliedEarnings) * ticketCount).toFixed(2);

  /*
   * Component
   */

  return (
    <Wrapper ref={ref}>
      <TitleLabelAndDropdownIconContainer>
        <TitleLabel>
          {"Listing Summary"}
        </TitleLabel>

        <TitleValueAndChevronContainer>
          <TitleValue>
            
          </TitleValue>  

          <StyledChevronDown
            onClick={() => setIsOpen(!isOpen)}
            $isOpen={isOpen}
          />
        </TitleValueAndChevronContainer>
      </TitleLabelAndDropdownIconContainer>

      <SummaryDropdown $isOpen={isOpen}>
        <HorizontalDivider/>

        <RequirementListContainer>
          <SummaryItem
            label={"Tickets listed for"}
            value={ticketsListedForString()}
          />

          <SummaryItem 
            label={"ZKP2P Service fees"}
            value={serviceFeeString()}
          />

          <SummaryItem 
            label={"Payout per ticket"}
            value={payoutPerTicketString()}
          />

          <SummaryItem 
            label={"What you will earn"}
            value={earningsString()}
          />

          <SummaryItem 
            label={"Extra earnings with ZKP2P"}
            value={youMakeString()}
            helperText={helperString()}
            colorOverride={colors.orderSummaryGreen}
          />
        </RequirementListContainer>
      </SummaryDropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  border-radius: 16px;
  border: 1px solid ${colors.defaultBorderColor};
  background: ${colors.container};
`;

const TitleLabelAndDropdownIconContainer = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px 10px;
`;

const TitleLabel = styled.div`
  font-size: 14px;
`;

const TitleValueAndChevronContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  gap: 8px;
`;

const TitleValue = styled.div`
  font-size: 15px;
`;

interface StyledChevronDownProps {
  $isOpen?: boolean;
};

const StyledChevronDown = styled(ChevronDown)<StyledChevronDownProps>`
  width: 20px;
  height: 20px;
  color: ${colors.chevronDown};

  transition: transform 0.4s;
  transform: ${({ $isOpen }) => $isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const HorizontalDivider = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.defaultBorderColor};
`;

interface SummaryDropdownProps {
  $isOpen?: boolean;
};

const SummaryDropdown = styled.div<SummaryDropdownProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${colors.container};
  color: ${colors.darkText};
  align-items: center;
  gap: 16px;
  overflow: hidden;

  max-height: ${({ $isOpen }) => $isOpen ? '500px' : '0px'};
  transition: max-height 0.4s ease-out;
`;

const RequirementListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;
`;

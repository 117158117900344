import React, { useEffect, useState } from 'react';
import { Tag } from 'react-feather';
import styled, { css } from 'styled-components/macro';

import { RowBetween } from '@components/layouts/Row';
import { ListingRow } from '@components/Event/ListingRow'; 
import { DetailedListing} from '@helpers/types';
import { formatTicketSummaries } from '@helpers/ticketFormat';
import { formatEventDateTimeShort } from '@helpers/dateFormat';
import { toUsdcString } from '@helpers/units';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';


export interface ListingRowData {
  sectionText: string;
  rowText: string;
  seatText: string;
  expirationText: string;
  priceText: string;
};

interface ListingTableProps {
  listingsForSelectedEvent: DetailedListing[] | null;
  handleListingPressed: (listingRowIndex: number) => void;
};

export const ListingTable: React.FC<ListingTableProps> = ({
  listingsForSelectedEvent,
  handleListingPressed
}) => {
  /*
   * State
   */

  const [listingRowData, setListingRowData] = useState<ListingRowData[]>([]);

  /*
   * Hooks
   */

  useEffect(() => {
    if (listingsForSelectedEvent && listingsForSelectedEvent.length > 0) {
      var listingRowsData: ListingRowData[] = [];
      listingsForSelectedEvent.forEach((detailedListing: DetailedListing) => {
        const unfulfilledTickets = detailedListing.unfilledTickets;
        if (unfulfilledTickets.length === 0) {
          return;
        }

        const pendingTickets = detailedListing.pendingTickets;
        const ticketsOnListing = [...unfulfilledTickets, ...pendingTickets];
        const ticketSeatSummaries = formatTicketSummaries(ticketsOnListing);

        const ticketPriceText = `${toUsdcString(detailedListing.price)} USDC`;

        const expirationText = formatEventDateTimeShort(detailedListing.expiration);
        
        listingRowsData.push({
          sectionText: ticketSeatSummaries.sectionSummary,
          rowText: ticketSeatSummaries.rowSummary,
          seatText: ticketSeatSummaries.seatSummary,
          expirationText: expirationText,
          priceText: ticketPriceText,
        });
      });

      setListingRowData(listingRowsData);
    } else {
      setListingRowData([]);
    }
  }, [listingsForSelectedEvent]);
  
  /*
   * Component
   */

  return (
    <Container>
      <TitleRow>
        <ThemedText.HeadlineSmall>
          Listings
        </ThemedText.HeadlineSmall>
      </TitleRow>

      <Content>
        {listingRowData.length === 0 ? (
          <ErrorContainer>
            <TagIcon strokeWidth={1} />

            <ThemedText.DeprecatedBody>
                No active listings for this event.
            </ThemedText.DeprecatedBody>
          </ErrorContainer>
        ) : (
          <TableContainer>
            <TableHeaderRow>
              <ColumnHeader>Seats</ColumnHeader>

              <PriceColumnHeader>Price per Ticket</PriceColumnHeader>
            </TableHeaderRow>
            
            <Table>
              {listingRowData.map((listingRow, rowIndex) => (
                <StyledListingRow key={rowIndex}>
                  <ListingRow
                    sectionText={listingRow.sectionText}
                    rowText={listingRow.rowText}
                    seatText={listingRow.seatText}
                    expirationText={listingRow.expirationText}
                    ticketPriceText={listingRow.priceText}
                    rowIndex={rowIndex}
                    onRowClick={() => handleListingPressed(rowIndex)}
                    lastRow={listingRowData.length === rowIndex + 1}
                  />
                </StyledListingRow>
              ))}
            </Table>
          </TableContainer>
        )}
      </Content>
    </Container>
  )
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
`;

const TitleRow = styled(RowBetween)`
  align-items: flex-end;
  color: ${colors.darkText};
  padding: 1rem;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    padding: 0 1.75rem;
  };
`;

const Content = styled.div`
  height: 100%;
  background-color: ${colors.container};
  overflow: hidden;
  position: relative;

  @media (min-width: 600px) {
    border-radius: 16px;
  }
`;

const ErrorContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.background};
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const TagIcon = styled(Tag)`
  ${IconStyle}
`;

const TableContainer = styled.div`
`;

const TableHeaderRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px;
  padding: 1rem 0.5rem 0.75rem 1.5rem;
  text-align: left;
  color: ${colors.white};
  background-color: ${colors.buttonDefault};
`;

const ColumnHeader = styled.div`
  text-align: left;
  font-size: 14px;
  font-weight: 600;
`;

const PriceColumnHeader = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 600;
`;

const Table = styled.div`
  max-height: 493px;
  overflow-y: auto;
  font-size: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  & > * {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${colors.defaultBorderColor};
    }
  }

  & > *:last-child::after {
    display: none;
  }
`;

const StyledListingRow = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;
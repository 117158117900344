import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useBuyListingTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { swapTicketExchangeAddress, swapTicketExchangeAbi } = useSmartContracts();

  /*
   * State
   */

  const [listingIdInput, setListingIdInput] = useState<bigint | null>(null); 
  const [maxPriceInput, setMaxPriceInput] = useState<bigint | null>(null); 
  const [ticketIdsInput, setTicketIdsInput] = useState<string[]>([]);
  const [emailHashInput, setEmailHashInput] = useState<string>('');
  const [encryptedEmailInput, setEncryptedEmailInput] = useState<string>('');

  const [shouldConfigureBuyListingWrite, setShouldConfigureBuyListingWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  // function buyListing(
  //   uint256 _listingId,
  //   uint256 _maxPrice,
  //   bytes32[] memory _ticketIds,
  //   string memory _emailHash,
  //   string memory _encryptedEmail
  // )

  const { config: writeBuyListingConfig } = usePrepareContractWrite({
    address: swapTicketExchangeAddress,
    abi: swapTicketExchangeAbi,
    functionName: 'buyListing',
    args: [
      listingIdInput,
      maxPriceInput ?? 0n,
      ticketIdsInput,
      emailHashInput,
      encryptedEmailInput,
    ],
    onError(error: any) {
      console.log('writeBuyListingConfig failed: ', error.message);

      setShouldConfigureBuyListingWrite(false);

      onError?.(error);
    },
    enabled: shouldConfigureBuyListingWrite,
  });

  const {
    data: submitBuyListingResult,
    status: signBuyListingTransactionStatus,
    writeAsync: writeBuyListingAsync,
  } = useContractWrite(writeBuyListingConfig);

  const {
    status: mineBuyListingTransactionStatus,
  } = useWaitForTransaction({
    hash: submitBuyListingResult ? submitBuyListingResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeBuyListingAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeBuyListingAsync,
    listingIdInput,
    maxPriceInput,
    ticketIdsInput,
    emailHashInput,
    encryptedEmailInput,
    setListingIdInput,
    setMaxPriceInput,
    setTicketIdsInput,
    setEmailHashInput,
    setEncryptedEmailInput,
    setShouldConfigureBuyListingWrite,
    shouldConfigureBuyListingWrite,
    signBuyListingTransactionStatus,
    mineBuyListingTransactionStatus,
    transactionHash: submitBuyListingResult?.hash,
  };
};

export const abi = [
  {
    "inputs": [
      {
        "internalType": "contract ITicketRegistry",
        "name": "_ticketRegistry",
        "type": "address"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "bytes32",
        "name": "eventId",
        "type": "bytes32"
      },
      {
        "indexed": false,
        "internalType": "string",
        "name": "rawId",
        "type": "string"
      },
      {
        "indexed": false,
        "internalType": "string",
        "name": "eventName",
        "type": "string"
      },
      {
        "indexed": false,
        "internalType": "string",
        "name": "venue",
        "type": "string"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "startTime",
        "type": "uint256"
      }
    ],
    "name": "EventAdded",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "eventIds",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "name": "events",
    "outputs": [
      {
        "internalType": "string",
        "name": "rawId",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "eventName",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "venue",
        "type": "string"
      },
      {
        "internalType": "uint256",
        "name": "startTime",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "eventUrl",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getAllEventsInfo",
    "outputs": [
      {
        "components": [
          {
            "components": [
              {
                "internalType": "string",
                "name": "rawId",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "eventName",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "venue",
                "type": "string"
              },
              {
                "internalType": "uint256",
                "name": "startTime",
                "type": "uint256"
              },
              {
                "internalType": "string",
                "name": "eventUrl",
                "type": "string"
              }
            ],
            "internalType": "struct IEventRegistry.Event",
            "name": "eventInfo",
            "type": "tuple"
          },
          {
            "internalType": "bytes32",
            "name": "eventId",
            "type": "bytes32"
          }
        ],
        "internalType": "struct IEventRegistry.EventWithId[]",
        "name": "eventInfo",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getEventIds",
    "outputs": [
      {
        "internalType": "bytes32[]",
        "name": "",
        "type": "bytes32[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_eventId",
        "type": "bytes32"
      }
    ],
    "name": "getEventInfo",
    "outputs": [
      {
        "components": [
          {
            "internalType": "string",
            "name": "rawId",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "eventName",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "venue",
            "type": "string"
          },
          {
            "internalType": "uint256",
            "name": "startTime",
            "type": "uint256"
          },
          {
            "internalType": "string",
            "name": "eventUrl",
            "type": "string"
          }
        ],
        "internalType": "struct IEventRegistry.Event",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_eventId",
        "type": "bytes32"
      }
    ],
    "name": "getEventStartTime",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32[]",
        "name": "_eventIds",
        "type": "bytes32[]"
      }
    ],
    "name": "getEventsInfo",
    "outputs": [
      {
        "components": [
          {
            "components": [
              {
                "internalType": "string",
                "name": "rawId",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "eventName",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "venue",
                "type": "string"
              },
              {
                "internalType": "uint256",
                "name": "startTime",
                "type": "uint256"
              },
              {
                "internalType": "string",
                "name": "eventUrl",
                "type": "string"
              }
            ],
            "internalType": "struct IEventRegistry.Event",
            "name": "eventInfo",
            "type": "tuple"
          },
          {
            "internalType": "bytes32",
            "name": "eventId",
            "type": "bytes32"
          }
        ],
        "internalType": "struct IEventRegistry.EventWithId[]",
        "name": "eventInfo",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "internalType": "string",
            "name": "rawId",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "eventName",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "venue",
            "type": "string"
          },
          {
            "internalType": "uint256",
            "name": "startTime",
            "type": "uint256"
          },
          {
            "internalType": "string",
            "name": "eventUrl",
            "type": "string"
          }
        ],
        "internalType": "struct IEventRegistry.Event",
        "name": "_event",
        "type": "tuple"
      }
    ],
    "name": "parseEventInfo",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "ticketRegistry",
    "outputs": [
      {
        "internalType": "contract ITicketRegistry",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];

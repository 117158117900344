export const NewListingTransactionStatus = {
  DEFAULT: 'default',
  BAD_TICKET_SELECTION: 'bad_ticket_selection',
  MISSING_PRICE: 'missing_amounts',
  TRANSACTION_SIGNING: 'transaction_signing',
  TRANSACTION_MINING: 'transaction_mining',
  VALID: 'valid',
  TRANSACTION_SUCCEEDED: 'transaction_succeeded'
};

export type NewListingTransactionStatusType = typeof NewListingTransactionStatus[keyof typeof NewListingTransactionStatus];

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { Event } from '@helpers/types';
import { colors } from '@theme/colors';


interface EventHeaderProps {
  events: Event[] | null;
  didSelectEventAtIndex: (index: number) => void;
};

export const EventHeader: React.FC<EventHeaderProps> = ({
  events,
  didSelectEventAtIndex
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  /*
   * State
   */

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  /*
   * Hooks
   */

  useEffect(() => {
    if (events && events.length > 0 && selectedIndex === null) {
      setSelectedIndex(0);

      didSelectEventAtIndex(0);
    }
  }, [events, selectedIndex, didSelectEventAtIndex]);

  /*
   * Handlers
   */

  const handleButtonClick = (index: number) => {
    setSelectedIndex(index);

    didSelectEventAtIndex(index);
  };
  
  /*
   * Component
   */

  return (
    <Container>
      <ContentWrapper>
        <Content ref={scrollContainerRef}>
          <EventLabel>
            Events:
          </EventLabel>

          {events && events.map((event, index) => (
            <EventButton
              key={event.id}
              onClick={() => handleButtonClick(index)}
              isSelected={index === selectedIndex}
            >
              {event.name}
            </EventButton>
          ))}
        </Content>
      </ContentWrapper>
    </Container>
  )
};

const Container = styled.div`
  width: 100%;
  max-width: 920px;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  background-color: ${colors.buttonDefault};

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Content = styled.div`
  display: inline-flex;
  padding: 1.25rem 1rem 1rem 1.5rem;
  gap: 1rem;
  white-space: nowrap;
`;

const EventLabel = styled.div`
  margin: auto;
  font-size: 14px;
  color: ${colors.white};
  font-weight: 600;
`;

const EventButton = styled.button<{ isSelected: boolean }>`
  border: none;
  background-color: ${colors.buttonDefault};
  color: ${colors.white};
  opacity: ${({ isSelected }) => isSelected ? 1 : 0.6};
  text-decoration: ${props => props.isSelected ? 'underline' : 'none'};
  text-underline-offset: 4px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
`;

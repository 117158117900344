import { useCallback, useEffect, useRef, useState } from 'react';


export default function useTableScroll(debounceTime = 500) {
  const scrollingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  
  /*
   * State
   */

  const [tableElement, setTableElement] = useState<HTMLDivElement | null>(null);
  const [isScrolling, setIsScrolling] = useState(false);

  const tableRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setTableElement(node);
    }
  }, []);

  /*
   * Handlers
   */

  const handleScroll = useCallback(() => {
    if (!isScrolling) {
      setIsScrolling(true);
    }

    if (scrollingTimeoutRef.current) {
      clearTimeout(scrollingTimeoutRef.current);
    }

    scrollingTimeoutRef.current = setTimeout(() => {
      setIsScrolling(false);
    }, debounceTime);
  }, [isScrolling, debounceTime]);

  /*
   * Hooks
   */

  useEffect(() => {
    if (!tableElement) {
      return;
    };

    tableElement.addEventListener('scroll', handleScroll);

    return () => {
      tableElement.removeEventListener('scroll', handleScroll);
      if (scrollingTimeoutRef.current) {
        clearTimeout(scrollingTimeoutRef.current);
      }
    };
  }, [tableElement, handleScroll]);

  return {
    tableRef,
    isScrolling,
  };
}

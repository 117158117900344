import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import { toBigInt } from '@helpers/units';
import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useCreateListingTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { swapTicketExchangeAddress, swapTicketExchangeAbi } = useSmartContracts();

  /*
   * State
   */ 

  const [ticketIdsInput, setTicketIdsInput] = useState<string[]>([]);
  const [priceInput, setPriceInput] = useState<string>('');
  const [expirationInput, setExpirationInput] = useState<bigint | null>(null);
  const [encryptEmailKeyInput, setEncryptEmailKeyInput] = useState<string>('');

  const [shouldConfigureCreateListingWrite, setShouldConfigureCreateListingWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  const { config: writeCreateListingConfig } = usePrepareContractWrite({
    address: swapTicketExchangeAddress,
    abi: swapTicketExchangeAbi,
    functionName: 'createListing',
    args: [
      ticketIdsInput,
      toBigInt(priceInput),
      expirationInput,
      '0x' + encryptEmailKeyInput
    ],
    onError(error: any) {
      console.log('writeCreateListingConfig failed: ', error.message);

      setShouldConfigureCreateListingWrite(false);

      onError?.(error);
    },
    enabled: shouldConfigureCreateListingWrite,
  });

  const {
    data: submitCreateListingResult,
    status: signCreateListingTransactionStatus,
    writeAsync: writeCreateListingAsync,
  } = useContractWrite(writeCreateListingConfig);

  const {
    status: mineCreateListingTransactionStatus,
  } = useWaitForTransaction({
    hash: submitCreateListingResult ? submitCreateListingResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeCreateListingAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeCreateListingAsync,
    ticketIdsInput,
    priceInput,
    expirationInput,
    encryptEmailKeyInput,
    setTicketIdsInput,
    setPriceInput,
    setExpirationInput,
    setEncryptEmailKeyInput,
    setShouldConfigureCreateListingWrite,
    shouldConfigureCreateListingWrite,
    signCreateListingTransactionStatus,
    mineCreateListingTransactionStatus,
    transactionHash: submitCreateListingResult?.hash,
  };
};

import React, { useCallback, useState, useRef, useEffect, useLayoutEffect, useMemo } from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { ChevronDown } from 'react-feather';

import { colors } from '@theme/colors';
import { VerifyInstructionsRow } from '@components/Listings/NewListing/VerifyTickets/VerifyInstructionsRow';


export const BuyInstructions: React.FC = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  /*
   * State
   */

  const [isOpen, setIsOpen] = useState(true);

  const [centerPosition, setCenterPosition] = useState(0);

  const [canAnimate, setCanAnimate] = useState(false);

  /*
   * Hooks
   */

  const updateCenterPosition = useCallback(() => {
    if (containerRef.current && titleRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const titleWidth = titleRef.current.offsetWidth;
      const newCenterPosition = (containerWidth - titleWidth) / 2;
      setCenterPosition(newCenterPosition);
    }
  }, []);

  useLayoutEffect(() => {
    updateCenterPosition();
  }, [updateCenterPosition]);

  useEffect(() => {
    const handleResize = () => {
      requestAnimationFrame(updateCenterPosition);
    };

    window.addEventListener('resize', handleResize);
    
    const timer = setTimeout(() => setCanAnimate(true), 0);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timer);
    };
  }, [updateCenterPosition]);

  const toggleOpen = useCallback(() => setIsOpen(prev => !prev), []);

  const titleStyle = useMemo(() => ({
    transform: `translateX(${isOpen ? centerPosition : 0}px)`
  }), [isOpen, centerPosition]);

  /*
   * Component
   */

  return (
    <Wrapper ref={wrapperRef}>
        <TitleLabelAndDropdownIconContainer ref={containerRef} $isOpen={isOpen}>
          <TitleLabelWrapper>
            <TitleLabel  ref={titleRef}  $canAnimate={canAnimate} style={titleStyle}>
              Review Instructions
            </TitleLabel>
          </TitleLabelWrapper>
          
          <StyledChevronDown
            onClick={toggleOpen}
            $isOpen={isOpen}
          />
        </TitleLabelAndDropdownIconContainer>

        <InstructionsDropdown $isOpen={isOpen}>
          <VerifyInstructionsRow step={1}>
            Select the tickets to purchase and submit order, this requires paying <strong>USDC</strong>
          </VerifyInstructionsRow>

          <VerifyInstructionsRow step={2}>
            The seller will send you the tickets at the provided <strong>Ticketmaster email address</strong>
          </VerifyInstructionsRow>

          <VerifyInstructionsRow step={3}>
            If the seller has not sent you the tickets, you can submit a refund request. See our <SectionEmbeddedLink to="/how-it-works">"How To" on Refunding Purchases</SectionEmbeddedLink> for more.
          </VerifyInstructionsRow>

          <DisclaimerLabel>
            Due to the peer-to-peer nature of this marketplace, in the event of event cancellation, we cannot guarantee a refund.
          </DisclaimerLabel>
        </InstructionsDropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 14px 20px 12px 20px;
  border-radius: 16px;
  border: 1px solid ${colors.defaultBorderColor};
  background-color: ${colors.inputDefaultColor};

  --center-position: 0px;
`;

const TitleLabelAndDropdownIconContainer = styled.div<{ $isOpen: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: padding-bottom 0.4s ease-out;
  padding-bottom: ${({ $isOpen }) => $isOpen ? '1.25rem' : '0rem'};
  align-items: center;
`;

const TitleLabelWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
`;

const TitleLabel = styled.div<{ $canAnimate: boolean }>`
  display: flex;
  align-items: center;
  font-size: 15px;
  transition: ${({ $canAnimate }) => $canAnimate ? 'transform 0.4s ease-out' : 'none'};
`;

const StyledChevronDown = styled(ChevronDown)<{ $isOpen: boolean }>`
  width: 20px;
  height: 20px;
  color: #CED4DA;
  flex-shrink: 0;
  transition: transform 0.4s ease-out;
  transform: ${({ $isOpen }) => $isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const InstructionsDropdown = styled.div<{ $isOpen: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.inputDefaultColor};
  color: #FFF;
  gap: 16px;
  overflow: hidden;
  max-height: ${({ $isOpen }) => $isOpen ? '500px' : '0rem'};
  transition: max-height 0.4s ease-out;
`;

const DisclaimerLabel = styled.button`
  font-size: 14px;
  font-family: 'Graphik';
  color: ${colors.warningRed};
  font-weight: 600;
  padding: 0.75rem 0;
  text-align: center;
  background: none;
  border: none;
`;

const SectionEmbeddedLink = styled(Link)`
  color: #0066CC;
  font-weight: 535;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  margin-top: auto;
  transition: opacity 250ms ease 0s;
  &:hover {
    opacity: 0.6;
  }
`;

import React, { useRef, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';

import { colors, gradientOptions } from '@theme/colors';
import { Button } from '@components/common/Button';
import useMediaQuery from '@hooks/useMediaQuery';


interface EventCarouselCardProps {
  cardIndex: number;
  venueText: string;
  eventText: string;
  startTimeText: string;
  onCardClick: () => void;
}

export const EventCarouselCard: React.FC<EventCarouselCardProps> = ({
  cardIndex,
  venueText,
  eventText,
  startTimeText,
  onCardClick,
}: EventCarouselCardProps) => {
  EventCarouselCard.displayName = "EventCarouselCard";
  
  const imageContainerRef = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery() === 'mobile';

  /*
   * State
   */

  const [fontSize, setFontSize] = useState(30);

  const selectedGradient = useMemo(() => {
    return gradientOptions[cardIndex % gradientOptions.length];
  },[cardIndex]);

  /*
   * Hooks
   */

  useEffect(() => {
    const resizeText = () => {
      if (imageContainerRef.current) {
        const containerWidth = imageContainerRef.current.offsetWidth;
        const containerHeight = imageContainerRef.current.offsetHeight;
        const longestText = Math.max(eventText.length, startTimeText.length);
        
        // Calculate font size based on container dimensions and text length
        const newFontSize = Math.min(
          containerWidth / (longestText * 0.75), // Adjust 0.6 as needed
          containerHeight / 7                    // Assuming we want to fit about 8 lines
        );

        setFontSize(Math.floor(newFontSize));
      }
    };

    resizeText();
    window.addEventListener('resize', resizeText);
    return () => window.removeEventListener('resize', resizeText);
  }, [eventText, startTimeText]);

  return (
    <Container>
      <BackgroundSVG>
        <svg height="100%" width="100%">
          <defs>
            <linearGradient id={`linear-${cardIndex}`} x1="0" y1="0.5" x2="1" y2="0.5">
              {selectedGradient.linear.map((stop, index) => (
                <stop key={index} offset={stop.offset} stopColor={stop.color} />
              ))}
            </linearGradient>

            <radialGradient id={`radial-${cardIndex}`} gradientTransform="translate(-0.9 0) scale(2, 2)">
              {selectedGradient.radial.map((stop, index) => (
                <stop key={index} offset={stop.offset} stopColor={stop.color} />
              ))}
            </radialGradient>
          </defs>
          <rect fill={`url(#linear-${cardIndex})`} height="100%" width="100%"/>
          <rect fill={`url(#radial-${cardIndex})`} height="100%" width="100%"/>
        </svg>
      </BackgroundSVG>

      <ContentContainer>
        <DetailsAndButtonsContainer>
          <ContentWrapper>
            <EventDetailsContainer isMobile={isMobile}>
              <EventNameLabel>
                {eventText}
              </EventNameLabel>

              <DetailValueLabel>
                {startTimeText}
              </DetailValueLabel>

              <DetailValueLabel>
                {venueText}
              </DetailValueLabel>
            </EventDetailsContainer>

            <Button
              disabled={false}
              onClick={onCardClick}
              width={184}
            >
              See Listings
            </Button>
          </ContentWrapper>
        </DetailsAndButtonsContainer>
        <BlockTextContainer ref={imageContainerRef}>
          <BlockLetterText opacity={0.8} fontSize={fontSize}>{eventText}</BlockLetterText>
          <BlockLetterText opacity={0.3} fontSize={fontSize}>{startTimeText}</BlockLetterText>
          <BlockLetterText opacity={0.6} fontSize={fontSize}>{eventText}</BlockLetterText>
          <BlockLetterText opacity={0.6} fontSize={fontSize}>{startTimeText}</BlockLetterText>
          <BlockLetterText opacity={0.4} fontSize={fontSize}>{eventText}</BlockLetterText>
          <BlockLetterText opacity={0.9} fontSize={fontSize}>{startTimeText}</BlockLetterText>
          <BlockLetterText opacity={0.2} fontSize={fontSize}>{eventText}</BlockLetterText>
        </BlockTextContainer>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 350px;
  display: flex;
  flex-direction: row;
  background-color: ${colors.container};
  position: relative;
  overflow: hidden;
`;

const BackgroundSVG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const DetailsAndButtonsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem 4rem;
  height: 100%;
  width: 35%;
`;

const BlockTextContainer = styled.div`
  width: 65%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
`;

const BlockLetterText = styled.div<{opacity?: number, fontSize: number}>`
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  opacity: ${({ opacity }) => opacity || 1};
  white-space: nowrap;
  color: ${colors.darkText};
  z-index: 1;
  margin: ${({ fontSize }) => `${(50-fontSize) / 2}px 0`};
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding-top: 7rem;
  gap: 1.5rem;
`;

const EventDetailsContainer = styled.div<{isMobile?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

const EventNameLabel = styled.span`
  font-size: 24px;
  color: ${colors.darkText};
  font-weight: 600;
`;

const DetailValueLabel = styled.span`
  font-size: 16px;
  color: ${colors.darkText};
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

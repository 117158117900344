import { createContext } from 'react';

import { BuyerOrdersStore } from '@helpers/types';


interface BuyersValues {
  buyerOrdersStore: BuyerOrdersStore | null;
  refetchBuyerOrders: (() => void) | null;
  shouldFetchBuyerOrders: boolean;
};

const defaultValues: BuyersValues = {
  buyerOrdersStore: null,
  refetchBuyerOrders: null,
  shouldFetchBuyerOrders: false,
};

const BuyersContext = createContext<BuyersValues>(defaultValues);

export default BuyersContext;
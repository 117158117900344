import { Ticket, TicketStub } from '@helpers/types'; 


interface TicketSummary {
    sectionSummary: string;
    rowSummary: string;
    seatSummary: string;
  }
  
  export function formatTicketSummaries(tickets: Ticket[]): TicketSummary {
    if (tickets.length === 0) return { sectionSummary: "", rowSummary: "", seatSummary: "" };
  
    // Sort tickets by section, row, and seat
    tickets.sort((a, b) => {
      if (a.section !== b.section) return a.section.localeCompare(b.section);
      if (a.row !== b.row) return a.row.localeCompare(b.row);
      return parseInt(a.seat) - parseInt(b.seat);
    });
  
    const sections = new Set<string>();
    const rows = new Set<string>();
    const seats: number[] = [];
  
    for (const ticket of tickets) {
      sections.add(ticket.section);
      rows.add(ticket.row);
      seats.push(parseInt(ticket.seat));
    }
  
    function formatRange(numbers: number[]): string {
      if (numbers.length === 0) return "";
      if (numbers.length === 1) return numbers[0].toString();
      
      let result = "";
      let start = numbers[0];
      let end = numbers[0];
  
      for (let i = 1; i <= numbers.length; i++) {
        if (i < numbers.length && numbers[i] === end + 1) {
          end = numbers[i];
        } else {
          if (result) result += ", ";
          result += start === end ? start.toString() : `${start}-${end}`;
          if (i < numbers.length) {
            start = end = numbers[i];
          }
        }
      }
  
      return result;
    }
  
    const sectionSummary = sections.size === 1 ? `Section ${[...sections][0]}` : `Sections ${[...sections].join(", ")}`;
    const rowSummary = rows.size === 1 ? `Row ${[...rows][0]}` : `Rows ${[...rows].join(", ")}`;
    const seatSummary = seats.length === 1 ? `Seat ${seats[0]}` : `Seats ${formatRange(seats)}`;
  
    return { sectionSummary, rowSummary, seatSummary };
  };

  export function formatTicketSeatsSummary(tickets: (Ticket | TicketStub)[]): string {
    if (tickets.length === 0) return "";
  
    // Sort tickets by section, row, and seat
    tickets.sort((a, b) => {
      if (a.section !== b.section) return a.section.localeCompare(b.section);
      if (a.row !== b.row) return a.row.localeCompare(b.row);
      return parseInt(a.seat) - parseInt(b.seat);
    });
  
    let result = "";
    let currentSection = "";
    let currentRow = "";
    let seatGroups: number[][] = [];
    let currentGroup: number[] = [];
  
    function addSeatGroups() {
      if (seatGroups.length > 0) {
        const seatString = seatGroups.map(group => {
          if (group.length === 1) {
            return `${group[0]}`;
          } else {
            return `${group[0]}-${group[group.length - 1]}`;
          }
        }).join(", ");
        result += `Seat${seatGroups.length > 1 || seatGroups[0].length > 1 ? 's' : ''} ${seatString}`;
        seatGroups = [];
      }
    }
  
    for (let i = 0; i < tickets.length; i++) {
      const ticket = tickets[i];
      const seatNum = parseInt(ticket.seat);
  
      if (ticket.section !== currentSection) {
        addSeatGroups();
        if (result) result += " | ";
        result += `Sec ${ticket.section}, `;
        currentSection = ticket.section;
        currentRow = "";
      }
  
      if (ticket.row !== currentRow) {
        addSeatGroups();
        if (currentRow) result += ", ";
        result += `Row ${ticket.row}, `;
        currentRow = ticket.row;
      }
  
      if (currentGroup.length === 0 || seatNum === currentGroup[currentGroup.length - 1] + 1) {
        currentGroup.push(seatNum);
      } else {
        seatGroups.push(currentGroup);
        currentGroup = [seatNum];
      }
    }
  
    if (currentGroup.length > 0) {
      seatGroups.push(currentGroup);
    }
  
    addSeatGroups();
  
    return result;
  };

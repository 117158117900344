import React, { ReactNode } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';

import useMediaQuery from '@hooks/useMediaQuery';

import { TopNav } from '@components/layouts/TopNav';
import { BottomNav } from '@components/layouts/BottomNav';
import { EnvironmentBanner } from '@components/layouts/EnvironmentBanner';
import { MobileLandingPage } from '@components/layouts/MobileLandingPage';

import { HowItWorks } from "./pages/HowItWorks";
import { Event } from "./pages/Event";
import { ListingsPage } from "./pages/Listings";
import { Browse } from "./pages/Browse";
import { Orders } from "./pages/Orders";
import { Send } from "./pages/Send";
import { Privacy } from "./pages/Privacy";
import { Tos } from "./pages/Tos";
import Modals from "./pages/Modals";

// Contexts
import AccountProvider from './contexts/Account/AccountProvider';
import BalancesProvider from './contexts/Balances/BalancesProvider';
import SendSettingsProvider from './contexts/SendSettings/SendSettingsProvider';
import SmartContractsProvider from './contexts/SmartContracts/SmartContractsProvider';
import ExtensionNotarizationsProvider from './contexts/ExtensionProxyProofs/ExtensionProxyProofsProvider';
import { ModalSettingsProvider } from 'contexts/ModalSettings';

import TicketsProvider from './contexts/Tickets/TicketsProvider';
import EventsProvider from './contexts/Events/EventsProvider';
import ListingsProvider from './contexts/Listings/ListingsProvider';
import SellersProvider from './contexts/Sellers/SellersProvider';
import BuyersProvider from './contexts/Buyers/BuyersProvider';

import './App.css';
import './styles.css';


const App = () => {
  /*
   * Context
   */

  const currentDeviceSize = useMediaQuery();

  /*
   * Component
   */

  if (currentDeviceSize === 'mobile') {
    return (
      <Router>
        <Providers>
          <div className="app-container">
            <TopNav withoutLinks />
            <div className="app-content">
              <Routes>
                <Route path="/" element={<MobileLandingPage />} />
                <Route path="/browse/:eventId" element={<MobileLandingPage />} />
                <Route path="/how-it-works" element={<MobileLandingPage />} />
                <Route path="/orders" element={<MobileLandingPage />} />
                <Route path="/listings" element={<MobileLandingPage />} />
                <Route path="/send" element={<MobileLandingPage />} />
                <Route path="/pp" element={<Privacy />} />
                <Route path="/tos" element={<Tos />} />
              </Routes>
            </div>
          </div>
        </Providers>
      </Router>
    );
  } else {
    return (
      <Router>
        <Providers>
          <div className="app-container">
            <EnvironmentBanner />
            <TopNav />
            <div className="app-content">
              <Routes>
                <Route path="/" element={<Browse />} />
                <Route path="/browse/:eventId" element={<Event />} />
                <Route path="/how-it-works" element={<HowItWorks />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/listings" element={<ListingsPage />} />
                <Route path="/send" element={<Send />} />
                <Route path="/pp" element={<Privacy />} />
                <Route path="/tos" element={<Tos />} />
                <Route path="*" element={<>Not found</>} />
              </Routes>
            </div>
            <Modals />

            {( currentDeviceSize === 'tablet' || currentDeviceSize === 'mobile') &&
              <BottomNav />
            }
          </div>
        </Providers>
      </Router>
    );
  }
};

type ProvidersType = [React.ElementType, Record<string, unknown>];
type ChildrenType = {
  children: React.ReactNode;
};

export const buildProvidersTree = (
  componentsWithProps: Array<ProvidersType>,
) => {
  const initialComponent = ({children}: ChildrenType) => <>{children}</>;
  return componentsWithProps.reduce(
    (
      AccumulatedComponents: React.ComponentType<ChildrenType>,
      [Provider, props = {}]: ProvidersType,
    ) => {
      return ({children}: ChildrenType) => (
        <AccumulatedComponents>
          <Provider {...props}>{children}</Provider>
        </AccumulatedComponents>
      );
    },
    initialComponent,
  );
};

const providersWithProps: ProvidersType[] = [
  [AccountProvider, {}],
  [SmartContractsProvider, {}],

  [SendSettingsProvider, {}],

  [BalancesProvider, {}],
  [EventsProvider, {}],
  [TicketsProvider, {}],
  [ListingsProvider, {}],
  [SellersProvider, {}],
  [BuyersProvider, {}],

  [ExtensionNotarizationsProvider, {}],
  
  [ModalSettingsProvider, {}],
];

const ProviderTree = buildProvidersTree(providersWithProps);

interface ProvidersProps {
  children: ReactNode;
}

const Providers: React.FC<ProvidersProps> = ({ children }) => {
  return <ProviderTree>{children}</ProviderTree>;
}

export default App;

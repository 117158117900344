import { useEffect, useState, ReactNode, useRef } from 'react';
import { useContractRead } from 'wagmi';
import { readContract } from '@wagmi/core';

import { Listing, DetailedListing, Order, Ticket, Abi } from '@helpers/types';
import { esl, CALLER_ACCOUNT, ZERO } from '@helpers/constants';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import useEvents from '@hooks/contexts/useEvents';
import useTickets from '@hooks/contexts/useTickets';

import ListingsContext from './ListingsContext';


const BATCH_SIZE = 30;
const PRUNED_LISTINGS_PREFIX = 'prunedListings_';

interface ProvidersProps {
  children: ReactNode;
}

const Listings = ({ children }: ProvidersProps) => {
  /*
   * Contexts
   */

  const { swapTicketExchangeAddress, swapTicketExchangeAbi } = useSmartContracts();
  const { fetchTicketsBatch } = useTickets();
  const { eventsByEventId } = useEvents();

  /*
   * State
   */

  const currentRampAddressRef = useRef(swapTicketExchangeAddress);

  const [fetchListingsTrigger, setFetchListingsTrigger] = useState(0);

  const [listingCounter, setListingCounter] = useState<bigint | null>(null);

  const [activeListingIds, setActiveListingIds] = useState<bigint[] | null>(null);
  const [activeListingTicketIds, setActiveListingTicketIds] = useState<string[] | null>(null);

  const [activeListings, setActiveListings] = useState<Listing[] | null>(null);
  const [activeListingOrders, setActiveListingOrders] = useState<Order[] | null>(null);
  const [activeListingTickets, setActiveListingTickets] = useState<Ticket[] | null>(null);

  const [activeListingsStore, setActiveListingsStore] = useState<DetailedListing[] | null>(null);

  const [shouldFetchListingCounter, setShouldFetchListingCounter] = useState<boolean>(false);
  const [shouldFetchActiveListings, setShouldFetchActiveListings] = useState<boolean>(false);
  const [shouldFetchActiveListingTickets, setShouldFetchActiveListingTickets] = useState<boolean>(false);
  const [shouldFetchActiveListingOrders, setShouldFetchActiveListingOrders] = useState<boolean>(false);

  /*
   * Contract Reads
   */

  // uint256 public listingCounter;
  const {
    data: listingCounterRaw,
    refetch: refetchListingCounter,
  } = useContractRead({
    address: swapTicketExchangeAddress,
    abi: swapTicketExchangeAbi,
    functionName: 'listingCounter',
    enabled: shouldFetchListingCounter,
    account: CALLER_ACCOUNT
  })

  // getListingOrders(uint256[] memory _listingIds) (OrderWithId[][] memory ordersOutput) 
  const {
    data: activeListingOrdersRaw,
    // refetch: refetchActiveListingOrders,
  } = useContractRead({
    address: swapTicketExchangeAddress,
    abi: swapTicketExchangeAbi,
    functionName: 'getListingOrders',
    args: [
      activeListingIds
    ],
    enabled: shouldFetchActiveListingOrders
  });

  /*
   * Hooks
   */

  useEffect(() => {
    esl && console.log('shouldFetchListingCounter_1');
    esl && console.log('checking swapTicketExchangeAddress: ', swapTicketExchangeAddress);

    if (swapTicketExchangeAddress) {
      esl && console.log('shouldFetchListingCounter_2');

      setShouldFetchListingCounter(true);
    } else {
      esl && console.log('shouldFetchListingCounter_3');

      setShouldFetchListingCounter(false);

      setActiveListings(null);
    }
  }, [swapTicketExchangeAddress]);

  useEffect(() => {
    esl && console.log('listingCounter_1');
    esl && console.log('checking listingCounterRaw: ', listingCounterRaw);
  
    if (listingCounterRaw || listingCounterRaw === ZERO) { // BigInt(0) is falsy)
      esl && console.log('listingCounter_2');
      
      setListingCounter(listingCounterRaw as bigint);
    } else {
      esl && console.log('listingCounter_3');
      
      setListingCounter(null);
    }
  }, [listingCounterRaw]);
  
  useEffect(() => {
    currentRampAddressRef.current = swapTicketExchangeAddress;
  }, [swapTicketExchangeAddress]);
  
  useEffect(() => {
    esl && console.log('shouldFetchActiveListings_1');
    esl && console.log('checking listingCounter: ', listingCounter);
    esl && console.log('checking swapTicketExchangeAddress: ', swapTicketExchangeAddress);
  
    const fetchData = async () => {
      if (listingCounter && swapTicketExchangeAddress) {
        esl && console.log('shouldFetchActiveListings_2');
  
        setShouldFetchActiveListings(true);
  
        await fetchAndPruneListings(listingCounter, swapTicketExchangeAddress);
      } else {
        esl && console.log('shouldFetchActiveListings_3');
  
        setShouldFetchActiveListings(false);
  
        setActiveListings(null);
        setActiveListingsStore(null);
      }
    };
  
    fetchData();
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingCounter, swapTicketExchangeAddress, fetchListingsTrigger]);

  useEffect(() => {
    esl && console.log('activeListingOrders_1');
    esl && console.log('checking activeListingOrdersRaw', activeListingOrdersRaw);
  
    if (activeListingOrdersRaw) {
      esl && console.log('activeListingOrders_2');
      const flattenedListingOrders = activeListingOrdersRaw.flat();

      if (flattenedListingOrders.length > 0) {
        esl && console.log('activeListingOrders_3');
  
        const sanitizedOrders = sanitizeRawOrders(flattenedListingOrders);
        setActiveListingOrders(sanitizedOrders);
      } else {
        esl && console.log('activeListingOrders_4');
    
        setActiveListingOrders([]); // Return empty array to indicate loaded state with no orders
      }
    } else {
      esl && console.log('activeListingOrders_5');
  
      setActiveListingOrders(null);
    }
  }, [activeListingOrdersRaw]);

  useEffect(() => {
    esl && console.log('activeListingTickets_1');
    esl && console.log('checking activeListingTicketIds: ', activeListingTicketIds);

    if (fetchTicketsBatch && activeListingTicketIds && activeListingTicketIds.length > 0) {
      esl && console.log('activeListingTickets_2');

      const fetchTickets = async () => {
        try {
          const tickets = await fetchTicketsBatch(activeListingTicketIds);
          
          esl && console.log('tickets: ', tickets);

          setActiveListingTickets(tickets);
        } catch (error) {
          esl && console.log('activeListingTickets_3');
          
          setActiveListingTickets(null);
        }
      };
  
      fetchTickets();
    } else {
      esl && console.log('activeListingTickets_4');

      setActiveListingTickets(null);
    }

  }, [activeListingTicketIds, shouldFetchActiveListingTickets, fetchTicketsBatch]);
  
  useEffect(() => {
    esl && console.log('activeListingstore_1');
    esl && console.log('checking activeListings: ', activeListings);
    esl && console.log('checking activeListingTickets: ', activeListingTickets); 
    esl && console.log('checking activeListingOrders: ', activeListingOrders);
    esl && console.log('checking eventsByEventId: ', eventsByEventId);
  
    if (activeListings && activeListingTickets && activeListingOrders && eventsByEventId) {
      esl && console.log('activeListingstore_2');

      const ordersByListingIdMap: { [listingId: string]: Order[] } = {};
      activeListingOrders.forEach(order => {
        const listingId = order.listingId.toString();
        if (!ordersByListingIdMap[listingId]) {
          ordersByListingIdMap[listingId] = [];
        }

        ordersByListingIdMap[listingId].push(order);
      });

      const detailedactiveListings: DetailedListing[] = activeListings.map((listing: Listing) => {
        const unfilledTickets = listing.unfilledTicketIds.map(ticketId => 
          activeListingTickets.find(ticket => ticket.ticketId === ticketId)
        ).filter((ticket): ticket is Ticket => ticket !== undefined);

        const pendingTickets = listing.pendingTicketIds.map(ticketId => 
          activeListingTickets.find(ticket => ticket.ticketId === ticketId)
        ).filter((ticket): ticket is Ticket => ticket !== undefined);

        const listingOrders = ordersByListingIdMap[listing.listingId.toString()];

        return {
          ...listing,
          unfilledTickets,
          pendingTickets,
          orders: listingOrders
        };
      });

      setActiveListingsStore(detailedactiveListings);
    } else {
      esl && console.log('activeListingstore_3');
  
      setActiveListingsStore(null);
    }
  }, [activeListings, activeListingTickets, activeListingOrders, eventsByEventId]);
  
  /*
   * Public
   */
  
  const refetchActiveListings = () => {
    setFetchListingsTrigger(prev => prev + 1);
  };
  
  /*
   * Helpers
   */
  
  const fetchStoredPrunedListingIds = (contractAddress: string) => {
    const prunedIdsStorageKey = `${PRUNED_LISTINGS_PREFIX}${contractAddress}`;
    const prunedIdsFromStorage = localStorage.getItem(prunedIdsStorageKey);
    const prunedIdsFromStorageParsed = prunedIdsFromStorage ? JSON.parse(prunedIdsFromStorage).map(BigInt) : [];
  
    return prunedIdsFromStorageParsed;
  };
  
  const updateStoredPrunedIds = (rampAddress: string, prunedListingIdsToStore: bigint[]) => {
    esl && console.log('updateStoredPrunedIds_1: ', rampAddress);
  
    const storageKey = `${PRUNED_LISTINGS_PREFIX}${rampAddress}`;
    const prunedListingIdsForStorage = prunedListingIdsToStore.map(id => id.toString());
    localStorage.setItem(storageKey, JSON.stringify(prunedListingIdsForStorage));
  };

  const fetchAndPruneListings = async (listingCounter: bigint, rampAddress: string) => {
    const existingPrunedIds = fetchStoredPrunedListingIds(rampAddress);
    const listingIdsToFetch = initializeListingIdsToFetch(listingCounter, existingPrunedIds);

    esl && console.log('listingIdsToFetch: ', listingIdsToFetch);
    esl && console.log('existingPrunedIds: ', existingPrunedIds);
  
    const fetchedActiveListings: Listing[] = [];
    const listingIdsToPrune: bigint[] = [];

    const ticketIdsToFetch: string[] = [];
    
    for (let i = 0; i < listingIdsToFetch.length; i += BATCH_SIZE) {
      const listingIdBatch = listingIdsToFetch.slice(i, i + BATCH_SIZE);
      esl && console.log('listingIdBatch: ', listingIdBatch);

      const rawListingsData = await fetchingListingsBatch(listingIdBatch);

      esl && console.log('rawListingsData: ', rawListingsData);
      
      const listings = sanitizeRawListings(rawListingsData as any);
      for (let j = 0; j < listings.length; j++) {
        const listing = listings[j];
  
        const listingHasNoUnfilledTickets = listing.unfilledTicketIds.length === 0;
        const listingHasNoPendingTickets = listing.pendingTicketIds.length === 0;
        const listingCompleted = listingHasNoUnfilledTickets && listingHasNoPendingTickets;
  
        esl && console.log('listing: ', listing);

        if (listingCompleted) {
          listingIdsToPrune.push(listing.listingId);
        } else {
          fetchedActiveListings.push(listing);
        }

        ticketIdsToFetch.push(...listing.unfilledTicketIds, ...listing.pendingTicketIds);
      }
    }

    esl && console.log('fetchedActiveListings: ', fetchedActiveListings);
  
    if (currentRampAddressRef.current === rampAddress) {
      const newPrunedListingIds = [...existingPrunedIds, ...listingIdsToPrune];
      updateStoredPrunedIds(rampAddress, newPrunedListingIds);

      setActiveListings(fetchedActiveListings);

      // Set ticketIdsToFetch, triggering fetchTicketsBatch
      const uniqueTicketIdsToFetch = [...new Set(ticketIdsToFetch)];
      if (uniqueTicketIdsToFetch.length > 0) {
        setShouldFetchActiveListingTickets(true);

        setActiveListingTicketIds(uniqueTicketIdsToFetch);
      } else {
        setShouldFetchActiveListingTickets(false);
        
        setActiveListingTicketIds(null);
      }

      // Set listingIdsToFetch, triggering fetchActiveListings
      const fetchedActiveListingIds = fetchedActiveListings.map(listing => BigInt(listing.listingId));
      if (fetchedActiveListingIds.length > 0) {
        setActiveListingIds(fetchedActiveListingIds);
        
        setShouldFetchActiveListingOrders(true);
      } else {
        setShouldFetchActiveListingOrders(false);

        setActiveListingIds(null);
      }
    }
  };
  
  const initializeListingIdsToFetch = (currentListingCounter: bigint, storedlistingIdsToPrune: bigint[]): bigint[] => {
    if (currentListingCounter) {
      const prunedIdsSet = new Set(storedlistingIdsToPrune.map(id => id.toString()));
      const listingIds = [];
  
      for (let i = 0; i < currentListingCounter; i++) {
        const listingId = BigInt(i).toString();
        if (!prunedIdsSet.has(listingId)) {
          listingIds.push(BigInt(listingId));
        }
      }
  
      return listingIds;
    } else {
      return [];
    }
  };
  
  const fetchingListingsBatch = async (listingIdBatch: bigint[]) => {
    try {
      // function getListings(uint256[] memory _listingIds) external view returns (Listing[] memory listingInfo)
      const data = await readContract({
        address: swapTicketExchangeAddress as `0x${string}`,
        abi: swapTicketExchangeAbi as Abi,
        functionName: 'getListings',
        args: [listingIdBatch],
        account: CALLER_ACCOUNT,
      });
  
      return data;
    } catch (error) {
      console.error('Error fetching listings batch:', error);
      
      return [];
    }
  };
  
  const sanitizeRawListings = (rawListingsData: any[]) => {
    const sanitizedListings: Listing[] = [];
  
    for (let i = rawListingsData.length - 1; i >= 0; i--) {
      const listingWithIdData = rawListingsData[i];
      const listingData = listingWithIdData.listing;
      
      const listing: Listing = {
        listingId: listingWithIdData.listingId,
        seller: listingData.seller.toString(),
        price: listingData.price,
        createdAt: listingData.createdAt,
        expiration: listingData.expiration,
        unfilledTicketIds: listingData.unfilledTickets,
        pendingTicketIds: listingData.pendingTickets,
        eventId: listingData.eventId,
        encryptionKey: listingData.encryptionKey.substring(2),
      };
  
      sanitizedListings.push(listing);
    }
  
    return sanitizedListings;
  };

  const sanitizeRawOrders = (rawOrdersData: any[]): Order[] => {
    const sanitizedOrders: Order[] = [];
  
    for (let i = rawOrdersData.length - 1; i >= 0; i--) {
      const orderWithIdData = rawOrdersData[i];
      const orderData = orderWithIdData.order;
      
      const order: Order = {
        orderId: orderWithIdData.orderId,
        buyer: orderData.buyer,
        encryptedEmail: orderData.encryptedEmail,
        emailHash: orderData.emailHash,
        listingId: orderData.listingId,
        ticketIds: orderData.ticketIds,
        transferId: orderData.transferId,
        purchaseValue: orderData.purchaseValue,
        createdAt: orderData.createdAt,
        refundInitiatedAt: orderData.refundInitiatedAt,
      };
  
      sanitizedOrders.push(order);
    }

    return sanitizedOrders;
  };

  return (
    <ListingsContext.Provider
      value={{
        activeListingsStore,

        refetchListingCounter,
        shouldFetchListingCounter,

        refetchActiveListings,
        shouldFetchActiveListings
      }}
    >
      {children}
    </ListingsContext.Provider>
  );
};

export default Listings;

import { createContext } from 'react';

import { DetailedTicket, Order, SellerListingsStore } from '@helpers/types';


interface SellersValues {
  sellerListingsStore: SellerListingsStore | null;
  refetchSellerListingStore: (() => void) | null;
  shouldFetchSellerListings: boolean;

  sellerOrders: Order[] | null;
  refetchSellerOrders: (() => void) | null;
  shouldFetchSellerListingOrders: boolean;

  sellerTickets: DetailedTicket[] | null;
  refetchSellerTickets: (() => void) | null;
  shouldFetchSellerTickets: boolean;
};

const defaultValues: SellersValues = {
  sellerListingsStore: null,
  refetchSellerListingStore: null,
  shouldFetchSellerListings: false,

  sellerOrders: null,
  refetchSellerOrders: null,
  shouldFetchSellerListingOrders: false,

  sellerTickets: null,
  shouldFetchSellerTickets: false,
  refetchSellerTickets: null,
};

const SellersContext = createContext<SellersValues>(defaultValues);

export default SellersContext;

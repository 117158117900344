import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro'
import { ArrowLeft } from 'react-feather';
import Link from '@mui/material/Link';

import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { RowBetween } from '@components/layouts/Row';
import { ProofTable } from '@components/Listings/NewListing/VerifyTickets/ProofTable';
import { commonStrings } from '@helpers/strings';
import { parseProof } from '@helpers/parseProof';
import { LoginStatus, VerifyTicketTransactionStatus } from '@helpers/types';
import { VerifyInstructions } from '@components/Listings/NewListing/VerifyTickets/VerifyInstructions';
import useAccount from '@hooks/contexts/useAccount';
import useEvents from '@hooks/contexts/useEvents';
import useSellers from '@hooks/contexts/useSellers';
import useVerifyTicketsTransaction from '@hooks/transactions/useVerifyTickets';


interface VerifyTicketsFormProps {
  handleBackClick: () => void;
};

export const VerifyTicketsForm: React.FC<VerifyTicketsFormProps> = ({
  handleBackClick
}) => {
  /*
   * Context
   */

  const { loginStatus } = useAccount();
  const { refetchEventIds } = useEvents();
  const { sellerTickets, refetchSellerTickets } = useSellers();

  /*
   * State
   */

  const [sellerExistingTicketsRawIds, setSellerExistingTicketsRawIds] = useState<string[]>([]);

  const [verifyTicketStatus, setVerifyTicketStatus] = useState<string>(VerifyTicketTransactionStatus.DEFAULT);
  
  const [verifiedTicketProofs, setVerifiedTicketProofs] = useState<string[]>([]);

  /*
   * Hooks
   */

  const onSuccessCallback = useCallback((data: any) => {
    console.log('verifyTickets Succeeded: ', data);

    // Refetch all event IDs in case registering tickets created new events
    refetchEventIds?.();

    // Refetch all of the seller's tickets, no dependencies
    refetchSellerTickets?.();
  }, [refetchEventIds, refetchSellerTickets]);

  const {
    writeVerifyTicketsAsync,
    ticketProofsInput,
    setTicketProofsInput,
    setShouldConfigureVerifyTicketsWrite,
    signVerifyTicketsTransactionStatus,
    mineVerifyTicketsTransactionStatus,
    // transactionHash,
  } = useVerifyTicketsTransaction(onSuccessCallback);

  useEffect(() => {
    setShouldConfigureVerifyTicketsWrite(verifyTicketStatus === VerifyTicketTransactionStatus.VALID);
  }, [verifyTicketStatus, setShouldConfigureVerifyTicketsWrite]);

  useEffect(() => {
    if (sellerTickets) {
      const rawExistingTicketIds = sellerTickets.map(ticket => ticket.ticketId);

      setSellerExistingTicketsRawIds(rawExistingTicketIds); 
    } else {
      setSellerExistingTicketsRawIds([]);
    }
  }, [sellerTickets]);

  useEffect(() => {
    if (verifiedTicketProofs.length > 0) {
      setTicketProofsInput(verifiedTicketProofs.map(proof => parseProof(proof)));
    } else {
      setTicketProofsInput([]);
    }
  }, [verifiedTicketProofs, setTicketProofsInput]);

  useEffect(() => {
    const updateVerifyTicketsStatus = async () => {
      const successfulVerificationTransaction = mineVerifyTicketsTransactionStatus === 'success';
      if (successfulVerificationTransaction) {
        setVerifyTicketStatus(VerifyTicketTransactionStatus.TRANSACTION_SUCCEEDED);
      } else {
        const ticketsSelected = ticketProofsInput.length > 0;

        if (ticketsSelected) {
          const signingVerifyTicketsTransaction = signVerifyTicketsTransactionStatus === 'loading';
          const miningVerifyTicketsTransaction = mineVerifyTicketsTransactionStatus === 'loading';

          if (signingVerifyTicketsTransaction) {
            setVerifyTicketStatus(VerifyTicketTransactionStatus.TRANSACTION_SIGNING);
          } else if (miningVerifyTicketsTransaction) {
            setVerifyTicketStatus(VerifyTicketTransactionStatus.TRANSACTION_MINING);
          } else {
            setVerifyTicketStatus(VerifyTicketTransactionStatus.VALID);
          }
        } else {
          setVerifyTicketStatus(VerifyTicketTransactionStatus.DEFAULT);
        }
      }
    }

    updateVerifyTicketsStatus();
  }, [
      ticketProofsInput,
      signVerifyTicketsTransactionStatus,
      mineVerifyTicketsTransactionStatus,
    ]
  );

  /*
   * Helpers
   */

  const ctaOnClick = async () => {
    switch (verifyTicketStatus) {
      case VerifyTicketTransactionStatus.VALID:
        try {
          await writeVerifyTicketsAsync?.();
        } catch (error) {
          console.log('writeVerifyTicketsAsync failed: ', error);
        }
        break;

      case VerifyTicketTransactionStatus.TRANSACTION_SUCCEEDED:
        handleBackClick();
        break;

      default:
        break;
    }
  }

  /*
   * Component
   */

  return (
    <Container>
      <TitleContainer>
        <RowBetween style={{ padding: '0.25rem 0rem 0.25rem 0rem' }}>
          <div style={{ flex: 0.5 }}>
            <button
              onClick={handleBackClick}
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            >
              <StyledArrowLeft/>
            </button>
          </div>

          <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
            Verify Tickets
          </ThemedText.HeadlineSmall>

          <div style={{ flex: 0.5 }}/>
        </RowBetween>

        <InstructionContainer>
          {commonStrings.get('PROOF_FORM_VERIFY_TICKET_INSTRUCTIONS')}

          {loginStatus === LoginStatus.EOA && (
            <>
              {commonStrings.get('TICKET_VERIFICATION_ETH_REQUIRED')}

              <Link
                href={'https://bridge.base.org/deposit'}
                target='_blank'>
                  Base Bridge ↗
              </Link>
            </>
          )}
        </InstructionContainer>

        <VerifyInstructions/>
      </TitleContainer>

      <VerticalDivider/>

      <ProofTable
        sellerRawTicketIds={sellerExistingTicketsRawIds}
        setProofsToSubmit={setVerifiedTicketProofs}
        handleSubmitTransactionPressed={ctaOnClick}
        proofSelectionStatus={verifyTicketStatus}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 660px;
`;

const TitleContainer = styled.div`
  padding: 1.5rem;
  background-color: ${colors.container};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  
  @media (min-width: 600px) {
    max-width: 606px;
    border-radius: 16px;
  }
`;

const InstructionContainer = styled.span`
  display: block;
  padding: 1rem 0.5rem;
  color: #333;
  text-align: left;
  line-height: 1.4;
  font-size: 15px;
`;

const StyledArrowLeft = styled(ArrowLeft)`
  color: #333;
`;

const VerticalDivider = styled.div`
  height: 24px;
  width: 1px;
  background-color: ${colors.defaultBorderColor};
  margin: 0 auto;
`;

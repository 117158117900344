import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import styled from 'styled-components';
import Link from '@mui/material/Link';

import { TicketsTable } from '@components/Listings/NewListing/TicketsTable';
import { EmailWarningModal } from '@components/Listings/NewListing/EmailWarningModal';
import { Summary } from '@components/Listings/NewListing/Summary';
import { Button } from '@components/common/Button';
import { RowBetween } from '@components/layouts/Row';
import { ThemedText } from '@theme/text';
import { Input } from '@components/Listings/NewListing/Input';
import { LoginStatus, NewListingTransactionStatus, Event } from '@helpers/types';
import { toUsdcString } from '@helpers/units';
import { commonStrings } from '@helpers/strings';
import { getPublicKeyFromAccount } from '@helpers/messagEncryption';
import { subscribeToBidNotifications } from '@helpers/notificationService';
import { colors } from '@theme/colors';
import useAccount from '@hooks/contexts/useAccount';
import useBalances from '@hooks/contexts/useBalance';
import useSellers from '@hooks/contexts/useSellers';
import useCreateListingTransaction from '@hooks/transactions/useCreateListing';


interface NewListingProps {
  handleVerifyTicketsPressed: () => void;
  handleBackClick: () => void;
}
 
export const NewListing: React.FC<NewListingProps> = ({
  handleVerifyTicketsPressed,
  handleBackClick
}) => {
  /*
   * Contexts
   */

  const { usdcBalance } = useBalances();
  const { accountListingHash, createAccountListingHash, isLoggedIn, loginStatus } = useAccount();
  const { refetchSellerListingStore, sellerTickets, refetchSellerTickets } = useSellers();

  /*
   * State
   */

  const [eventIdByTicketId, setEventIdByTicketId] = useState<{ [ticketId: string]: string }>({});
  
  const [newListingState, setNewListingState] = useState(NewListingTransactionStatus.DEFAULT);

  const [eventFromSelectedTicket, setEventFromSelectedTicket] = useState<Event | null>(null);

  const [notificationEmailInput, setNotificationEmailInput] = useState('');

  const [showWarningModal, setShowWarningModal] = useState(false);

  /*
   * Hooks
   */

  const onSuccessCallback = useCallback(async(data: any) => {
    console.log('createListing Succeeded: ', data);

    // Call the notification service
    if (notificationEmailInput && data) {
      await subscribeToBidNotifications(data, notificationEmailInput);
    }

    // Has many dependencies, but we only need to refetch sellerListingsStore
    refetchSellerListingStore?.();
    setNotificationEmailInput('');
    setPriceInput('');

    // Update tickets to prune from tickets table after successfully listing
    refetchSellerTickets?.();
  }, [refetchSellerListingStore, refetchSellerTickets, notificationEmailInput]);

  const {
    writeCreateListingAsync,
    ticketIdsInput,
    priceInput,
    setTicketIdsInput,
    setPriceInput,
    setExpirationInput,
    setEncryptEmailKeyInput,
    setShouldConfigureCreateListingWrite,
    signCreateListingTransactionStatus,
    mineCreateListingTransactionStatus,
    // transactionHash,
  } = useCreateListingTransaction(onSuccessCallback);

  useEffect(() => {
    const storedEmail = localStorage.getItem('zkp2p_notification_email');
    if (storedEmail) {
      setNotificationEmailInput(storedEmail);
    }
  }, []);

  useEffect(() => {
    if (!accountListingHash && isLoggedIn && createAccountListingHash) {
      createAccountListingHash();
    }

    if (accountListingHash && setEncryptEmailKeyInput) {
      const publicKey = getPublicKeyFromAccount(accountListingHash);
      setEncryptEmailKeyInput(publicKey);
    } else {
      setEncryptEmailKeyInput('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountListingHash, isLoggedIn]);

  useEffect(() => {
    const updateNewListingState = async () => {
      const successfulCreateListingTransaction = mineCreateListingTransactionStatus === 'success';

      if (successfulCreateListingTransaction) {
        setNewListingState(NewListingTransactionStatus.TRANSACTION_SUCCEEDED);
      } else {
        const ticketsSelected = ticketIdsInput.length > 0;

        if (ticketsSelected) {
          const eventIdsForSelectedTicketIds = ticketIdsInput.map(ticketId => eventIdByTicketId[ticketId]);
          const missingEventIds = eventIdsForSelectedTicketIds.filter(eventId => !eventId);
          const allTicketsHaveEvents = missingEventIds.length === 0;

          const uniqueEventIds = Array.from(new Set(eventIdsForSelectedTicketIds));
          const ticketsFromSameEvent = uniqueEventIds.length === 1;

          if (allTicketsHaveEvents && ticketsFromSameEvent) {
            if (priceInput) {
              const signingCreateListingTransaction = signCreateListingTransactionStatus === 'loading';
              const miningCreateListingTransaction = mineCreateListingTransactionStatus === 'loading';
  
              if (signingCreateListingTransaction) {
                setNewListingState(NewListingTransactionStatus.TRANSACTION_SIGNING);
              } else if (miningCreateListingTransaction){
                setNewListingState(NewListingTransactionStatus.TRANSACTION_MINING);
              } else {
                setNewListingState(NewListingTransactionStatus.VALID);
              }
            } else {
              setNewListingState(NewListingTransactionStatus.MISSING_PRICE);
            }
          } else {
            setNewListingState(NewListingTransactionStatus.BAD_TICKET_SELECTION);
          }
        } else {
          setNewListingState(NewListingTransactionStatus.DEFAULT);
        }
      }
    }

    updateNewListingState();
  }, [
      priceInput,
      eventIdByTicketId,
      sellerTickets,
      ticketIdsInput,
      signCreateListingTransactionStatus,
      mineCreateListingTransactionStatus
    ]
  );

  useEffect(() => {
    const ticketIdsByEventId: { [ticketId: string]: string } = {};
    if (sellerTickets) {
      sellerTickets.forEach(ticket => {
        const eventId = ticket.eventId;
        const ticketId = ticket.ticketId;

        ticketIdsByEventId[ticketId] = eventId; 
      });
    }

    setEventIdByTicketId(ticketIdsByEventId);
  }, [sellerTickets]);

  useEffect(() => {
    if (eventFromSelectedTicket) {
      setExpirationInput(eventFromSelectedTicket.startTime);
    } else {
      setExpirationInput(null);
    }
  }, [eventFromSelectedTicket, setExpirationInput]);

  useEffect(() => {
    console.log('newListingState: ', newListingState);

    setShouldConfigureCreateListingWrite(newListingState === NewListingTransactionStatus.VALID);
  }, [newListingState, setShouldConfigureCreateListingWrite]);

  /*
   * Helpers
   */

  const isSummaryLoading = (): boolean => {
    switch (newListingState) {
      case NewListingTransactionStatus.VALID:
      case NewListingTransactionStatus.TRANSACTION_SIGNING:
      case NewListingTransactionStatus.TRANSACTION_MINING:
        return false;

      default:
        return true;
    }
  }

  function isValidInput(value: string) {
    const isValid = /^-?\d*(\.\d{0,2})?$/.test(value);
    
    return parseFloat(value) >= 0 && isValid;
  }

  const ctaDisabled = (): boolean => {
    switch (newListingState) {
      case NewListingTransactionStatus.DEFAULT:
      case NewListingTransactionStatus.MISSING_PRICE:
      case NewListingTransactionStatus.TRANSACTION_SIGNING:
      case NewListingTransactionStatus.TRANSACTION_MINING:
      case NewListingTransactionStatus.BAD_TICKET_SELECTION:
        return true;

      case NewListingTransactionStatus.VALID:
      default:
        return false;
    }
  }

  const ctaLoading = (): boolean => {
    switch (newListingState) {
      case NewListingTransactionStatus.TRANSACTION_SIGNING:
      case NewListingTransactionStatus.TRANSACTION_MINING:
        return loginStatus === LoginStatus.AUTHENTICATED;

      default:
        return false;
    }
  };

  const ctaText = (): string => {
    switch (newListingState) {
      case NewListingTransactionStatus.MISSING_PRICE:
        return 'Input price per ticket';

      case NewListingTransactionStatus.TRANSACTION_SIGNING:
        return 'Signing Transaction';

      case NewListingTransactionStatus.TRANSACTION_MINING:
        return 'Mining Transaction';

      case NewListingTransactionStatus.VALID:
        return 'Create Listing';

      case NewListingTransactionStatus.TRANSACTION_SUCCEEDED:
        return 'Go to Listings';

      case NewListingTransactionStatus.BAD_TICKET_SELECTION:
        return 'Must be from same event';

      case NewListingTransactionStatus.DEFAULT:
      default:
        return 'Select tickets to list';
    }
  }

  const ctaOnClick = async () => {
    switch (newListingState) {
      case NewListingTransactionStatus.VALID:
        if (!notificationEmailInput.trim()) {
          setShowWarningModal(true);
        } else {
          try {
            await writeCreateListingAsync?.();
          } catch (error) {
            console.log('writeCreateListingAsync failed: ', error);
          }
        }
        break;

      case NewListingTransactionStatus.TRANSACTION_SUCCEEDED:
        handleBackClick();
        break;

      default:
        break;
    }
  }

  const handleConfirmWithoutEmail = async () => {
    setShowWarningModal(false);
    try {
      await writeCreateListingAsync?.();
    } catch (error) {
      console.log('writeCreateListingAsync failed: ', error);
    }
  }

  const usdcBalanceLabel = useMemo(() => {
    if (isLoggedIn && usdcBalance !== null) {
      return `Balance: ${toUsdcString(usdcBalance, true)}`
    } else {
      return '';
    }
  }, [usdcBalance, isLoggedIn]);

  /*
   * Handlers
   */

  const handleInputChange = (value: string, setInputFunction: React.Dispatch<React.SetStateAction<string>>) => {
    if (value === "") {
      setInputFunction('');
    } else if (value === ".") {
      setInputFunction('0.');
    } else if (isValidInput(value)) {
      setInputFunction(value);
    }
  };

  const handleNotificationEmailChange = (email: string) => {
    setNotificationEmailInput(email);
    localStorage.setItem('zkp2p_notification_email', email);
  };

  return (
    <Container>
      <TitleContainer>
        <div style={{ flex: 0.5 }}>
          <button
            onClick={handleBackClick}
            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
          >
            <StyledArrowLeft/>
          </button>
        </div>

        <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
          New Listing
        </ThemedText.HeadlineSmall>

        <div style={{ flex: 0.5 }}/>
      </TitleContainer>

      <Content>
        <InstructionContainer>
          { commonStrings.get('NEW_LISTING_INSTRUCTIONS') }
          <Link href="https://docs.zkp2p.xyz/zkp2p/user-guides/off-ramping/fetch-your-venmo-id" target="_blank">
            Learn More ↗
          </Link>
        </InstructionContainer>

        <InputsContainer>
          <TicketsTable
            handleVerifyTicketsPressed={handleVerifyTicketsPressed}
            setSelectedTicketIds={setTicketIdsInput}
            setSelectedEventForTickets={setEventFromSelectedTicket}
          />

          <Input
            label="Price per Ticket"
            name={`priceInput`}
            value={priceInput}
            onChange={(e) => handleInputChange(e.currentTarget.value, setPriceInput)}
            type="number"
            inputLabel="USDC"
            placeholder="150"
            accessoryLabel={usdcBalanceLabel}
            helperText={commonStrings.get('NEW_LISTING_PRICE_TOOLTIP')}
          />

          <Input
            label="Notification Email (Optional)"
            name={`notificationEmailInput`}
            value={notificationEmailInput}
            onChange={(e) => handleNotificationEmailChange(e.currentTarget.value)}
            type="email"
            placeholder="vitalik@zkp2p.xyz"
            helperText={commonStrings.get('NEW_LISTING_NOTIFICATION_EMAIL_TOOLTIP')}
          />

          <Summary
            isLoading={isSummaryLoading()}
            pricePerTicket={priceInput}
            ticketCount={ticketIdsInput.length}
            listingFee={0.05}
          />

          <ButtonContainer>
            <Button
              fullWidth={true}
              disabled={ctaDisabled()}
              loading={ctaLoading()}
              onClick={async () => {
                ctaOnClick();
              }}>
              { ctaText() }
            </Button>
          </ButtonContainer>
        </InputsContainer>
      </Content>
      
      {showWarningModal && (
        <EmailWarningModal
          onBackClick={() => setShowWarningModal(false)}
          onConfirm={handleConfirmWithoutEmail}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  background-color: ${colors.container};
  padding: 1.5rem;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);

  @media (min-width: 600px) {
    max-width: 552px;
    border-radius: 16px;
  }
`;

const TitleContainer = styled(RowBetween)`
  padding: 0.25rem 0rem 0.25rem 0rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const InstructionContainer = styled.span`
  display: block;
  padding: 1rem 0.5rem 0rem 0.5rem; 
  color: #333;
  line-height: 1.4;
  font-size: 15px;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ButtonContainer = styled.div`
  padding-top: 0.5rem;
`;

const StyledArrowLeft = styled(ArrowLeft)`
  color: #333;
`;

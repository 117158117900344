import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import { Proof } from '@helpers/types';
import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useVerifyTicketsTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { verifiedTicketRegistryAddress, verifiedTicketRegistryAbi } = useSmartContracts();

  /*
   * State
   */ 

  const [ticketProofsInput, setTicketProofsInput] = useState<Proof[]>([]);

  const [shouldConfigureVerifyTicketsWrite, setShouldConfigureVerifyTicketsWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  const { config: writeVerifyTicketsConfig } = usePrepareContractWrite({
    address: verifiedTicketRegistryAddress,
    abi: verifiedTicketRegistryAbi,
    functionName: 'verifyTickets',
    args: [
      ticketProofsInput,
    ],
    onError(error: any) {
      console.log('writeVerifyTicketsConfig failed: ', error.message);

      setShouldConfigureVerifyTicketsWrite(false);

      onError?.(error);
    },
    enabled: shouldConfigureVerifyTicketsWrite,
  });

  const {
    data: submitVerifyTicketsResult,
    status: signVerifyTicketsTransactionStatus,
    writeAsync: writeVerifyTicketsAsync,
  } = useContractWrite(writeVerifyTicketsConfig);

  const {
    status: mineVerifyTicketsTransactionStatus,
  } = useWaitForTransaction({
    hash: submitVerifyTicketsResult ? submitVerifyTicketsResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeVerifyTicketsAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeVerifyTicketsAsync,
    ticketProofsInput,
    setTicketProofsInput,
    setShouldConfigureVerifyTicketsWrite,
    signVerifyTicketsTransactionStatus,
    mineVerifyTicketsTransactionStatus,
    transactionHash: submitVerifyTicketsResult?.hash,
  };
};

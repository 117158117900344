export const abi = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_owner",
        "type": "address"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "exchange",
        "type": "address"
      }
    ],
    "name": "ExchangeAdded",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "exchange",
        "type": "address"
      }
    ],
    "name": "ExchangeRemoved",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "bytes32",
        "name": "ticketId",
        "type": "bytes32"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "exchange",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "listingId",
        "type": "uint256"
      }
    ],
    "name": "ListingStatusAdded",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "bytes32",
        "name": "ticketId",
        "type": "bytes32"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "exchange",
        "type": "address"
      }
    ],
    "name": "ListingStatusRemoved",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "bytes32",
        "name": "eventId",
        "type": "bytes32"
      },
      {
        "indexed": false,
        "internalType": "string",
        "name": "rawId",
        "type": "string"
      },
      {
        "indexed": false,
        "internalType": "string",
        "name": "section",
        "type": "string"
      },
      {
        "indexed": false,
        "internalType": "string",
        "name": "row",
        "type": "string"
      },
      {
        "indexed": false,
        "internalType": "string",
        "name": "seat",
        "type": "string"
      }
    ],
    "name": "TicketVerified",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_exchange",
        "type": "address"
      }
    ],
    "name": "addExchange",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "eventRegistry",
    "outputs": [
      {
        "internalType": "contract IEventRegistry",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "exchanges",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getExchanges",
    "outputs": [
      {
        "internalType": "address[]",
        "name": "",
        "type": "address[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32[]",
        "name": "_tickets",
        "type": "bytes32[]"
      }
    ],
    "name": "getTickets",
    "outputs": [
      {
        "components": [
          {
            "internalType": "bytes32",
            "name": "ticketId",
            "type": "bytes32"
          },
          {
            "components": [
              {
                "internalType": "address",
                "name": "owner",
                "type": "address"
              },
              {
                "internalType": "string",
                "name": "rawId",
                "type": "string"
              },
              {
                "internalType": "enum ITicketRegistry.TicketStatus",
                "name": "status",
                "type": "uint8"
              },
              {
                "internalType": "string",
                "name": "section",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "row",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "seat",
                "type": "string"
              },
              {
                "internalType": "bytes32",
                "name": "eventId",
                "type": "bytes32"
              },
              {
                "internalType": "address",
                "name": "exchange",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "listingId",
                "type": "uint256"
              }
            ],
            "internalType": "struct ITicketRegistry.Ticket",
            "name": "ticket",
            "type": "tuple"
          }
        ],
        "internalType": "struct ITicketRegistry.TicketWithId[]",
        "name": "ticketInfo",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_user",
        "type": "address"
      }
    ],
    "name": "getUserTickets",
    "outputs": [
      {
        "components": [
          {
            "internalType": "bytes32",
            "name": "ticketId",
            "type": "bytes32"
          },
          {
            "components": [
              {
                "internalType": "address",
                "name": "owner",
                "type": "address"
              },
              {
                "internalType": "string",
                "name": "rawId",
                "type": "string"
              },
              {
                "internalType": "enum ITicketRegistry.TicketStatus",
                "name": "status",
                "type": "uint8"
              },
              {
                "internalType": "string",
                "name": "section",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "row",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "seat",
                "type": "string"
              },
              {
                "internalType": "bytes32",
                "name": "eventId",
                "type": "bytes32"
              },
              {
                "internalType": "address",
                "name": "exchange",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "listingId",
                "type": "uint256"
              }
            ],
            "internalType": "struct ITicketRegistry.Ticket",
            "name": "ticket",
            "type": "tuple"
          }
        ],
        "internalType": "struct ITicketRegistry.TicketWithId[]",
        "name": "ticketInfo",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "contract IEventRegistry",
        "name": "_eventRegistry",
        "type": "address"
      },
      {
        "internalType": "contract IVerifyTicketProcessor",
        "name": "_verifyTicketProcessor",
        "type": "address"
      },
      {
        "internalType": "address[]",
        "name": "_exchanges",
        "type": "address[]"
      }
    ],
    "name": "initialize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "isExchange",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "isInitialized",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_exchange",
        "type": "address"
      }
    ],
    "name": "removeExchange",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_listingId",
        "type": "uint256"
      },
      {
        "internalType": "bytes32[]",
        "name": "_ticketIds",
        "type": "bytes32[]"
      }
    ],
    "name": "setTicketsToListed",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32[]",
        "name": "_ticketIds",
        "type": "bytes32[]"
      }
    ],
    "name": "setTicketsToSold",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32[]",
        "name": "_ticketIds",
        "type": "bytes32[]"
      }
    ],
    "name": "setTicketsToVerified",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "name": "tickets",
    "outputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "string",
        "name": "rawId",
        "type": "string"
      },
      {
        "internalType": "enum ITicketRegistry.TicketStatus",
        "name": "status",
        "type": "uint8"
      },
      {
        "internalType": "string",
        "name": "section",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "row",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "seat",
        "type": "string"
      },
      {
        "internalType": "bytes32",
        "name": "eventId",
        "type": "bytes32"
      },
      {
        "internalType": "address",
        "name": "exchange",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "listingId",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "userTickets",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "verifyTicketProcessor",
    "outputs": [
      {
        "internalType": "contract IVerifyTicketProcessor",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "components": [
              {
                "internalType": "string",
                "name": "provider",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "parameters",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "context",
                "type": "string"
              }
            ],
            "internalType": "struct Claims.ClaimInfo",
            "name": "claimInfo",
            "type": "tuple"
          },
          {
            "components": [
              {
                "components": [
                  {
                    "internalType": "bytes32",
                    "name": "identifier",
                    "type": "bytes32"
                  },
                  {
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                  },
                  {
                    "internalType": "uint32",
                    "name": "timestampS",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "epoch",
                    "type": "uint32"
                  }
                ],
                "internalType": "struct Claims.CompleteClaimData",
                "name": "claim",
                "type": "tuple"
              },
              {
                "internalType": "bytes[]",
                "name": "signatures",
                "type": "bytes[]"
              }
            ],
            "internalType": "struct Claims.SignedClaim",
            "name": "signedClaim",
            "type": "tuple"
          }
        ],
        "internalType": "struct IProxyBaseProcessor.Proof[]",
        "name": "_proofs",
        "type": "tuple[]"
      }
    ],
    "name": "verifyTickets",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];

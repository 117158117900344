import { createContext } from 'react';

import { Ticket } from '@helpers/types';


interface TicketsValues {
  fetchTicketsBatch: ((ticketIds: string[]) => Promise<Ticket[]>) | null;

  fetchExchanges: (() => Promise<string[]>) | null;

  fetchUserTickets: ((userAddress: string) => Promise<Ticket[]>) | null;
};

const defaultValues: TicketsValues = {
  fetchTicketsBatch: null,

  fetchExchanges: null,
  
  fetchUserTickets: null,
};

const TicketsContext = createContext<TicketsValues>(defaultValues);

export default TicketsContext;

import React, { CSSProperties } from 'react';

import { colors } from '@theme/colors';
import { SVGIcon } from './SVGIcon';
import './SVGIcon.css';

interface SVGIconThemedProps {
    iconName?: string,
    icon?: string,
    size?: string,
    width?: string,
    height?: string,
    themeMode?: string,
    onClick?: () => void,
    className?: string,
    backgroundColor?: string
  };

export const SVGIconThemed: React.FC<SVGIconThemedProps> = ({
    iconName = 'ethereum-logo',
    icon,
    size,
    width,
    height,
    themeMode = 'dark',
    onClick,
    className,
    backgroundColor
}) => {
  const styles: CSSProperties = {
    display: 'flex'
  };
  console.log('icon', icon);
  const showedIconName = typeof icon === 'string'
    ? `${themeMode}-${icon}`
    : iconName;
    console.log('showedIconName', showedIconName);
  styles.backgroundColor = backgroundColor || colors.defaultSVGIconBackgroundColor; 

  const showedClass = `svg-icon-${size} ${className}`;

  return (
    <div
      style={styles}
      onClick={onClick}
      className={showedClass}
    >
        <SVGIcon iconName={showedIconName} width={width} height={height} />
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Map } from 'react-feather';

import { RowBetween } from '@components/layouts/Row';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import useFetchVenueData, { FetchVenueDataResponse } from '@hooks/useFetchVenueData';


interface SeatMapProps {
  arenaName: string | undefined;
  sections: string[];
  eventUrl:string | undefined;
  
};

export const SeatMap: React.FC<SeatMapProps> = ({
  arenaName,
  sections,
  eventUrl,
}) => {

  /*
   * State
   */

  const [venueSvgData, setVenueSvgData] = useState<FetchVenueDataResponse | null>(null);

  const [venueOverlay, setVenueOverlay] = useState<JSX.Element[] | null>(null);

  /*
   * Hooks
   */

  const { fetchVenueData } = useFetchVenueData();

  useEffect(() => {
    const fetchData = async () => {
      if (arenaName && fetchVenueData) {
        const fetchedVenueSvgData = await fetchVenueData(eventUrl?.split('/').pop() ?? '');

        setVenueSvgData(fetchedVenueSvgData);
      } else {
        setVenueSvgData(null);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arenaName]);

  const [aspectRatio, setAspectRatio] = useState<string>('1 / 1');
  const [viewBox, setViewBox] = useState<string>('0 0 100 100');

  useEffect(() => {
    if (venueSvgData) {
      const { svg_data } = venueSvgData;
      const { segments } = svg_data;

      const svgOverlays = segments.map(segment => {
        const isHighlighted = sections.includes(segment.name);
        
        return generateSVG(segment.shapes[0], isHighlighted);
      });

      const scalar = svg_data.height / svg_data.image.height;
      const viewBox = `0 0 ${svg_data.image.width * scalar} ${svg_data.image.height * scalar}`;

      setVenueOverlay(svgOverlays);
      setAspectRatio(`${svg_data.image.width} / ${svg_data.image.height}`);
      setViewBox(viewBox);
    } else {
      setVenueOverlay(null);
    }
  }, [venueSvgData, sections]);

  function generateSVG(shapeData: any, isHighlighted: boolean) {
    return (
      <>
        <path
          d={shapeData.path}
          fill={isHighlighted ? colors.buttonDefault : "transparent"}
          stroke={isHighlighted ? "none" : colors.buttonDefault}
        />

        {shapeData.labels[0].text.split('\r').map((line: string, index: number) => (
          <text
            key={index}
            x={shapeData.labels[0].x}
            y={shapeData.labels[0].y + (index * shapeData.labels[0].size * 1.2)}
            fontSize={shapeData.labels[0].size}
            textAnchor="middle"
            dominantBaseline="central"
            fill={isHighlighted ? colors.white : colors.darkText}
            transform={`rotate(${shapeData.labels[0].angle || 0}, ${shapeData.labels[0].x}, ${shapeData.labels[0].y})`}
          >
            {line}
          </text>
        ))}
      </>
    );
  }

  return (
    <Container>
      <TitleRow>
        <ThemedText.HeadlineSmall>
          Seat Map
        </ThemedText.HeadlineSmall>
      </TitleRow>

      <Content>
        {!venueSvgData ? (
          <ErrorContainer>
            <MapIcon strokeWidth={1} />
            <ThemedText.DeprecatedBody>
              Attempting to load seat map...
            </ThemedText.DeprecatedBody>

            <ThemedText.DeprecatedBody>
              See <Link to={eventUrl ?? ''} target="_blank">Ticketmaster Event Page ↗</Link> if not loading
            </ThemedText.DeprecatedBody>
          </ErrorContainer>
        ) : (
          <>
            <MapContainer aspectRatio={aspectRatio}>
              <MapImage src={venueSvgData.img_link} alt="Arena Seat Map" />
              <SVGMap viewBox={viewBox}>{venueOverlay}</SVGMap>
            </MapContainer>
          </>
        )}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
`;

const Content = styled.div`
  background-color: ${colors.background};
  overflow: hidden;
  position: relative;
  padding: 0.5rem;

  @media (min-width: 600px) {
    border-radius: 16px;
  }
`;

const ErrorContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.background};
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const MapIcon = styled(Map)`
  ${IconStyle}
`;

const TitleRow = styled(RowBetween)`
  align-items: flex-end;
  color: ${colors.darkText};
  padding: 1rem;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    padding: 0 1.75rem;
  }
`;

const MapContainer = styled.div<{ aspectRatio: string }>`
  position: relative;
  width: 100%;
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
`;

const MapImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const SVGMap = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

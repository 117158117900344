import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { Trash2, Edit, Share } from 'react-feather';

import Spinner from '@components/common/Spinner';
import FlatTooltip from '@components/common/FlatTooltip';
import { colors } from '@theme/colors';


type iconType = 
  "trash" |
  "edit" |
  "share";

interface TransactionIconButtonProps {
  icon: iconType;
  text: string;
  disabled?: boolean;
  disabledText?: string;
  loading?: boolean;
  onClick?: () => void;
}

export const TransactionIconButton: React.FC<TransactionIconButtonProps> = ({
  icon,
  text,
  disabled = false,
  disabledText,
  loading = false,
  onClick,
}) => {
  /*
   * State
   */

  const [show, setShow] = useState<boolean>(false)

  /*
   * Hooks
   */

  const open = useCallback(() => 
    setShow(true)
  , [setShow]);
  const close = useCallback(() =>
    setShow(false)
  , [setShow]);

  /*
   * Helpers
   */

  const getIcon = (iconName: iconType) => {
    switch (iconName) {
      case "trash":
        return <StyledTrash disabled={disabled} />;

      case "edit":
        return <StyledEditIcon disabled={disabled} />;

      case "share":
        return <StyledShareIcon disabled={disabled} />;

      default:
        return null;
    }
  };

  /*
   * Component
   */

  return (
    <Container
      disabled={disabled || loading}
      $loading={loading}
      onClick={onClick}
    >
      {loading ? (
        <Spinner
          color={colors.darkText}
          size={18}
        />
      ) : (
        <FlatTooltip text={disabled ? disabledText || text : text} show={show}>
          <IconBorder onMouseEnter={open} onMouseLeave={close}>
            { getIcon(icon) }
          </IconBorder>
        </FlatTooltip>
      )}
    </Container>
  );
};

const Container = styled.button<{ $loading: boolean }>`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: ${colors.iconButtonDefault};

  &:hover:not([disabled]) {
    color: ${colors.iconButtonIconHover};

    * {
      color: ${colors.iconButtonIconHover};
    }
  }

  &:active:not([disabled]) {
    background: ${colors.iconButtonIconHover};
  }

  ${({ $loading }) =>
    $loading && css`
      cursor: wait;
    `
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: ${colors.iconButtonDefault};
  }
`;

const IconBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  padding: 8px;
  background-color: ${colors.iconButtonDefault};

  &:hover {
    background-color: ${colors.iconButtonHover};
  }
`;

const StyledTrash = styled(Trash2)<{ disabled: boolean }>`
  width: 18px;
  height: 18px;
  color: ${colors.darkText};

  cursor: pointer;

  ${({ disabled }) =>
    disabled && css`
      cursor: not-allowed;
      opacity: 0.5;
    background: ${colors.iconButtonDefault};
    `
  }
`;

const StyledEditIcon = styled(Edit)<{ disabled: boolean }>`
  height: 18px;
  width: 18px;
  color: ${colors.darkText};

  cursor: pointer;

  ${({ disabled }) =>
    disabled && css`
      cursor: not-allowed;
      opacity: 0.5;
    background: ${colors.iconButtonDefault};
    `
  }
`;

const StyledShareIcon = styled(Share)<{ disabled: boolean }>`
  height: 18px;
  width: 18px;
  color: ${colors.darkText};

  cursor: pointer;

  ${({ disabled }) =>
    disabled && css`
      cursor: not-allowed;
      opacity: 0.5;
    background: ${colors.iconButtonDefault};
    `
  }
`;

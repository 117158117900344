import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Link from '@mui/material/Link';

import useMediaQuery from '@hooks/useMediaQuery';
import { AccessoryButton } from '@components/common/AccessoryButton';
import { colors } from '@theme/colors';

import useBalances from '@hooks/contexts/useBalance';
import useBuyers from '@hooks/contexts/useBuyers';
import useFinalizeRefund from '@hooks/transactions/useFinalizeRefund';


export enum BuyerOrderStatus {
  WAITING_TRANSFER = 'waiting_transfer',
  WAITING_TO_ACCEPT = 'ready_to_accept',
  WAITING_REFUND = 'waiting_refund',
};

export enum BuyerOrderAction {
  NONE = 'none',
  INITIATE_REFUND = 'initiate_refund',
  FINALIZE_REFUND = 'finalize_refund',
};

interface OrderRowProps {
  status: BuyerOrderStatus;
  action: BuyerOrderAction;
  orderId: bigint;
  venueText: string;
  eventText: string;
  startTimeText: string;
  sectionText: string;
  rowText: string;
  seatText: string;
  orderPriceText: string;
  rowIndex: number;
  eventUrl: string;
  handleInitiateRefundPressed: () => void;
}

export const OrderRow: React.FC<OrderRowProps> = ({
  status,
  action,
  orderId,
  venueText,
  eventText,
  startTimeText,
  sectionText,
  rowText,
  seatText,
  orderPriceText,
  rowIndex,
  eventUrl,
  handleInitiateRefundPressed,
}: OrderRowProps) => {
  OrderRow.displayName = "OrderRow";

  /*
   * Context
   */
  
  const { refetchBuyerOrders } = useBuyers();
  const { refetchUsdcBalance } = useBalances();

  const isMobile = useMediaQuery() === 'mobile';

  /*
   * Hooks
   */

  const onSuccessCallback = useCallback((data: any) => {
    console.log('claimRefund Succeeded: ', data);

    refetchUsdcBalance?.();

    refetchBuyerOrders?.();
  }, [refetchBuyerOrders, refetchUsdcBalance]);

  const {
    writeFinalizeRefundAsync,
    shouldConfigureFinalizeRefundWrite,
    setOrderIdInput,
    setShouldConfigureFinalizeRefundWrite,
    // transactionHash,
  } = useFinalizeRefund(onSuccessCallback);

  useEffect(() => {
    const submitFinalizeRefund = async () => {
      if (shouldConfigureFinalizeRefundWrite && writeFinalizeRefundAsync) {
        try {
          await writeFinalizeRefundAsync?.();
        } catch (error) {
          console.log('writeDeleteListingAsync failed: ', error);

          setShouldConfigureFinalizeRefundWrite(false);

          setOrderIdInput(null);
        }
      };
    };

    submitFinalizeRefund();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writeFinalizeRefundAsync]);

  /*
  * Handlers
  */

  const handleFinalizeRefundPressed = ()=> {
    console.log('handleFinalizeRefundPressed: ', orderId);

    setOrderIdInput(orderId);

    setShouldConfigureFinalizeRefundWrite(true);
  };

  function renderStatus() {
    switch(status) {
      case BuyerOrderStatus.WAITING_TRANSFER:
        return 'Awaiting Transfer';

      case BuyerOrderStatus.WAITING_TO_ACCEPT:
        return 'Pending Accept';

      case BuyerOrderStatus.WAITING_REFUND:
        return 'Refund Pending';
      
      default:
        return null;
    }
  }

  function renderActionButton() { 
    if (action === BuyerOrderAction.INITIATE_REFUND) {
      return (
        <AccessoryButton
          onClick={handleInitiateRefundPressed}
          height={36}
          title={'Initiate Refund'}
        />
      );
    } else if (action === BuyerOrderAction.FINALIZE_REFUND) {
      return (
        <AccessoryButton
          onClick={() => handleFinalizeRefundPressed()}
          height={36}
          title={'Claim Refund'}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <Container>
      <OrderDetailsContainer isMobile={isMobile}>
        <RowNumberContainer>
          <SummaryLabel>
            <SummaryLabelTitle>{rowIndex + 1}</SummaryLabelTitle>
          </SummaryLabel>
        </RowNumberContainer>

        <StatusLabelContainer>
          <StatusLabel $status={status}>
            {renderStatus()}
          </StatusLabel>
        </StatusLabelContainer>

        <EventDetailsContainer>
          <SummaryLabelValue>
            <Link href={eventUrl} target="_blank">
              {eventText}
            </Link>
          </SummaryLabelValue>

          <SummaryLabelValue>
            {startTimeText}
          </SummaryLabelValue>

          <SummaryLabelValue>
            {venueText}
          </SummaryLabelValue>
        </EventDetailsContainer>

        <TicketDetailsContainer>
          <SummaryLabelValue>
            {sectionText}
          </SummaryLabelValue>
          
          <SummaryLabelValue>
            {rowText}
          </SummaryLabelValue>

          <SummaryLabelValue>
            {seatText}
          </SummaryLabelValue>
        </TicketDetailsContainer>

        <PriceDetailsContainer>
          <SummaryLabel>
            <TicketPriceLabel>
              {orderPriceText}
            </TicketPriceLabel>
          </SummaryLabel>
        </PriceDetailsContainer>

        <ActionsContainer>
          {renderActionButton()}
        </ActionsContainer>
      </OrderDetailsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const OrderDetailsContainer = styled.div<{isMobile?: boolean}>`
  width: 100%;
  display: grid;
  grid-template-columns: 0.75fr 1fr 3.5fr 1fr 1fr 1.5fr;
  grid-gap: 4px;
  align-items: center;
  padding: 1rem 0rem 0.75rem 0rem;
  line-height: 24px;
`;

const RowNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatusLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EventDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TicketDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PriceDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const SummaryLabel = styled.label`
  font-size: 15px;
  color: #FFFFFF;
`;

const StatusLabel = styled.label<{ $status: BuyerOrderStatus }>`
  font-size: 15px;
  text-align: center;
  color: ${props => props.$status === BuyerOrderStatus.WAITING_TRANSFER ? colors.orderStatusWaitingTransfer : colors.orderStatusWaitingToAccept};
`;

const SummaryLabelTitle = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
`;

const SummaryLabelValue = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
`;

const TicketPriceLabel = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
`;

import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { ChevronDown } from 'react-feather';

import { SummaryItem } from '@components/Event/BuyModal/SummaryItem';
import { colors } from '@theme/colors';


interface SummaryProps {
  isLoading: boolean;
  usdcBalance: string;
  pricePerTicket: string;
  ticketCount: number;
};

export const Summary: React.FC<SummaryProps> = ({
  isLoading,
  usdcBalance,
  pricePerTicket,
  ticketCount,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  /*
   * State
   */

  const [isOpen, setIsOpen] = useState(true);

  /*
   * Hooks
   */

  useEffect(() => {
    if (isLoading) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [isLoading]);

  /*
   * Helpers
   */

  const orderTotal = Number(pricePerTicket) * ticketCount;

  const orderTotalString = () => {
    if (ticketCount === 0 || pricePerTicket === '') {
      return 'Loading...';
    } else {
      return `${orderTotal} USDC`;
    }
  };

  const ticketsListedForString = () => {
    if (ticketCount === 0 || pricePerTicket === '') {
      return 'Loading...';
    } else if (ticketCount === 1) {
      return `${pricePerTicket} USDC`;
    } else {
      return `${ticketCount} x ${pricePerTicket} USDC`;
    }
  };
  
  const selectedTicketString = () => {
    if (ticketCount === 0) {
      return 'Loading...';
    } else{
      return `${ticketCount}`;
    }
  };

  const balanceString = () => {
    if (usdcBalance === '') {
      return 'Loading...';
    } else {
      return `${usdcBalance}`;
    }
  };

  /*
   * Component
   */

  return (
    <Wrapper ref={ref}>
      <TitleLabelAndDropdownIconContainer>
        <TitleLabel>
          {"Order Summary"}
        </TitleLabel>

        <TitleValueAndChevronContainer>
          <TitleValue>
            
          </TitleValue>  

          <StyledChevronDown
            onClick={() => setIsOpen(!isOpen)}
            $isOpen={isOpen}
          />
        </TitleValueAndChevronContainer>
      </TitleLabelAndDropdownIconContainer>

      <SummaryDropdown $isOpen={isOpen}>
        <HorizontalDivider/>

        <RequirementListContainer>
          <SummaryItem 
            label={"Account balance"}
            value={balanceString()}
          />

          <SummaryItem 
            label={"Selected tickets"}
            value={selectedTicketString()}
          />

          <SummaryItem
            label={"Tickets listed for"}
            value={ticketsListedForString()}
          />

          <SummaryItem
            label={"Order Total"}
            value={orderTotalString()}
          />
        </RequirementListContainer>
      </SummaryDropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  border-radius: 16px;
  border: 1px solid ${colors.defaultBorderColor};
  background: ${colors.container};
`;

const TitleLabelAndDropdownIconContainer = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px 10px;
`;

const TitleLabel = styled.div`
  font-size: 14px;
`;

const TitleValueAndChevronContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  gap: 8px;
`;

const TitleValue = styled.div`
  font-size: 15px;
`;

interface StyledChevronDownProps {
  $isOpen?: boolean;
};

const StyledChevronDown = styled(ChevronDown)<StyledChevronDownProps>`
  width: 20px;
  height: 20px;
  color: ${colors.chevronDown};

  transition: transform 0.4s;
  transform: ${({ $isOpen }) => $isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const HorizontalDivider = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.defaultBorderColor};
`;

interface SummaryDropdownProps {
  $isOpen?: boolean;
};

const SummaryDropdown = styled.div<SummaryDropdownProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${colors.container};
  color: ${colors.darkText};
  align-items: center;
  gap: 16px;
  overflow: hidden;

  max-height: ${({ $isOpen }) => $isOpen ? '500px' : '0px'};
  transition: max-height 0.4s ease-out;
`;

const RequirementListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;
`;

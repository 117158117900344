import React, { useEffect, useState } from 'react';
import { Inbox } from 'react-feather';
import styled, { css } from 'styled-components/macro';

import { RowBetween } from '@components/layouts/Row';
import { CustomConnectButton } from '@components/common/ConnectButton';
import { OrderRow, BuyerOrderAction, BuyerOrderStatus, } from '@components/Orders/OrderRow'; 
import QuestionHelper from '@components/common/QuestionHelper';
import { formatEventDateTime } from '@helpers/dateFormat';
import { formatTicketSummaries } from '@helpers/ticketFormat';
import { Order } from '@helpers/types';
import { toUsdString } from '@helpers/units';
import { commonStrings } from '@helpers/strings';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import useAccount from '@hooks/contexts/useAccount';
import useBuyers from '@hooks/contexts/useBuyers';


export interface OrderRowData {
  status: BuyerOrderStatus;
  action: BuyerOrderAction;
  orderId: bigint;
  venueText: string;
  eventText: string;
  startTimeText: string;
  sectionText: string;
  rowText: string;
  seatText: string;
  ticketPriceText: string;
  eventUrl: string;
};

export interface OrderRefundData {
  orderId: bigint;
  venueText: string;
  eventText: string;
  startTimeText: string;
  sectionText: string;
  rowText: string;
  seatText: string;
  ticketPriceText: string;
}

interface OrderTableProps {
  handleInitiateRefundPressed: (selectedOrderDetails: OrderRefundData) => void;
}

export const OrderTable: React.FC<OrderTableProps> = ({
  handleInitiateRefundPressed,
}) => {
  /*
   * Contexts
   */

  const { isLoggedIn } = useAccount();
  const { buyerOrdersStore } = useBuyers();

  /*
   * State
   */

  const [orderRowsData, setOrderRowsData] = useState<OrderRowData[]>([]);

  /*
   * Handlers
   */

  const handleInitiateRefundRowPressed = (rowIndex: number) => {
    // console.log(rowIndex);

    const selectedOrderDetails: OrderRefundData = {
      orderId: orderRowsData[rowIndex].orderId,
      venueText: orderRowsData[rowIndex].venueText,
      eventText: orderRowsData[rowIndex].eventText,
      startTimeText: orderRowsData[rowIndex].startTimeText,
      sectionText: orderRowsData[rowIndex].sectionText,
      rowText: orderRowsData[rowIndex].rowText,
      seatText: orderRowsData[rowIndex].seatText,
      ticketPriceText: orderRowsData[rowIndex].ticketPriceText
    };

    handleInitiateRefundPressed(selectedOrderDetails);
  };

  /*
   * Hooks
   */

  function determineStatusAndActions(order: Order): [BuyerOrderStatus, BuyerOrderAction] {
    if (order.transferId) {
      return [BuyerOrderStatus.WAITING_TO_ACCEPT, BuyerOrderAction.NONE];
    } else if (order.refundInitiatedAt === BigInt(0)) {
      const refundInitializableTimestamp = order.createdAt + BigInt(60);
      const currentUtcTimestamp = BigInt(Math.floor(Date.now() / 1000));

      if (refundInitializableTimestamp < currentUtcTimestamp) {
        return [BuyerOrderStatus.WAITING_TRANSFER, BuyerOrderAction.INITIATE_REFUND];
      } else {
        return [BuyerOrderStatus.WAITING_TRANSFER, BuyerOrderAction.NONE];
      }
    } else {
      const refundFinalizableTimestamp = order.refundInitiatedAt + BigInt(60);
      const currentUtcTimestamp = BigInt(Math.floor(Date.now() / 1000));

      if (refundFinalizableTimestamp < currentUtcTimestamp) {
        return [BuyerOrderStatus.WAITING_REFUND, BuyerOrderAction.FINALIZE_REFUND];
      } else {
        return [BuyerOrderStatus.WAITING_REFUND, BuyerOrderAction.NONE];
      }
    }
  }

  useEffect(() => {
    console.log('buyerOrdersStore: ', buyerOrdersStore);

    if (buyerOrdersStore) {
      const buyerOrders = buyerOrdersStore.buyerOrders;
      const eventsMap = buyerOrdersStore.events;

      if (buyerOrders.length > 0) {
        const orderRowsData: OrderRowData[] = [];
        for (const order of buyerOrders) {
          // Extract Order Info
          const ticketsOnOrder = order.tickets;
          const ticketSummaryComponents = formatTicketSummaries(ticketsOnOrder);
  
          const ticketPriceText = `$${toUsdString(order.purchaseValue)}`;

          const [status, action] = determineStatusAndActions(order);
          // console.log(status, action);

          // Extract Listing Info
          const eventIdOnTicket = ticketsOnOrder[0].eventId;
          const event = eventsMap[eventIdOnTicket];
          const eventText = event.name;
          const startTimeText = formatEventDateTime(event.startTime);
          const venueText = event.venue;
          const eventUrl = event.eventUrl;

          // TODO: store buyer email locally and show when creating order
  
          orderRowsData.push({
            orderId: order.orderId,
            status,
            action,
            eventText,
            venueText,
            startTimeText,
            sectionText: ticketSummaryComponents.sectionSummary,
            rowText: ticketSummaryComponents.rowSummary,
            seatText: ticketSummaryComponents.seatSummary,
            ticketPriceText,
            eventUrl
          });
        }

        setOrderRowsData(orderRowsData);
      } else {
        setOrderRowsData([]);
      }
    } else {
      setOrderRowsData([]);
    }
  }, [buyerOrdersStore]);
  
  /*
   * Component
   */

  return (
    <Container>
      <TitleRow>
        <ThemedText.HeadlineMedium>
          My Orders
        </ThemedText.HeadlineMedium>
      </TitleRow>

      <Content>
        {!isLoggedIn ? (
          <ErrorContainer>
            <ThemedText.DeprecatedBody textAlign="center">
              <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
              <div>
                Your open orders will appear here.
              </div>
            </ThemedText.DeprecatedBody>
            <CustomConnectButton width={152} />
          </ErrorContainer>
        ) : orderRowsData.length === 0 ? (
            <ErrorContainer>
              <ThemedText.DeprecatedBody textAlign="center">
                <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
                <div>
                  You have no open orders.
                </div>
              </ThemedText.DeprecatedBody>
            </ErrorContainer>
        ) : (
          <>
            <TableHeaderRow>
              <ColumnHeader>#</ColumnHeader>

              <ColumnHeader>
                <ColumnHeaderAndQuestionMarkContainer>
                  Status
                  <QuestionHelper
                    text={commonStrings.get('ORDER_STATUS_LABEL_EXPLAINER')}
                  />
                </ColumnHeaderAndQuestionMarkContainer>
              </ColumnHeader>

              <ColumnHeader>Event</ColumnHeader>

              <ColumnHeader>Seats</ColumnHeader>

              <ColumnHeader>Total</ColumnHeader>
              <ColumnHeader>Actions</ColumnHeader>
            </TableHeaderRow>

            <Table>
              {orderRowsData.map((orderRowData, rowIndex) => (
                <StyledOrderRow key={rowIndex}>
                  <OrderRow
                    status={orderRowData.status}
                    action={orderRowData.action}
                    orderId={orderRowData.orderId}
                    venueText={orderRowData.venueText}
                    eventText={orderRowData.eventText}
                    startTimeText={orderRowData.startTimeText}
                    sectionText={orderRowData.sectionText}
                    rowText={orderRowData.rowText}
                    seatText={orderRowData.seatText}
                    orderPriceText={orderRowData.ticketPriceText}
                    eventUrl={orderRowData.eventUrl}
                    rowIndex={rowIndex}
                    handleInitiateRefundPressed={() => handleInitiateRefundRowPressed(rowIndex)}
                  />
                </StyledOrderRow>
              ))}
            </Table>
          </>
        )}
      </Content>
    </Container>
  )
};

const Container = styled.div`
  gap: 1rem;
`;

const TitleRow = styled(RowBetween)`
  align-items: flex-end;
  color: ${colors.darkText};
  padding: 1.25rem 1rem 1.75rem 1rem;
  height: 76px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    padding: 0 1.75rem;
  };
`;

const Content = styled.div`
  background-color: ${colors.container};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  overflow: hidden;
  position: relative;

  @media (min-width: 600px) {
    border-radius: 16px;
  }
`;

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 36px;
  max-width: 340px;
  min-height: 25vh;
  gap: 36px;
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`;

const TableHeaderRow = styled.div`
  display: grid;
  grid-template-columns: 0.75fr 1fr 3.5fr 1fr 1fr 1.5fr;
  grid-gap: 4px;
  padding: 1rem 0rem 0.75rem 0rem;
  text-align: left;
  color: ${colors.darkText};
  border-bottom: 1px solid ${colors.defaultBorderColor};
`;

const ColumnHeader = styled.div`
  text-align: center;
  font-size: 14px;
  
  @media (max-width: 600px) {
    font-size: 13px;
  };
`;

const ColumnHeaderAndQuestionMarkContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Table = styled.div`
  font-size: 16px;
  color: #616161;

  & > * {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${colors.defaultBorderColor};
    }
  }

  & > *:last-child::after {
    display: none;
  }
`;

const StyledOrderRow = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useCancelOrderTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { swapTicketExchangeAddress, swapTicketExchangeAbi } = useSmartContracts();

  /*
   * State
   */ 

  const [orderIdInput, setOrderIdInput] = useState<bigint | null>(null);

  const [shouldConfigureSellerCancelOrderWrite, setShouldConfigureSellerCancelOrderWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  // @param _orderId       Id of the order being canceled

  const { config: writeSellerCancelOrderConfig } = usePrepareContractWrite({
    address: swapTicketExchangeAddress,
    abi: swapTicketExchangeAbi,
    functionName: 'sellerCancelOrder',
    args: [
      orderIdInput,
    ],
    onError(error: any) {
      console.log('writeSellerCancelOrderConfig failed: ', error.message);

      setShouldConfigureSellerCancelOrderWrite(false);

      onError?.(error);
    },
    enabled: shouldConfigureSellerCancelOrderWrite,
  });

  const {
    data: submitSellerCancelOrderResult,
    status: signSellerCancelOrderTransactionStatus,
    writeAsync: writeSellerCancelOrderAsync,
  } = useContractWrite(writeSellerCancelOrderConfig);

  const {
    status: mineSellerCancelOrderTransactionStatus,
  } = useWaitForTransaction({
    hash: submitSellerCancelOrderResult ? submitSellerCancelOrderResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeSellerCancelOrderAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeSellerCancelOrderAsync,
    orderIdInput,
    setOrderIdInput,
    shouldConfigureSellerCancelOrderWrite,
    setShouldConfigureSellerCancelOrderWrite,
    signSellerCancelOrderTransactionStatus,
    mineSellerCancelOrderTransactionStatus,
    transactionHash: submitSellerCancelOrderResult?.hash,
  };
};

import { createContext } from 'react'

interface BalancesValues {
  ethBalance: bigint | null
  refetchEthBalance: (() => void) | null
  shouldFetchEthBalance: boolean | null

  usdcBalance: bigint | null
  refetchUsdcBalance: (() => void) | null
  shouldFetchUsdcBalance: boolean | null

  usdcApprovalToExchange: bigint | null
  refetchUsdcApprovalToExchange: (() => void) | null
  shouldFetchUsdcApprovalToExchange: boolean

  usdcApprovalToSocketBridge: bigint | null
  refetchUsdcApprovalToSocketBridge: (() => void) | null
  usdcApprovalToLifiBridge: bigint | null
  refetchUsdcApprovalToLifiBridge: (() => void) | null
}

const defaultValues: BalancesValues = {
  ethBalance: null,
  refetchEthBalance: null,
  shouldFetchEthBalance: null,

  usdcBalance: null,
  refetchUsdcBalance: null,
  shouldFetchUsdcBalance: null,

  usdcApprovalToExchange: null,
  refetchUsdcApprovalToExchange: null,
  shouldFetchUsdcApprovalToExchange: false,

  usdcApprovalToSocketBridge: null,
  refetchUsdcApprovalToSocketBridge: null,
  usdcApprovalToLifiBridge: null,
  refetchUsdcApprovalToLifiBridge: null,
};

const BalancesContext = createContext<BalancesValues>(defaultValues)

export default BalancesContext

import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro'
import { ArrowLeft } from 'react-feather';
import Confetti from 'react-confetti';
import { useWindowSize } from '@uidotdev/usehooks';
import Link from '@mui/material/Link';

import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { RowBetween } from '@components/layouts/Row';
import { ProofTable } from '@components/Listings/FinalizeOrder/ProofTable';
import { TransferInstructions } from '@components/Listings/FinalizeOrder/TransferInstructions';
import { OrderSettlementDetails } from '@components/Listings/Seller/OrderTable';
import { commonStrings } from '@helpers/strings';
import { parseProof } from '@helpers/parseProof';
import { LoginStatus, FinalizeSwapTransactionStatus } from '@helpers/types';
import { Z_INDEX } from '@theme/zIndex';
import useAccount from '@hooks/contexts/useAccount';
import useBalances from '@hooks/contexts/useBalance';
import useSellers from '@hooks/contexts/useSellers';
import useFinalizeSwap from '@hooks/transactions/useFinalizeSwap';

interface FinalizeOrderFormProps {
  handleBackClick: () => void;
  orderDetails: OrderSettlementDetails | null;
};

export const FinalizeOrderForm: React.FC<FinalizeOrderFormProps> = ({
  handleBackClick,
  orderDetails
}) => {
  const size = useWindowSize();

  /*
   * Context
   */

  const { loginStatus } = useAccount();
  const { refetchSellerListingStore } = useSellers();
  const { refetchUsdcBalance } = useBalances();

  /*
   * State
   */

  const [finalizeSwapStatus, setFinalizeSwapStatus] = useState<string>(FinalizeSwapTransactionStatus.DEFAULT);
  
  const [transferProof, setTransferProof] = useState<string | null>(null); 

  const [showConfetti, setShowConfetti] = useState<boolean>(false);

  /*
   * Hooks
   */

  const onSuccessCallback = useCallback((data: any) => {
    console.log('finalizeSwap Succeeded: ', data);

    refetchUsdcBalance?.();

    refetchSellerListingStore?.();
  }, [refetchSellerListingStore, refetchUsdcBalance]);

  const {
    writeFinalizeSwapAsync,
    orderIdInput,
    transferProofInput,
    setOrderIdInput,
    setTransferProofInput,
    setShouldConfigureFinalizeSwapWrite,
    signFinalizeSwapTransactionStatus,
    mineFinalizeSwapTransactionStatus,
    // transactionHash,
  } = useFinalizeSwap(onSuccessCallback);

  useEffect(() => {
    setShouldConfigureFinalizeSwapWrite(finalizeSwapStatus === FinalizeSwapTransactionStatus.VALID);
  }, [finalizeSwapStatus, setShouldConfigureFinalizeSwapWrite]);

  useEffect(() => {
    if (transferProof) {
      const parsedTransferProof = parseProof(transferProof);

      setTransferProofInput(parsedTransferProof);
    } else {
      setTransferProofInput(null);
    }
  }, [transferProof, setTransferProofInput]);

  useEffect(() => {
    if (orderDetails) {
      setOrderIdInput(orderDetails.orderId);
    } else {
      setOrderIdInput(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails]);

  useEffect(() => {
    const updateVerifyTicketsStatus = async () => {
      const successfulVerificationTransaction = mineFinalizeSwapTransactionStatus === 'success';
      if (successfulVerificationTransaction) {
        setFinalizeSwapStatus(FinalizeSwapTransactionStatus.TRANSACTION_SUCCEEDED);
      } else {
        const transferProofSelected = transferProofInput !== null;
        const orderAndTransferProofSelected = orderIdInput !== null && transferProofSelected;

        if (orderAndTransferProofSelected) {
          const signingVerifyTicketsTransaction = signFinalizeSwapTransactionStatus === 'loading';
          const miningVerifyTicketsTransaction = mineFinalizeSwapTransactionStatus === 'loading';

          if (signingVerifyTicketsTransaction) {
            setFinalizeSwapStatus(FinalizeSwapTransactionStatus.TRANSACTION_SIGNING);
          } else if (miningVerifyTicketsTransaction) {
            setFinalizeSwapStatus(FinalizeSwapTransactionStatus.TRANSACTION_MINING);
          } else {
            setFinalizeSwapStatus(FinalizeSwapTransactionStatus.VALID);
          }
        } else {
          setFinalizeSwapStatus(FinalizeSwapTransactionStatus.DEFAULT);
        }
      }
    }

    updateVerifyTicketsStatus();
  }, [
      orderIdInput,
      transferProofInput,
      signFinalizeSwapTransactionStatus,
      mineFinalizeSwapTransactionStatus,
    ]
  );

  useEffect(() => {
    if (mineFinalizeSwapTransactionStatus === 'success') {
      setShowConfetti(true);
      
      setTimeout(() => {
        setShowConfetti(false);
      }, 5000);
    }
  }, [mineFinalizeSwapTransactionStatus])

  /*
   * Helpers
   */

  const ctaOnClick = async () => {
    switch (finalizeSwapStatus) {
      case FinalizeSwapTransactionStatus.VALID:
        try {
          await writeFinalizeSwapAsync?.();
        } catch (error) {
          console.log('writeFinalizeSwapAsync failed: ', error);
        }
        break;

      case FinalizeSwapTransactionStatus.TRANSACTION_SUCCEEDED:
        handleBackClick();
        break;

      default:
        break;
    }
  }

  const recipientEmailFromOrder = () => {
    return orderDetails ? orderDetails.recipientEmail : ''
  };

  const ticketSeatsFromOrder = () => {
    // TODO: This should not be necessary since we should not be on the transfer ticket form if there is no order
    const emptyOrderDetails = {
      orderId: BigInt(0),
      recipientEmail: '',
      orderPriceText: '',
      seats: [],
      section: '',
      row: '',
      eventName: '',
      eventDate: BigInt(0)
    };

    return orderDetails ?? emptyOrderDetails;
  };

  /*
   * Component
   */

  return (
    <Container>
      {showConfetti ? (
        <ConfettiContainer>
          <Confetti
            recycle={false}
            numberOfPieces={500}
            width={size.width ?? undefined}
            height={document.documentElement.scrollHeight}
          />
        </ConfettiContainer>
      ) : null}

      <TitleContainer>
        <RowBetween style={{ padding: '0.25rem 0rem 0.25rem 0rem' }}>
          <div style={{ flex: 0.5 }}>
            <button
              onClick={handleBackClick}
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            >
              <StyledArrowLeft/>
            </button>
          </div>

          <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
            Complete Order
          </ThemedText.HeadlineSmall>

          <div style={{ flex: 0.5 }}/>
        </RowBetween>

        <InstructionContainer>
          {commonStrings.get('PROOF_FORM_FINALIZE_ORDER_INSTRUCTIONS')}

          {loginStatus === LoginStatus.EOA && (
            <>
              {commonStrings.get('TICKET_VERIFICATION_ETH_REQUIRED')}

              <Link
                href={'https://bridge.base.org/deposit'}
                target='_blank'>
                  Base Bridge ↗
              </Link>
            </>
          )}
        </InstructionContainer>

        <TransferInstructions
          orderDetails={ticketSeatsFromOrder()}
        />
      </TitleContainer>

      <VerticalDivider/>

      <ProofTable
        setProofToSubmit={setTransferProof}
        handleSubmitTransactionPressed={ctaOnClick}
        proofSelectionStatus={finalizeSwapStatus}
        recipientEmail={recipientEmailFromOrder()}
        ticketSeatsFromOrder={orderDetails ? orderDetails.seats : []}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 660px;
`;

const ConfettiContainer = styled.div`
  z-index: ${Z_INDEX.confetti};
`;

const TitleContainer = styled.div`
  padding: 1.5rem;
  background-color: ${colors.container};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  
  @media (min-width: 600px) {
    max-width: 606px;
    border-radius: 16px;
  }
`;

const InstructionContainer = styled.span`
  display: block;
  padding: 1rem 0.5rem;
  color: ${colors.darkText};
  text-align: left;
  line-height: 1.4;
  font-size: 15px;
`;

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${colors.darkText};
`;

const VerticalDivider = styled.div`
  height: 24px;
  width: 1px;
  background-color: ${colors.defaultBorderColor};
  margin: 0 auto;
`;

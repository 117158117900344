import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { AutoColumn } from '@components/layouts/Column';
import { EventCarousel } from '@components/Browse/EventCarousel'; 
import { EventTable } from '@components/Browse/EventTable';
import { STATE_REFETCH_INTERVAL } from '@helpers/constants';
import { Event } from '@helpers/types';
import useEvents from '@hooks/contexts/useEvents';


export default function ListingBrowser() {
  /*
   * Context
   */

  const {
    eventsByEventId,
    shouldFetchEvents,
    refetchEvents,
    shouldFetchEventIds,
    refetchEventIds,
  } = useEvents();

  const [timeFilteredEvents, setTimeFilteredEvents] = useState<Event[] | null>(null);

  /*
   * Hooks
   */

  useEffect(() => {
    const timeFilteredEvents = Object.values(eventsByEventId ?? {}).filter((event: Event) => { 
      const currentTime = BigInt(Math.floor(Date.now() / 1000));

      return event.startTime > currentTime;
    });

    if (timeFilteredEvents) {
      setTimeFilteredEvents(timeFilteredEvents);
    } else {
      setTimeFilteredEvents(null);
    }
  }, [eventsByEventId]);

  useEffect(() => {
    if (shouldFetchEvents) {
      const intervalId = setInterval(() => {
        refetchEvents?.();
      }, STATE_REFETCH_INTERVAL);

      return () => clearInterval(intervalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchEvents]);

  useEffect(() => {
    if (shouldFetchEventIds) {
      const intervalId = setInterval(() => {
        refetchEventIds?.();
      }, STATE_REFETCH_INTERVAL);

      return () => clearInterval(intervalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchEventIds]);

  /*
   * Component
   */

  return (
    <Wrapper>
      <EventCarousel
        events={timeFilteredEvents ? Object.values(timeFilteredEvents) : []}
      />

      <EventTable
        events={timeFilteredEvents ? Object.values(timeFilteredEvents) : []}
      />
    </Wrapper>
  );
};

const Wrapper = styled(AutoColumn)`
  margin: auto;
`;

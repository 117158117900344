import React, { useCallback, useEffect, useState } from 'react';
import { Inbox, Search } from 'react-feather';
import styled, { css } from 'styled-components/macro';
import { useNavigate } from 'react-router-dom'

import { RowBetween } from '@components/layouts/Row';
import { EventTableRow } from '@components/Browse/EventTableRow';
import { Event } from '@helpers/types'; 
import { formatEventDateTime } from '@helpers/dateFormat';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import useListings from '@hooks/contexts/useListings';


const ROWS_PER_PAGE = 10;

export interface EventRowData {
  eventId: string;
  venueText: string;
  eventText: string;
  startTimeText: string;
  startTimeSort: bigint;
  ticketCount: number;
  eventUrl: string;
};

interface EventTableProps {
  events: Event[];
}

export const EventTable: React.FC<EventTableProps> = ({
  events
}) => {
  const navigate = useNavigate();

  /*
   * Contexts
   */

  const { activeListingsStore  } = useListings();

  /*
   * State
   */

  const [eventRowsData, setEventRowsData] = useState<EventRowData[]>([])
  const [filteredEventRowsData, setFilteredEventRowsData] = useState<EventRowData[]>([])
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  /*
   * Hooks
   */

  useEffect(() => {
    console.log('eventsByEventId: ', events);

    if (events.length > 0 && activeListingsStore) {
      if (events.length > 0) {
        const eventRowsData: EventRowData[] = [];
        for (const event of events) {
          const eventText = event.name;
          const startTimeText = formatEventDateTime(event.startTime);
          const venueText = event.venue;
          const eventUrl = event.eventUrl;
          const eventId = event.id;

          const listingsForSelectedEvent = activeListingsStore.filter((detailedListing) => detailedListing.eventId === eventId);
          const ticketCount = listingsForSelectedEvent.reduce((totalTickets, detailedListing) => totalTickets + detailedListing.unfilledTickets.length, 0);

          eventRowsData.push({
            eventId,
            eventText,
            venueText,
            startTimeText,
            startTimeSort: event.startTime,
            ticketCount,
            eventUrl
          });
        }

        setEventRowsData(eventRowsData);
      } else {
        setEventRowsData([]);
      }
    } else {
      setEventRowsData([]);
    }
  }, [events, activeListingsStore]);

  const applyFilters = useCallback((data: EventRowData[]) => {
    let result = [...data];

    if (searchTerm) {
      // Simple string matching as an alternative to Fuse.js
      result = result.filter(item => 
        item.eventText.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    result.sort((a, b) => {
      const startTimeA = a.startTimeSort;
      const startTimeB = b.startTimeSort;
      return Number(startTimeA) - Number(startTimeB);
    });

    return result;
  }, [searchTerm]);

  useEffect(() => {
    setFilteredEventRowsData(applyFilters(eventRowsData));
  }, [eventRowsData, applyFilters, searchTerm]);

  useEffect(() => {
    setCurrentPage(0);
  }, [filteredEventRowsData.length]);

  /*
   * Handlers
   */

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleEventRowClick = (index: number) => {
    console.log('hitting');

    if (filteredEventRowsData.length > 0) {
      const actualIndex = currentPage * ROWS_PER_PAGE + index;

      const eventId = filteredEventRowsData[actualIndex].eventId;

      navigate(`/browse/${eventId}`);
    };
  };

  /*
   * Helpers
   */

  const totalPages = Math.ceil(filteredEventRowsData.length / ROWS_PER_PAGE);

  const paginatedData = filteredEventRowsData.slice(currentPage * ROWS_PER_PAGE, (currentPage + 1) * ROWS_PER_PAGE);
  
  /*
   * Component
   */

  return (
    <Container>
      <TitleRow>
        <ThemedText.HeadlineMedium>
          All Events
        </ThemedText.HeadlineMedium>
      </TitleRow>

      <SearchContainer>
        <SearchIcon size={20} />

        <SearchInput
          type="text"
          placeholder="Search events..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchContainer>

      <Content>
        {filteredEventRowsData.length === 0 ? (
          <ErrorContainer>
            <ThemedText.DeprecatedBody textAlign="center">
              <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
              <div>
                {searchTerm ? "No matches found" : "Loading events..."}
              </div>
            </ThemedText.DeprecatedBody>
          </ErrorContainer>
        ) : (
          <>
            <TableHeaderRow>
              <ColumnHeader>#</ColumnHeader>

              <ColumnHeader>Event</ColumnHeader>

              <ColumnHeader>Location</ColumnHeader>

              <TicketsColumnHeader>Available Tickets</TicketsColumnHeader>

              <ColumnHeader>{/* Placeholder for See Tickets */}</ColumnHeader>
            </TableHeaderRow>

            <Table>
              {paginatedData.map((eventRowData, rowIndex) => (
                <StyledEventRow key={rowIndex}>
                  <EventTableRow
                    venueText={eventRowData.venueText}
                    eventText={eventRowData.eventText}
                    startTimeText={eventRowData.startTimeText}
                    eventUrl={eventRowData.eventUrl}
                    rowIndex={rowIndex}
                    ticketCount={eventRowData.ticketCount}
                    handleSeeTicketsPressed={() => handleEventRowClick(rowIndex)}
                  />
                </StyledEventRow>
              ))}
            </Table>
          </>
        )}
      </Content>

      {eventRowsData.length > ROWS_PER_PAGE && (
        <PaginationContainer>
          <PaginationButton disabled={currentPage === 0} onClick={() => handleChangePage(currentPage - 1)}>
            &#8249;
          </PaginationButton>
          <PageInfo>
            {totalPages === 0 ? '0 of 0' : `${currentPage + 1} of ${totalPages}`}
          </PageInfo>
          <PaginationButton
            disabled={currentPage === totalPages - 1 || totalPages === 0}
            onClick={() => handleChangePage(currentPage + 1)}>  
            &#8250;
          </PaginationButton>
        </PaginationContainer>
      )}
    </Container>
  )
};

const Container = styled.div`
  width: 1200px;
  margin: auto;
  gap: 1rem;
`;

const TitleRow = styled(RowBetween)`
  align-items: flex-end;
  color: ${colors.darkText};
  padding: 1.25rem 1rem 1.75rem 1rem;
  height: 76px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    padding: 0 1.75rem;
  };
`;

const Content = styled.div`
  background-color: ${colors.container};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  overflow: hidden;
  position: relative;

  @media (min-width: 600px) {
    border-radius: 16px;
  }
`;

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 36px;
  max-width: 340px;
  min-height: 25vh;
  gap: 36px;
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`;

const TableHeaderRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 6fr 4fr 2fr 3fr; 
  grid-gap: 4px;
  padding: 1rem 1.5rem 0.75rem 1.5rem;
  text-align: left;
  color: ${colors.darkText};
  border-bottom: 1px solid ${colors.defaultBorderColor};
`;

const ColumnHeader = styled.div`
  text-align: left;
  font-size: 14px;
  
  @media (max-width: 600px) {
    font-size: 13px;
  };
`;

const TicketsColumnHeader = styled.div`
  text-align: center;
  font-size: 14px;
  
  @media (max-width: 600px) {
    font-size: 13px;
  };
`;

const Table = styled.div`
  font-size: 16px;
  color: #616161;

  & > * {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${colors.defaultBorderColor};
    }
  }

  & > *:last-child::after {
    display: none;
  }
`;

const StyledEventRow = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

const PaginationButton = styled.button`
  background-color: ${colors.paginationDefault};
  color: white;
  padding: 8px 16px;
  margin: 0 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${colors.paginationHover};
  }

  &:disabled {
    background-color: ${colors.paginationDisabled};
    cursor: not-allowed;
  }
`;

const PageInfo = styled.span`
  color: ${colors.darkText};
  word-spacing: 2px;
  font-size: 16px;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 1.5rem;
  margin: 0 0.75rem 1rem 0.75rem;
  
  background-color: ${colors.white};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.01); 
  border-radius: 24px;
  flex: 1;
`;

const SearchIcon = styled(Search)`
  color: ${colors.darkText};
`;

const SearchInput = styled.input`
  flex: 1;
  border: none;
  background: transparent;
  font-size: 16px;
  color: ${colors.darkText};
  outline: none;
  padding: 0.75rem 0.25rem 0.5rem 0.25rem;

  &::placeholder {
    color: ${colors.darkText}80;
  }
`;

export interface CommonStrings {
  // Environment Banner
  LOCAL_ENV_BANNER: string,
  STAGING_TESTNET_ENV_BANNER: string,
  STAGING_ENV_BANNER: string,
  PRODUCTION_ENV_BANNER: string,

  // Registraiton ETH Required
  TICKET_VERIFICATION_ETH_REQUIRED: string,

  // Receive Modal
  RECEIVE_FUNDS_INSTRUCTIONS_1: string
  RECEIVE_FUNDS_INSTRUCTIONS_2: string
  RECEIVE_FUNDS_ONRAMP_INSTRUCTIONS: string

  // Extension Instructions
  BROWSER_NOT_SUPPORTED_INSTRUCTIONS: string,
  EXTENSION_DOWNLOAD_INSTRUCTIONS: string,

  // New Listing Form
  NEW_LISTING_INSTRUCTIONS: string,
  NEW_LISTING_NOTIFICATION_EMAIL_TOOLTIP: string,
  NEW_LISTING_PRICE_TOOLTIP: string,

  // Buy Order Modal
  BUY_ORDER_INSTRUCTIONS: string,
  BUY_ORDER_RECIPIENT_EMAIL_TOOLTIP: string,

  // Verify Ticket Form
  PROOF_FORM_VERIFY_TICKET_INSTRUCTIONS: string,

  // Initiate Transfer Form
  PROOF_FORM_INITIATE_TRANSFER_INSTRUCTIONS: string,

  // Finalize Order Form
  PROOF_FORM_FINALIZE_ORDER_INSTRUCTIONS: string,

  // Proof Table
  NO_VERIFIED_TICKET_PROOFS_ERROR: string,
  NO_INITIATE_TRANSFER_PROOFS_ERROR: string,
  NO_ACCEPTANCE_PROOFS_ERROR: string,
  NO_TRANSFER_PROOFS_ERROR: string,

  // Order and Listings tables
  ORDER_STATUS_LABEL_EXPLAINER: string,
  LISTING_STATUS_LABEL_EXPLAINER: string,
};

const strings: CommonStrings = {
  // Environment Banner
  LOCAL_ENV_BANNER: `
    You are currently viewing the application on localhost
  `,
  STAGING_TESTNET_ENV_BANNER: `
    You are currently viewing the staging-testnet application
  `,
  STAGING_ENV_BANNER: `
    You are currently viewing the staging application
  `,
  PRODUCTION_ENV_BANNER: `
    ZKP2P's Ticket Marketplace Alpha Launch —
  `,

  // Registration ETH Required
  TICKET_VERIFICATION_ETH_REQUIRED: `
    Base ETH is required to submit a ticket verification transaction.
  `,

  // Receive Modal
  RECEIVE_FUNDS_INSTRUCTIONS_1: `
    This address can ONLY receive
  `,
  RECEIVE_FUNDS_INSTRUCTIONS_2: `
    Sending invalid USDC or tokens from other networks will result in lost funds.
  `,
  RECEIVE_FUNDS_ONRAMP_INSTRUCTIONS:`
    Don't have USDC? Try our P2P ramp to get funds if you have an account with one
    of our supported P2P payment platforms.
  `,

  // Extension Instructions
  BROWSER_NOT_SUPPORTED_INSTRUCTIONS: `
    Your browser is not currently supported. Switch to a browser with Sidebar support and
    join our Telegram for updates on supported browsers.
  `,
  EXTENSION_DOWNLOAD_INSTRUCTIONS: `
    ZKP2P's browser sidebar is your browsing assistant that allows
    you to prove ownership of your Ticketmaster tickets and transfers.
  `,

  // New Listing Form
  NEW_LISTING_INSTRUCTIONS: `Create a new listing by selecting the unlisted tickets to include, specifying a price in USDC, and expiration in hours. `,
  NEW_LISTING_NOTIFICATION_EMAIL_TOOLTIP: `(Optional) We will send you a notification to this email when there are new bids on your listing`,
  NEW_LISTING_PRICE_TOOLTIP: `Price per ticket buyer will pay for tickets. No additional fees will be added.`,

  // Buy Order Modal
  BUY_ORDER_INSTRUCTIONS: `Create an order by selecting the desired tickets and providing your Ticketmaster account email. `,
  BUY_ORDER_RECIPIENT_EMAIL_TOOLTIP: `Provide email address to receive tickets at`,

  // Verify Ticket Form
  PROOF_FORM_VERIFY_TICKET_INSTRUCTIONS: `
    Prove ownership of tickets you want to list using the browser assistant. Tickets must be transferrable on Ticketmaster.
  `,

  // Initiate Transfer Form
  PROOF_FORM_INITIATE_TRANSFER_INSTRUCTIONS: `
    Prove successful transfer of tickets to the correct recipient using the browser assistant. Submit proof to initiate transfer.
  `,

  // Finalize Order Form
  PROOF_FORM_FINALIZE_ORDER_INSTRUCTIONS: `
    Prove recipient accepted tickets using the browser assistant. Submit proof to claim proceeds of sale.
  `,

  // Proof Table
  NO_VERIFIED_TICKET_PROOFS_ERROR: `
    No new Ticketmaster ticket proofs found.
    Please follow instructions in the browser sidebar to generate proof of tickets in your account.
  `,
  NO_INITIATE_TRANSFER_PROOFS_ERROR: `
    No Ticketmaster proofs found.
    Please follow instructions in the browser sidebar to generate proof of successful transfer to the recipient.
  `,
  NO_ACCEPTANCE_PROOFS_ERROR: `
    No Ticketmaster proofs found.
    Please follow instructions in the browser sidebar to generate proof of ticket acceptance by the recipient.
  `,
  NO_TRANSFER_PROOFS_ERROR: `
    No Ticketmaster proofs found.
    Please follow instructions in the browser sidebar to generate proof of successful transfer to the recipient.
  `,

  // Order and Listings tables
  ORDER_STATUS_LABEL_EXPLAINER: `Current status of order. For more information on settlement procedure see the How It Works page.`,

  LISTING_STATUS_LABEL_EXPLAINER: `Current status of listing. For more information on settlement procedure see the How It Works page.`,
};

export class CommonStringProvider {
  private strings: CommonStrings;

  constructor() {
    this.strings = strings;
  }

  get(key: keyof CommonStrings): string {
    return this.strings[key];
  }
};

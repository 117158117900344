import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import { Proof } from '@helpers/types';
import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useFinalizeSwapTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { swapTicketExchangeAddress, swapTicketExchangeAbi } = useSmartContracts();

  /*
   * State
   */ 

  const [orderIdInput, setOrderIdInput] = useState<bigint | null>(null);
  const [transferProofInput, setTransferProofInput] = useState<Proof | null>(null);

  const [shouldConfigureFinalizeSwapWrite, setShouldConfigureFinalizeSwapWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  // * @param _orderId       Id of the order being finalized
  // * @param _proof         Proof of ticket transfer and acceptance

  const { config: writeFinalizeSwapConfig } = usePrepareContractWrite({
    address: swapTicketExchangeAddress,
    abi: swapTicketExchangeAbi,
    functionName: 'finalizeSwap',
    args: [
      orderIdInput,
      transferProofInput,
    ],
    onError(error: any) {
      console.log('writeFinalizeSwapConfig failed: ', error.message);

      setShouldConfigureFinalizeSwapWrite(false);

      onError?.(error);
    },
    enabled: shouldConfigureFinalizeSwapWrite,
  });

  const {
    data: submitFinalizeSwapResult,
    status: signFinalizeSwapTransactionStatus,
    writeAsync: writeFinalizeSwapAsync,
  } = useContractWrite(writeFinalizeSwapConfig);

  const {
    status: mineFinalizeSwapTransactionStatus,
  } = useWaitForTransaction({
    hash: submitFinalizeSwapResult ? submitFinalizeSwapResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeFinalizeSwapAsync successful: ', data);

      onSuccess?.(data);
    },
  });

  return {
    writeFinalizeSwapAsync,
    orderIdInput,
    transferProofInput,
    setOrderIdInput,
    setTransferProofInput,
    setShouldConfigureFinalizeSwapWrite,
    signFinalizeSwapTransactionStatus,
    mineFinalizeSwapTransactionStatus,
    transactionHash: submitFinalizeSwapResult?.hash,
  };
};

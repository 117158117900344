import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { AutoColumn } from '@components/layouts/Column';
import { ListingsTable } from '@components/Listings/Seller/ListingsTable';
import { NewListing } from '@components/Listings/NewListing';
import { VerifyTicketsForm } from '@components/Listings/NewListing/VerifyTickets';
import { STATE_REFETCH_INTERVAL } from '@helpers/constants';
import useEvents from '@hooks/contexts/useEvents';
import useSellers from '@hooks/contexts/useSellers';
import { TransferTicketsForm } from '@components/Listings/InitiateTransfer';
import { OrderSettlementDetails } from '@components/Listings/Seller/OrderTable';
import { FinalizeOrderForm } from '@components/Listings/FinalizeOrder';


export default function Listings() {
  /*
   * Context
   */

  const {
    shouldFetchEvents,
    refetchEvents,
    shouldFetchEventIds,
    refetchEventIds,
  } = useEvents();

  const {
    shouldFetchSellerTickets,
    refetchSellerTickets,
    shouldFetchSellerListingOrders,
    refetchSellerOrders,
  } = useSellers();

  /*
   * State
   */

  const [isCreateListing, setIsCreateListing] = useState<boolean>(false);

  const [isVerifyTickets, setIsVerifyTickets] = useState<boolean>(false);

  const [selectedOrderForTransferDetails, setSelectedOrderForTransferDetails] = useState<OrderSettlementDetails | null>(null);
  const [isTransferOrder, setIsTransferOrder] = useState<boolean>(false);

  const [selectedOrderDetails, setSelectedOrderDetails] = useState<OrderSettlementDetails | null>(null);
  const [isFinalizeOrder, setIsFinalizeOrder] = useState<boolean>(false);

  /*
   * Hooks
   */

  useEffect(() => {
    if (shouldFetchSellerTickets) {
      const intervalId = setInterval(() => {
        refetchSellerTickets?.();
      }, STATE_REFETCH_INTERVAL);

      return () => clearInterval(intervalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchSellerTickets]);

  useEffect(() => {
    if (shouldFetchSellerListingOrders) {
      const intervalId = setInterval(() => {
        refetchSellerOrders?.();
      }, STATE_REFETCH_INTERVAL);

      return () => clearInterval(intervalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchSellerListingOrders]);

  useEffect(() => {
    if (shouldFetchEvents) {
      const intervalId = setInterval(() => {
        refetchEvents?.();
      }, STATE_REFETCH_INTERVAL);

      return () => clearInterval(intervalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchEvents]);

  useEffect(() => {
    if (shouldFetchEventIds) {
      const intervalId = setInterval(() => {
        refetchEventIds?.();
      }, STATE_REFETCH_INTERVAL);

      return () => clearInterval(intervalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchEventIds]);

  /*
   * Handlers
   */

  const handleCreateListingClicked = () => {
    setIsCreateListing(true);
  }

  const handleCreateListingBackClicked = () => {
    setIsCreateListing(false);
  }

  const handleVerifyTicketsClicked = () => {
    setIsVerifyTickets(true);
  }

  const handleVerifyTicketsBackClicked = () => {
    setIsVerifyTickets(false);
  }

  const handleTransferOrderClicked = (details: OrderSettlementDetails) => {
    setSelectedOrderForTransferDetails(details);

    setIsTransferOrder(true);
  }

  const handleTransferOrderBackClicked = () => {
    setIsTransferOrder(false);

    setSelectedOrderForTransferDetails(null);
  }

  const handleFinalizeOrderClicked = (details: OrderSettlementDetails) => {
    setSelectedOrderDetails(details);

    setIsFinalizeOrder(true);
  }

  const handleFinalizeOrderBackClicked = () => {
    setIsFinalizeOrder(false);

    setSelectedOrderDetails(null);
  }

  /*
   * Component
   */

  function renderContent() {
    if (isVerifyTickets) {
      return (
        <VerifyTicketsForm
          handleBackClick={handleVerifyTicketsBackClicked}
        />
      );
    } else if (isCreateListing) {
      return (
        <NewListing
          handleVerifyTicketsPressed={handleVerifyTicketsClicked}
          handleBackClick={handleCreateListingBackClicked}
        />
      );
    } else if (isTransferOrder) {
      return (
        <TransferTicketsForm
          handleBackClick={handleTransferOrderBackClicked}
          orderDetails={selectedOrderForTransferDetails}
        />
      );
    } else if (isFinalizeOrder) {
      return (
        <FinalizeOrderForm
          handleBackClick={handleFinalizeOrderBackClicked}
          orderDetails={selectedOrderDetails}
        />
      );
    } else {
      return (
        <ListingsTable
          handleCreateListingPressed={handleCreateListingClicked}
          handleTransferOrderPressed={handleTransferOrderClicked}
          handleFinalizeOrderPressed={handleFinalizeOrderClicked}
        />
      )
    }
  };

  return (
    <Wrapper>
      {renderContent()}
    </Wrapper>
  );
};

const Wrapper = styled(AutoColumn)`
  width: 920px;
  margin: auto;
`;

import React, { useEffect } from 'react';
import styled from 'styled-components';

import Listings from '@components/Listings';
import useMediaQuery from '@hooks/useMediaQuery';
import useAccount from '@hooks/contexts/useAccount';
import useSellers from '@hooks/contexts/useSellers';
import useEvents from '@hooks/contexts/useEvents';


export const ListingsPage: React.FC = () => {
  /*
   * Contexts
   */

  const currentDeviceSize = useMediaQuery();
  const { isLoggedIn } = useAccount();
  const {
    shouldFetchSellerTickets,
    refetchSellerTickets,
    shouldFetchSellerListingOrders,
    refetchSellerOrders,
    refetchSellerListingStore
  } = useSellers();
  const { shouldFetchEventIds, refetchEventIds } = useEvents();

  /*
   * Hooks
   */

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    // Refetch all of the seller's tickets, no dependencies
    if (shouldFetchSellerTickets) {
      refetchSellerTickets?.();
    };

    if (shouldFetchSellerListingOrders) {
      refetchSellerOrders?.();
    };

    refetchSellerListingStore?.();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (shouldFetchEventIds) {
      refetchEventIds?.();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * Component
   */

  return (
    <PageWrapper $isMobile={currentDeviceSize === 'tablet' || currentDeviceSize === 'mobile'}>
      <Listings />
    </PageWrapper>
  );
};

const PageWrapper = styled.div<{ $isMobile: boolean }>`
  padding-bottom: 7rem;
  
  @media (min-width: 600px) {
    padding: 12px 8px;
    padding-bottom: 3rem;
  }
`;
  
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ArrowLeft } from 'react-feather';
import { ethers } from 'ethers';
import Link from '@mui/material/Link';

import { TicketsTable } from '@components/Event/BuyModal/TicketsTable';
import { Input } from '@components/Event/BuyModal/Input';
import { Button } from '@components/common/Button';
import { Overlay } from '@components/modals/Overlay';
import { encryptMessage } from '@helpers/messagEncryption';
import { CustomConnectButton } from '@components/common/ConnectButton';
import { Summary } from '@components/Event/BuyModal/Summary';
import {
  DetailedListing,
  CreateOrderTransactionStatus,
  CreateOrderTransactionStatusType,
  LoginStatus,
  MODALS
} from '@helpers/types';
import { BuyInstructions } from '@components/Event/BuyModal/BuyInstructions';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { commonStrings } from '@helpers/strings';
import { Z_INDEX } from '@theme/zIndex';
import { toUsdcString } from '@helpers/units';
import { EXTENSION_DOCS_URL } from '@helpers/docUrls';
import { ZERO } from '@helpers/constants';
import useAccount from '@hooks/contexts/useAccount';
import useBalances from '@hooks/contexts/useBalance';
import useBuyListingTransaction from '@hooks/transactions/useBuyListing';
import useApproveTransaction from '@hooks/transactions/useApprove';
import useModal from '@hooks/useModal';
import useQuery from '@hooks/useQuery';


interface BuyModalProps {
  listing: DetailedListing
  onBackClick: () => void
}

export const BuyModal: React.FC<BuyModalProps> = ({
  listing,
  onBackClick,
}) => {
  const { navigateWithQuery } = useQuery();

  /*
   * Contexts
   */

  const {
    usdcBalance,
    refetchUsdcBalance,
    usdcApprovalToExchange,
    refetchUsdcApprovalToExchange
  } = useBalances();
  const { isLoggedIn, loginStatus } = useAccount();
  const { openModal } = useModal();

  /*
   * State
   */

  const [createOrderStatus, setCreateOrderStatus] = useState<CreateOrderTransactionStatusType>(CreateOrderTransactionStatus.DEFAULT);

  const [rawEmailInput, setRawEmailInput] = useState<string>('');

  /*
   * Hooks
   */

  useEffect(() => {
    refetchUsdcBalance?.();

    refetchUsdcApprovalToExchange?.();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const onBuyListingSuccessCallback = useCallback((data: any) => {
    console.log('createOrder Succeeded: ', data);

    refetchUsdcBalance?.();
  }, [refetchUsdcBalance]);

  const {
    writeBuyListingAsync,
    maxPriceInput: listingPerTicketPrice,
    ticketIdsInput,
    emailHashInput,
    // encryptEmailKeyInput,
    setListingIdInput,
    setMaxPriceInput,
    setTicketIdsInput,
    setEmailHashInput,
    setEncryptedEmailInput,
    setShouldConfigureBuyListingWrite,
    signBuyListingTransactionStatus,
    mineBuyListingTransactionStatus,
    // transactionHash,
  } = useBuyListingTransaction(onBuyListingSuccessCallback);

  const onApproveSuccessCallback = useCallback((data: any) => {
    console.log('approve Succeeded: ', data);

    refetchUsdcApprovalToExchange?.();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    writeApproveAsync,
    // amountToApprove,
    setAmountToApprove,
    setShouldConfigureApprovalWrite,
    signApproveTransactionStatus,
    mineApproveTransactionStatus,
    // transactionHash,
  } = useApproveTransaction(onApproveSuccessCallback);

  useEffect(() => {
    if (!listing) {
      return;
    };

    setListingIdInput(listing.listingId);

    setMaxPriceInput(listing.price);
  }, [listing, setListingIdInput, setMaxPriceInput]);

  useEffect(() => {
    const usdcApprovalToExchangeLoaded = usdcApprovalToExchange !== null;
    const ticketsSelected = ticketIdsInput && ticketIdsInput.length > 0;

    if (!listingPerTicketPrice || !usdcApprovalToExchangeLoaded || !ticketsSelected) {
      setAmountToApprove(ZERO);
    } else {
      const requiredUsdcBI = listingPerTicketPrice * BigInt(ticketIdsInput.length);
      const approvalDifference = requiredUsdcBI - usdcApprovalToExchange;
      if (approvalDifference > ZERO) {
        setAmountToApprove(requiredUsdcBI);
      } else {
        setAmountToApprove(ZERO);
      }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingPerTicketPrice, ticketIdsInput, usdcApprovalToExchange]);

  useEffect(() => {
    const updateNewListingState = async () => {
      const successfulCreateListingTransaction = mineBuyListingTransactionStatus === 'success';

      if (successfulCreateListingTransaction) {
        setCreateOrderStatus(CreateOrderTransactionStatus.TRANSACTION_SUCCEEDED);
      } else {
        const selectedTicketCount = ticketIdsInput.length;
        const ticketsSelected = selectedTicketCount > 0;

        if (ticketsSelected) {
          const ticketsOnListing = listing.unfilledTickets.length;
          const singleTicketRemaining = (ticketsOnListing - selectedTicketCount) === 1;

          if (!singleTicketRemaining) {
            // User needs to be logged in to check USDC balance and approval

            if (isLoggedIn) {
              const usdcBalanceLoaded = usdcBalance !== null;
              const usdcApprovalToExchangeLoaded = usdcApprovalToExchange !== null;
  
              if (listingPerTicketPrice && usdcBalanceLoaded && usdcApprovalToExchangeLoaded) {
                const requiredUsdcBI = listingPerTicketPrice * BigInt(ticketIdsInput.length); // Max Price * Ticket Count
                const isPriceGreaterThanBalance = requiredUsdcBI > usdcBalance;
                const isPriceGreaterThanApprovedBalance = requiredUsdcBI > usdcApprovalToExchange;
    
                const signingApproveTransaction = signApproveTransactionStatus === 'loading';
                const miningApproveTransaction = mineApproveTransactionStatus === 'loading';
                const successfulApproveTransaction = mineApproveTransactionStatus === 'success';
    
                if (isPriceGreaterThanBalance) {
                  setCreateOrderStatus(CreateOrderTransactionStatus.INSUFFICIENT_BALANCE);
                } else if (isPriceGreaterThanApprovedBalance && !successfulApproveTransaction) {
                  if (signingApproveTransaction) {
                    setCreateOrderStatus(CreateOrderTransactionStatus.TRANSACTION_SIGNING);
                  } else if (miningApproveTransaction) {
                    setCreateOrderStatus(CreateOrderTransactionStatus.TRANSACTION_MINING);
                  } else {
                    setCreateOrderStatus(CreateOrderTransactionStatus.APPROVAL_REQUIRED);
                  }
                } else {
                  // Optionally add check to make sure hashed email is configured
    
                  const signingCreateListingTransaction = signBuyListingTransactionStatus === 'loading';
                  const miningCreateListingTransaction = mineBuyListingTransactionStatus === 'loading';
        
                  if (signingCreateListingTransaction) {
                    setCreateOrderStatus(CreateOrderTransactionStatus.TRANSACTION_SIGNING);
                  } else if (miningCreateListingTransaction){
                    setCreateOrderStatus(CreateOrderTransactionStatus.TRANSACTION_MINING);
                  } else {
                    if (emailHashInput) {
                      setCreateOrderStatus(CreateOrderTransactionStatus.VALID);
                    } else {
                      setCreateOrderStatus(CreateOrderTransactionStatus.MISSING_RECIPIENT);
                    }
                  }
                }
              } else {
                setCreateOrderStatus(CreateOrderTransactionStatus.MISSING_PRICE);
              }
            } else {
              setCreateOrderStatus(CreateOrderTransactionStatus.NOT_LOGGED_IN);
            }
          } else {
            setCreateOrderStatus(CreateOrderTransactionStatus.INVALID_TICKET_SELECTION);
          }
        } else {
          setCreateOrderStatus(CreateOrderTransactionStatus.DEFAULT);
        }
      }
    }

    updateNewListingState();
  }, [
      isLoggedIn,
      listingPerTicketPrice,
      ticketIdsInput,
      listing,
      emailHashInput,
      usdcBalance,
      usdcApprovalToExchange,
      signApproveTransactionStatus,
      mineApproveTransactionStatus,
      signBuyListingTransactionStatus,
      mineBuyListingTransactionStatus,
    ]
  );

  useEffect(() => {
    const isApprovalRequired = createOrderStatus === CreateOrderTransactionStatus.APPROVAL_REQUIRED;
    setShouldConfigureApprovalWrite(isApprovalRequired);

    setShouldConfigureBuyListingWrite(createOrderStatus === CreateOrderTransactionStatus.VALID);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderStatus]);

  /*
   * Handlers
   */

  const handleOverlayClick = () => {
    onBackClick();
  };

  const ctaOnClick = async () => {
    switch (createOrderStatus) {
      case CreateOrderTransactionStatus.APPROVAL_REQUIRED:
        try {
          await writeApproveAsync?.();
        } catch (error) {
          console.log('writeApproveAsync failed: ', error);
        }
        break;

      case CreateOrderTransactionStatus.VALID:
        try {
          await writeBuyListingAsync?.();
        } catch (error) {
          console.log('writeBuyListingAsync failed: ', error);
        }
        break;

      case CreateOrderTransactionStatus.TRANSACTION_SUCCEEDED:
        navigateWithQuery('/orders');
        break;

      case CreateOrderTransactionStatus.INSUFFICIENT_BALANCE:
        openModal(MODALS.RECEIVE);
        break;

      default:
        break;
    }
  };

  const handleEmailInputChange = async (rawEmailInput: string) => {
    setRawEmailInput(rawEmailInput);

    const isEmailValid = isValidEmailInput(rawEmailInput);

    if (isEmailValid) {
      const hashedEmail = ethers.utils.solidityKeccak256(["string"], [rawEmailInput]);
      setEmailHashInput(hashedEmail);
  
      const encryptionKey = listing.encryptionKey
      const encryptedEmail = await encryptMessage(rawEmailInput, encryptionKey);
      setEncryptedEmailInput(encryptedEmail);
    } else {
      setEmailHashInput('');
      setEncryptedEmailInput('');
    }
  };

  /*
   * Helpers
   */

  const isValidEmailInput = (rawEmailInput: string): boolean => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(rawEmailInput);
  };

  const usdcBalanceLabel = useMemo(() => {
    if (usdcBalance !== null) {
      return `${toUsdcString(usdcBalance, true)} USDC`
    } else {
      return '';
    }
  }, [usdcBalance]);

  const ctaDisabled = (): boolean => {
    switch (createOrderStatus) {
      case CreateOrderTransactionStatus.DEFAULT:
      case CreateOrderTransactionStatus.MISSING_PRICE:
      case CreateOrderTransactionStatus.TRANSACTION_SIGNING:
      case CreateOrderTransactionStatus.TRANSACTION_MINING:
      case CreateOrderTransactionStatus.MISSING_RECIPIENT:
      case CreateOrderTransactionStatus.INVALID_TICKET_SELECTION:
        return true;

      case CreateOrderTransactionStatus.VALID:
      case CreateOrderTransactionStatus.APPROVAL_REQUIRED:
      case CreateOrderTransactionStatus.INSUFFICIENT_BALANCE:
      case CreateOrderTransactionStatus.NOT_LOGGED_IN:
      default:
        return false;
    }
  }

  const ctaLoading = (): boolean => {
    switch (createOrderStatus) {
      case CreateOrderTransactionStatus.TRANSACTION_SIGNING:
      case CreateOrderTransactionStatus.TRANSACTION_MINING:
        return loginStatus === LoginStatus.AUTHENTICATED;

      default:
        return false;
    }
  };

  const ctaText = (): string => {
    switch (createOrderStatus) {
      case CreateOrderTransactionStatus.MISSING_PRICE:
        return 'Input price per ticket';

      case CreateOrderTransactionStatus.MISSING_RECIPIENT:
        return 'Input valid Ticketmaster email';
      
      case CreateOrderTransactionStatus.INSUFFICIENT_BALANCE:
        return `Insufficient balance — Deposit USDC`;

      case CreateOrderTransactionStatus.TRANSACTION_SIGNING:
        return 'Signing Transaction';

      case CreateOrderTransactionStatus.TRANSACTION_MINING:
        return 'Mining Transaction';

      case CreateOrderTransactionStatus.APPROVAL_REQUIRED:
        const usdcApprovalToExchangeString = usdcApprovalToExchange ? toUsdcString(usdcApprovalToExchange, true) : '0';
        return `Insufficient USDC transfer approval: ${usdcApprovalToExchangeString}`;

      case CreateOrderTransactionStatus.VALID:
        return 'Place Order';

      case CreateOrderTransactionStatus.TRANSACTION_SUCCEEDED:
        return 'Go to Orders';

      case CreateOrderTransactionStatus.NOT_LOGGED_IN:
        return 'Log In';

      case CreateOrderTransactionStatus.INVALID_TICKET_SELECTION:
        return 'Cannot leave one ticket remaining';

      case CreateOrderTransactionStatus.DEFAULT:
      default:
        return 'Select tickets to buy';
    }
  }

  const isSummaryLoading = (): boolean => {
    console.log('createOrderStatus: ', createOrderStatus);
    switch (createOrderStatus) {
      case CreateOrderTransactionStatus.VALID:
      case CreateOrderTransactionStatus.TRANSACTION_SIGNING:
      case CreateOrderTransactionStatus.TRANSACTION_MINING:
      case CreateOrderTransactionStatus.APPROVAL_REQUIRED:
        return false;

      default:
        return true;
    }
  }

  /*
   * Component
   */

  return (
    <ModalAndOverlayContainer>
      <Overlay onClick={handleOverlayClick}/>

      <ModalContainer>
        <RowBetween>
          <div style={{ flex: 0.25 }}>
            <button
              onClick={handleOverlayClick}
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            >
              <StyledArrowLeft/>
            </button>
          </div>

          <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
            Place Order
          </ThemedText.HeadlineSmall>

          <div style={{ flex: 0.25 }}/>
        </RowBetween>

        <InstructionsContainer>
          { commonStrings.get('BUY_ORDER_INSTRUCTIONS') }
          <Link
            href={EXTENSION_DOCS_URL}
            target="_blank"
          >
            Learn More ↗
          </Link>
        </InstructionsContainer>

        <BuyInstructions />

        <BodyContainer>
          <TicketsTable
            listingTickets={listing.unfilledTickets}
            setSelectedListingTickets={setTicketIdsInput}
          />

          <Input
            label="Ticketmaster Email"
            name={`emailInput`}
            value={rawEmailInput}
            onChange={(e) => handleEmailInputChange(e.currentTarget.value)}
            type="string"
            placeholder="email@domain.com"
            helperText={commonStrings.get('BUY_ORDER_RECIPIENT_EMAIL_TOOLTIP')}
          />

          <Summary
            isLoading={isSummaryLoading()}
            usdcBalance={usdcBalanceLabel}
            pricePerTicket={toUsdcString(listing.price)} 
            ticketCount={ticketIdsInput.length}
          />

          {createOrderStatus === CreateOrderTransactionStatus.NOT_LOGGED_IN ? (
            <CustomConnectButton
              fullWidth={true}
              height={48}
            />
          ) : (
            <Button
              disabled={ctaDisabled()}
              loading={ctaLoading()}
              onClick={async () => {
                ctaOnClick();
              }}
              fullWidth={true}
            >
              { ctaText() }
            </Button>
          )}
        </BodyContainer>
      </ModalContainer>
    </ModalAndOverlayContainer>
  );
};

const ModalAndOverlayContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: flex-start;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.overlay};
`;

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${colors.black};
`;

const ModalContainer = styled.div`
  max-height: 80vh;
  width: 80vw;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1.5rem 1.5rem;
  background-color: ${colors.container};
  color: ${colors.black};
  z-index: ${Z_INDEX.buy_modal};
  gap: 1rem;
  overflow-y: auto;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
`;

const InstructionsContainer = styled.div`
  padding: 0rem 0.75rem;
  color: ${colors.darkText};
  font-size: 15px;
`;

import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ArrowLeft } from 'react-feather';

import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { Row } from "@components/common/Layout";
import { Button } from '@components/common/Button'
import { RowBetween } from '@components/layouts/Row';
import { OrderRefundData } from '@components/Orders/OrderTable';
import { InitiateRefundTransactionStatus } from '@helpers/types';

import useBalances from '@hooks/contexts/useBalance';
import useBuyers from '@hooks/contexts/useBuyers';
import useInitiateRefund from '@hooks/transactions/useInitiateRefund';


interface InitiateRefundFormProps {
  handleBackClick: () => void;
  orderDetails: OrderRefundData | null;
};

export const InitiateRefundForm: React.FC<InitiateRefundFormProps> = ({
  handleBackClick,
  orderDetails
}) => {
  // const size = useWindowSize();

  /*
   * Context
   */
  const { refetchBuyerOrders } = useBuyers();
  const { refetchUsdcBalance } = useBalances();

  /*
   * State
   */
  const [initiateRefundStatus, setInitiateRefundStatus] = useState<string>(InitiateRefundTransactionStatus.DEFAULT);
  const [ctaButtonTitle, setCtaButtonTitle] = useState<string>("");
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false); 

  /*
   * Hooks
   */

  const onSuccessCallback = useCallback((data: any) => {
    console.log('initiateRefund Succeeded: ', data);

    refetchUsdcBalance?.();

    refetchBuyerOrders?.();
  }, [refetchBuyerOrders, refetchUsdcBalance]);

  const {
    writeInitiateRefundAsync,
    orderIdInput,
    setOrderIdInput,
    setShouldConfigureInitiateRefundWrite,
    signInitiateRefundTransactionStatus,
    mineInitiateRefundTransactionStatus,
    // transactionHash,
  } = useInitiateRefund(onSuccessCallback);

  useEffect(() => {
    setShouldConfigureInitiateRefundWrite(initiateRefundStatus === InitiateRefundTransactionStatus.VALID);
  }, [initiateRefundStatus, setShouldConfigureInitiateRefundWrite]);

  useEffect(() => {
    if (orderDetails) {
      setOrderIdInput(orderDetails.orderId);
    } else {
      setOrderIdInput(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails]);

  useEffect(() => {
    const updateInitiateRefundStatus = async () => {
      const successfulVerificationTransaction = mineInitiateRefundTransactionStatus === 'success';
      if (successfulVerificationTransaction) {
        setInitiateRefundStatus(InitiateRefundTransactionStatus.TRANSACTION_SUCCEEDED);

        setCtaButtonTitle("Go Back");

        setButtonDisabled(false);
      } else {
        const orderSelected = orderIdInput !== null;

        if (orderSelected) {
          const signingVerifyTicketsTransaction = signInitiateRefundTransactionStatus === 'loading';
          const miningVerifyTicketsTransaction = mineInitiateRefundTransactionStatus === 'loading';

          if (signingVerifyTicketsTransaction) {
            setInitiateRefundStatus(InitiateRefundTransactionStatus.TRANSACTION_SIGNING);

            setCtaButtonTitle("Transaction Signing");

            setButtonDisabled(true);
          } else if (miningVerifyTicketsTransaction) {
            setInitiateRefundStatus(InitiateRefundTransactionStatus.TRANSACTION_MINING);

            setCtaButtonTitle("Transaction Mining");

            setButtonDisabled(true);
          } else {
            setInitiateRefundStatus(InitiateRefundTransactionStatus.VALID);

            setCtaButtonTitle("Refund Order");

            setButtonDisabled(false);
          }
        } else {
          setInitiateRefundStatus(InitiateRefundTransactionStatus.DEFAULT);

          setCtaButtonTitle("Refund Order");

          setButtonDisabled(false);
        }
      }
    }

    updateInitiateRefundStatus();
  }, [
      orderIdInput,
      signInitiateRefundTransactionStatus,
      mineInitiateRefundTransactionStatus,
    ]
  );

  /*
   * Helpers
   */

  const ctaOnClick = async () => {
    switch (initiateRefundStatus) {
      case InitiateRefundTransactionStatus.VALID:
        try {
          await writeInitiateRefundAsync?.();
        } catch (error) {
          console.log('writeInitiateRefundAsync failed: ', error);
        }
        break;

      case InitiateRefundTransactionStatus.TRANSACTION_SUCCEEDED:
        handleBackClick();
        break;

      default:
        break;
    }
  }

  /*
   * Component
   */

  return (
    <Container>
      <RowBetween style={{ padding: '0.25rem 0rem 0.25rem 0rem' }}>
        <div style={{ flex: 0.5 }}>
          <button
            onClick={handleBackClick}
            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
          >
            <StyledArrowLeft/>
          </button>
        </div>

        <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
          Initiate Refund
        </ThemedText.HeadlineSmall>

        <div style={{ flex: 0.5 }}/>
      </RowBetween>

      <CTAContainer>
        <CTAText>
          Are you sure you want to refund the following order?
        </CTAText>
      </CTAContainer>

      <TicketInfoContainer>
          <Label>
            {orderDetails?.eventText}
          </Label>

          <Label>
            {orderDetails?.startTimeText}
          </Label>

          <Label>
            {orderDetails?.sectionText}
          </Label>

          <Label>
            {orderDetails?.rowText}
          </Label>

          <Label>
            {orderDetails?.seatText}
          </Label>
      </TicketInfoContainer>

      <CTAContainer>
        <CTAText>
          You will be able to claim the refund after 24 hours has elapsed. If you have already accepted the ticket transfer you will be unable to receive a refund.
        </CTAText>
      </CTAContainer>

      <CTAContainer>
        <CTAText color={colors.orderSummaryGreen}>
          You will receive {orderDetails?.ticketPriceText}
        </CTAText>
      </CTAContainer>

      <ButtonContainer>
        <Button
          onClick={ctaOnClick}
          disabled={buttonDisabled}
        >
          {ctaButtonTitle}
        </Button>
      </ButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  margin: auto;
  max-width: 400px;
  padding: 1.5rem;
  background-color: ${colors.container};
  border-radius: 16px;
`;

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${colors.darkText};
`;

const TicketInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid;
  border-color: #C0C0C0;
  background-color: ${colors.background};
  border-radius: 8px;
  padding: 16px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const Label = styled.span`
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  color: #333333;
`;

const CTAContainer = styled(Row)`
  gap: 0.75rem;
  border-radius: 12px;
  color: #333333;
  line-height: 1.35;
  padding: 0rem 0.5rem;
  margin-top: 1rem !important;
  text-align: center;
  justify-content: center;
`;

const CTAText = styled.span<{ color?: string }>`
  font-size: 15px;
  color: ${props => props.color || 'inherit'};
`;

const ButtonContainer = styled.div`
  display: grid;
  margin-top: 1.5rem;
`;
